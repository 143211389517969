import { Axios } from "../lib/axios";
export const SamlServices = {
  addIDPSamlConfiguration,
  updateIDPSamlConfiguration,
  deleteIDPSamlConfiguration,
  verifyMetaDataURL,
  getSAMLUrls,
};
function addIDPSamlConfiguration(body) {
  return Axios.post("/saml/addIdentityProvider", body);
}
function updateIDPSamlConfiguration(body) {
  return Axios.post("saml/updateIdentityProvider", body);
}
function deleteIDPSamlConfiguration(body) {
  return Axios.patch("saml/deleteIdentityProvider", body);
}
function verifyMetaDataURL(body) {
  return Axios.post("saml/verify", body);
}
function getSAMLUrls() {
  return Axios.get("saml/getUrls");
}
