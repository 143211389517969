import React, { useEffect, useState } from "react";
import moment from "moment";
import "./Style/MagicLinkTimer.scss";
import decodeJWT from "../../lib/decodeJWT";

const MagicLinkTimer = () => {
  // Decode the token and extract expiration time
  const tokenDetails = decodeJWT(localStorage.getItem("token"));

  // Function to calculate the remaining time until expiration
  const calculateTimeLeft = () => {
    if (!tokenDetails?.user?.expire_in) return null; // Return null if expiration time is missing

    const expiryDate = moment.utc(tokenDetails.user.expire_in).local(); // Convert UTC to local timezone
    const now = moment(); // Get the current time in local timezone
    const difference = expiryDate.diff(now, "seconds"); // Calculate the difference in seconds

    if (difference <= 0) return null; // If expired, return null

    return {
      days: Math.floor(difference / (3600 * 24)), // Convert seconds to days
      hours: Math.floor((difference % (3600 * 24)) / 3600), // Convert remaining seconds to hours
      minutes: Math.floor((difference % 3600) / 60), // Convert remaining seconds to minutes
      seconds: difference % 60, // Get the remaining seconds
    };
  };

  // State to store the remaining time
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Stop if there is no time left
    if (!timeLeft) return;

    const timer = setInterval(() => {
      // Update the countdown every second
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(timer);
  }, [tokenDetails]);

  // Function to format numbers to always show two digits (e.g., 01, 09)
  const formatTime = (time) => time.toString().padStart(2, "0");

  // Hide the component if expired
  if (!timeLeft) return null;

  return (
    <div className="MagicLinkTimer">
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", gap: "3px" }}>
          <div className="box-style">
            {formatTime(timeLeft.days)}
            <span className="d-flex timer-text-ml">Days</span>
          </div>
          :
          <div className="box-style">
            {formatTime(timeLeft.hours)}
            <span className="d-flex timer-text-ml">Hrs</span>
          </div>
          :
          <div className="box-style">
            {formatTime(timeLeft.minutes)}
            <span className="d-flex timer-text-ml">Mins</span>
          </div>
          :
          <div className="box-style">
            {formatTime(timeLeft.seconds)}
            <span className="d-flex timer-text-ml">Sec</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagicLinkTimer;
