import React, { Component } from "react";
import { connect } from "react-redux";
import PasswordChecklist from "react-password-checklist";
import { formatRoute } from "react-router-named-routes";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import Notification from "../Components/Notification";
import profile_image from "../Images/profile-image.png";
import decodeJWT from "../lib/decodeJWT";
import { UserServices } from "../Services/User";
import {
  loginUserFailure,
  loginUserSuccess,
  openPreference,
} from "../Store/Actions/User";
import constants from "../Utils/constants";
import DateTimeFormatter from "../Utils/DateTimeFormatter";
import Toast from "../Utils/Toast";
import SetPassword from "../Components/SetPassword";
import Logout from "./LogOut_using_SSO";
import ToggleSwitch from "../Components/ToggleSwitch";
import ellipsisName from "../Utils/AddEllipsisToName";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import MagicLinkTimer from "../Pages/MagicLinkUsers/MagicLinkTimer";
import moment from "moment";
import { MagicLinkUserServices } from "../Services/MagicLinkUserServices";
import Dailog from "../Components/DailogNew";
import { withRouter } from "react-router-dom";
import { WebSocketConsumer } from "../Providers/WebSocketContext";

class Header extends Component {
  state = {
    loginSuccesData: null,
    isProfileMenu: false,
    documentModal: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    apiErrors: "",
    passwordError: false,
    newPasswordError: false,
    confirmPasswordError: false,
    isSubmitted: false,
    successMessage: "",
    isNewPasswordCurrentPasswordEqualError: false,
    isChecklistValid: false,
    isExpirationModalOpen: false,
    dailogModalHeader: "",
    dailogModalContent: "",
    dailogModalConfig: { type: null },
    submitButtonLabel: "Confirm",
    confirmBtn: true,
    cancelBtn: true,
  };

  constructor(props) {
    super(props);
    this.countdown = 5;
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    const { socketRef } = this.props;
    document.addEventListener("mousedown", this.handleClickOutside);
    // Magic Link Timer expiration check
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    this.handleSocketMessage = (event) => {
      try {
        let data = JSON.parse(event.data);
        if (
          tokenDetails.user.id === data.user_id &&
          data.msg === "LOGOUT_USER_SESSION"
        ) {
          this.setState({
            dailogModalHeader: (
              <h3 className="text-darkblue2 font-weight-bold">
                Session Logged Out
              </h3>
            ),
            dailogModalContent: (
              <h3 className="text-darkblue2 fs-15">
                Your session has been logged out because another session
                accessed your account. If this wasn’t you, please secure your
                account immediately.
              </h3>
            ),
            confirmBtn: false,
            cancelBtn: false,
            isExpirationModalOpen: true, // Open modal before redirecting
          });
        }
      } catch (e) {
        console.log("Websocket logout error: ", e);
      }
    };

    // Check if socketRef.current is available
    if (socketRef?.current) {
      socketRef.current.addEventListener("message", this.handleSocketMessage);
    } else {
      // Optional: wait for a bit and retry once
      this.waitForSocket();
    }

    if (tokenDetails?.user?.is_magic && tokenDetails?.user?.expire_in) {
      // Convert to local time
      const expiryTime = moment.utc(tokenDetails.user.expire_in).local();

      this.tokenExpiryInterval = setInterval(() => {
        const now = moment();
        if (now.isAfter(expiryTime)) {
          // Open modal when expired
          this.setState({
            dailogModalHeader: (
              <h3 className="text-darkblue2 font-weight-bold">
                Link is Expired
              </h3>
            ),
            dailogModalContent: (
              <h3 className="text-darkblue2" style={{ fontSize: "14px" }}>
                The magic link has expired. Click 'Request Now' to request a
                renewed link.
              </h3>
            ),
            isExpirationModalOpen: true,
            dailogModalConfig: { type: "magic-link-expired" },
            submitButtonLabel: "Request Now",
          });
          // Stop checking after expiration
          clearInterval(this.tokenExpiryInterval);
        }
      }, 1000);
    }
    this.setState({
      loginSuccesData: this.props.home.loginUserSuccess,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleOutsideClick(e) {
    if (e && e.target && this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.toggleProfileMenu();
    }
  }

  toggleProfileMenu = () => {
    if (!this.state.isProfileMenu) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState({
      isProfileMenu: !this.state.isProfileMenu,
    });
  };

  waitForSocket = () => {
    const { socketRef } = this.props;

    const check = () => {
      if (socketRef?.current) {
        socketRef.current.addEventListener("message", this.handleSocketMessage);
      } else {
        // Try again after 100 ms
        setTimeout(check, 100);
      }
    };

    check();
  };

  userLogout = () => {
    if (localStorage.getItem("token") !== null) {
      UserServices.getUserLogout()
        .then((res) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          localStorage.clear();
          this.props.history.push(
            formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
          );
          this.props.loginSuccess({});
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    } else {
      this.props.history.push(
        formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
      );
    }
  };

  handleChangePasswordFormSubmit = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      let body = {
        password: this.state.currentPassword,
        new_password: this.state.newPassword,
        confirm_password: this.state.confirmPassword,
      };
      if (localStorage.getItem("token") !== null) {
        UserServices.changePassword(body)
          .then((data) => {
            this.setState({
              apiErrors: "",
              successMessage: data.data.message,
            });
            setTimeout(() => {
              this.userLogout();
            }, 1500);
          })
          .catch((error) => {
            this.setState({
              apiErrors:
                error && error.data && error.data.error
                  ? error.data.error
                  : constants.ERROR.SOMETHING_WENT_WRONG,
            });
          });
      } else {
        this.props.history.push(
          formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        );
      }
    }
  };

  toggleDocumentModal = () => {
    let documentModal = this.state.documentModal;
    if (!documentModal && localStorage.getItem("token") == null) {
      Toast(constants.TOKEN.NOT_FOUND, "error");
      setTimeout(() => {
        this.props.history.push(
          formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        );
      }, 1500);
    } else {
      this.setState({
        documentModal: !documentModal,
        currentPasswordError: false,
        newPasswordError: false,
        confirmPasswordError: false,
        isNewPasswordCurrentPasswordEqualError: false,
      });
    }
  };

  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.isSubmitted) this.isFormValid();
      }
    );
  };

  isFormValid = () => {
    let isNewPasswordCurrentPasswordEqualError =
      this.state.newPassword === this.state.currentPassword &&
      this.state.newPassword &&
      this.state.currentPassword;
    this.setState({
      isNewPasswordCurrentPasswordEqualError,
      isSubmitted: true,
    });
    if (isNewPasswordCurrentPasswordEqualError === false) {
      return true;
    }
    return false;
  };

  // navitage to the Preference center
  navigateToPreferenceCenter = () => {
    this.props.history.push(
      formatRoute(constants.APPLICATION_ROUTE.MANAGE_PREFERENCES.ROUTE, {})
    );
    this.toggleProfileMenu();
  };

  getFirstLetters(str) {
    const firstLetters = str
      .split(" ")
      .map((word) => word[0])
      .join("");

    return firstLetters;
  }

  handleRequestNow = () => {
    const tokenDetails = decodeJWT(localStorage.getItem("token"));
    const userId = tokenDetails?.user?.id;

    MagicLinkUserServices.renewMagicLinkRequest(userId)
      .then((_res) => {
        let countdown = 5; // Start countdown from 5 seconds

        this.setState({
          isExpirationModalOpen: true, // Open modal before redirecting
          dailogModalHeader: (
            <h3 className="text-darkblue2 font-weight-bold">
              Request Submitted
            </h3>
          ),
          dailogModalContent: (
            <h3 className="text-darkblue2" style={{ fontSize: "14px" }}>
              Your request for renewal is submitted successfully to the
              authorized person! You will be redirected to login in {countdown}{" "}
              seconds. Thanks!
            </h3>
          ),
          confirmBtn: false,
          cancelBtn: false,
        });

        // Start countdown timer
        const interval = setInterval(() => {
          countdown -= 1; // Decrease countdown by 1

          // Update modal content dynamically with remaining time
          this.setState({
            dailogModalContent: (
              <h3 className="text-darkblue2" style={{ fontSize: "14px" }}>
                Your request for renewal is submitted successfully to the
                authorized person! You will be redirected to login in{" "}
                {countdown} seconds. Thanks!
              </h3>
            ),
          });

          if (countdown <= 0) {
            clearInterval(interval); // Stop interval when countdown ends

            // Clear token and redirect to login
            localStorage.removeItem("token");
            localStorage.clear();
            this.props.history.push(
              formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE)
            );
          }
        }, 1000); // Run every 1 second
      })
      .catch((error) => {
        Toast(
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleDecline = () => {
    this.setState({
      dailogModalHeader: (
        <h3 className="text-darkblue2 font-weight-bold">Session Expired</h3>
      ),
      dailogModalContent: (
        <h3 className="text-darkblue2" style={{ fontSize: "14px" }}>
          You have declined the request. You will be redirected to the login
          page in {this.countdown} seconds.
        </h3>
      ),
      confirmBtn: false,
      cancelBtn: false,
      isExpirationModalOpen: true, // Open modal before redirecting
    });

    // Start countdown timer
    const interval = setInterval(() => {
      this.countdown -= 1; // Decrease countdown by 1

      // Update modal content dynamically with remaining time
      this.setState({
        dailogModalContent: (
          <h3 className="text-darkblue2" style={{ fontSize: "14px" }}>
            You have declined the request. You will be redirected to the login
            page in {this.countdown} seconds.
          </h3>
        ),
      });

      if (this.countdown <= 0) {
        clearInterval(interval); // Stop an interval when the countdown ends

        // Clear token and redirect to log in
        localStorage.clear();
        this.props.history.push(
          formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE)
        );
      }
    }, 1000); // Run every 1 second
  };

  render() {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    return (
      <>
        <header>
          <div className="header-content-wrapper h-100">
            <div className="header-content h-100 d-flex align-items-center">
              <nav className="navbar navbar-expand-lg navbar-light p-0">
                <div className="logo d-flex align-items-center">
                  <a
                    className="navbar-brand m-0 nav_logo"
                    onClick={() => {
                      this.props.openPreference(false);
                    }}
                    href={formatRoute(
                      process.env.PUBLIC_URL +
                        constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE,
                      {}
                    )}
                  >
                    <img
                      style={{ width: "80px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/staging/TBR_2color_no_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo /> */}
                  </a>
                  <a
                    className="navbar-brand d-md-none header-logo"
                    href={formatRoute(
                      process.env.PUBLIC_URL +
                        constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE,
                      {}
                    )}
                  >
                    <img
                      style={{ width: "80px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/staging/TBR_2color_no_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo /> */}
                  </a>
                </div>
                {tokenDetails.role.short_name === "admin" && (
                  <div>
                    <ToggleSwitch data={this.state.loginSuccesData} />
                  </div>
                )}

                <div className="site_name ml-md-auto">
                  <span>IC-ADMIN</span>
                </div>
                <div className="ml-auto">
                  <div className="d-flex align-items-center">
                    {tokenDetails?.user?.is_magic && (
                      <div className="ml-auto">
                        <MagicLinkTimer />
                      </div>
                    )}
                    <div className="login_sec">
                      <ul className="navbar-nav justify-content-end align-items-center">
                        <li className="mr-3">
                          <Button
                            id="PopoverLegacy"
                            style={{
                              padding: 0,
                              border: "none",
                              background: "none",
                              boxShadow: "none",
                            }}
                            type="button"
                            className="d-inline-flex"
                          >
                            <span className="material-icons text-ligntblue2">
                              mail
                            </span>
                          </Button>
                          <UncontrolledPopover
                            placement="bottom"
                            target="PopoverLegacy"
                            trigger="legacy"
                            className="custom-popover"
                          >
                            <PopoverHeader>Email Support</PopoverHeader>
                            <PopoverBody>
                              Any queries/help, please contact us <br />
                              <a
                                style={{ color: "#0645AD" }}
                                href="mailto:icsupport@tbri.com"
                              >
                                icsupport@tbri.com
                              </a>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </li>
                        <li
                          onClick={this.toggleProfileMenu}
                          className={`user-details ${
                            this.state.isProfileMenu ? "active" : ""
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <span className="profile_circle">
                              {this.getFirstLetters(
                                `${this.props.home.loginUserSuccess.first_name} ${this.props.home.loginUserSuccess.last_name}`
                              )}
                            </span>
                            <div className="user-details-block d-none d-md-flex flex-column align-items-start">
                              <span>{`${ellipsisName(
                                this.props.home.loginUserSuccess.first_name
                              )}`}</span>
                              {tokenDetails.role.name === "Admin" ? (
                                <span className="user-role">
                                  {this.props.home.userDesignation &&
                                  this.props.home.userDesignation.length > 0
                                    ? this.props.home.userDesignation
                                    : this.props.home.loginUserSuccess
                                        .designation !== null
                                    ? this.props.home.loginUserSuccess
                                        .designation
                                    : tokenDetails.role.name}
                                </span>
                              ) : (
                                <span className="user-role">
                                  {this.props.home.loginUserSuccess
                                    .designation !== null
                                    ? this.props.home.loginUserSuccess
                                        .designation
                                    : tokenDetails.role.name}
                                </span>
                              )}
                            </div>
                            <span className="material-icons arrow-drown-down">
                              arrow_drop_down
                            </span>
                          </div>
                        </li>
                        <li className="d-none">
                          <span className="search_open">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div
          className={`login_sec user-popover ${
            this.state.isProfileMenu ? "active" : ""
          }`}
        >
          <ul
            ref={(node) => {
              this.node = node;
            }}
          >
            <li>
              <div className="userProfile">
                <img src={profile_image} alt="Profile" />
                <h4>{`${this.props.home.loginUserSuccess.first_name} ${this.props.home.loginUserSuccess.last_name}`}</h4>
                <div
                  data-for="displayNameUser"
                  data-tip={
                    this.props.home.loginUserSuccess.display_name || "N/A"
                  }
                >
                  <CustomReactTooltip id="displayNameUser" />
                  <p>
                    Display Name:{" "}
                    {ellipsisName(
                      this.props.home.loginUserSuccess.display_name
                    ) || "N/A"}
                  </p>
                </div>
                {tokenDetails && tokenDetails.role && (
                  <span className="user-profile-role">
                    {tokenDetails.role.name === "Admin"
                      ? this.props.home.userDesignation &&
                        this.props.home.userDesignation.length > 0
                        ? this.props.home.userDesignation
                        : this.props.home.loginUserSuccess.designation !== null
                        ? this.props.home.loginUserSuccess.designation
                        : tokenDetails.role.name
                      : this.props.home.loginUserSuccess.designation !== null
                      ? this.props.home.loginUserSuccess.designation
                      : tokenDetails.role.name}
                  </span>
                )}
                <p>
                  Member Since{" "}
                  {DateTimeFormatter(
                    this.props.home.loginUserSuccess.created_at
                  )}
                </p>
              </div>
            </li>
            {tokenDetails?.user?.is_magic === false &&
              (!tokenDetails.user.is_set_password ? (
                <SetPassword />
              ) : (
                <li>
                  <span
                    onClick={this.toggleDocumentModal}
                    className="d-inline-flex align-items-center"
                  >
                    <i className="material-icons-outlined">lock_reset</i>
                    Change Password
                  </span>
                </li>
              ))}

            {/* <RegisterSSO
              entity={constants.HTML.TAGS.LIST_ITEM}
              is_sso_registered={
                tokenDetails &&
                tokenDetails.user &&
                tokenDetails.user.is_sso_registered
              }
            /> */}
            <li>
              <span
                onClick={this.navigateToPreferenceCenter}
                className="d-inline-flex align-items-center"
              >
                <i className="material-icons-outlined">settings</i>
                Preferences Center
              </span>
            </li>
            <Logout userLogout={this.userLogout} />
            {this.state.documentModal && (
              <Modal
                isOpen={this.state.documentModal}
                toggle={this.toggleDocumentModal}
                className={"modal-md modal-w-header custom-modal primary-modal"}
              >
                <ModalHeader toggle={this.toggleDocumentModal}>
                  {constants.CHANGE_PASSWORD.HEADER_TITLE}
                </ModalHeader>
                <form
                  onSubmit={this.handleChangePasswordFormSubmit}
                  className={`col-lg-12 col-sm-12 form-wrapper p-0`}
                >
                  <ModalBody>
                    {this.state.apiErrors &&
                      this.state.apiErrors.length > 0 && (
                        <Notification
                          color={"danger"}
                          message={this.state.apiErrors}
                        />
                      )}

                    {this.state.successMessage &&
                      this.state.successMessage.length > 0 && (
                        <Notification
                          color={"success"}
                          message={this.state.successMessage}
                        />
                      )}
                    <FormGroup>
                      <div className="clearfix">
                        <Label htmlFor="currentPassword">
                          Current Password{" "}
                          <span className={"mandatory"}>* </span>
                        </Label>
                        <Input
                          type="password"
                          placeholder=""
                          id="currentPassword"
                          name="currentPassword"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="clearfix">
                        <Label htmlFor="newPassword">
                          New Password <span className={"mandatory"}>* </span>
                        </Label>
                        <Input
                          className={`${
                            this.state.isNewPasswordCurrentPasswordEqualError
                              ? "is-invalid"
                              : ""
                          }`}
                          type="password"
                          placeholder=""
                          id="newPassword"
                          name="newPassword"
                          onChange={this.handleInputChange}
                        />
                        {this.state.isNewPasswordCurrentPasswordEqualError && (
                          <div className="invalid-tooltip">
                            Current Password and New Password should be
                            different{" "}
                          </div>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="clearfix">
                        <Label htmlFor="confirmPassword">
                          Confirm Password{" "}
                          <span className={"mandatory"}>* </span>
                        </Label>
                        <Input
                          type="password"
                          placeholder=""
                          id="confirmPassword"
                          name="confirmPassword"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </FormGroup>
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "match",
                        "notEmpty",
                        "lowercase",
                      ]}
                      minLength={8}
                      value={this.state.newPassword}
                      iconSize={12}
                      valueAgain={this.state.confirmPassword}
                      onChange={(isValid) => {
                        this.setState({
                          isChecklistValid: isValid,
                        });
                      }}
                      style={{ marginTop: "20px" }}
                      className={"set-pwd-form"}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="modal-btn btn-outline-primary"
                      onClick={this.toggleDocumentModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="modal-btn"
                      type="submit"
                      disabled={!this.state.isChecklistValid}
                    >
                      Change Password
                    </Button>{" "}
                  </ModalFooter>
                </form>
              </Modal>
            )}
          </ul>
          {this.state.isExpirationModalOpen && (
            <Dailog
              isOpen={this.state.isExpirationModalOpen}
              accept={this.handleRequestNow}
              decline={this.handleDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={"success-modal"}
              cancel={this.state.cancelBtn}
              submit={this.state.confirmBtn}
              submitButtonLabel={this.state.submitButtonLabel}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
    loginFailure: (user) => {
      dispatch(loginUserFailure(user));
    },
    openPreference: (data) => {
      dispatch(openPreference(data));
    },
  };
};
const ConnectedHeader = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);

const HeaderWithWebSocket = () => (
  <WebSocketConsumer>
    {(socketRef) => <ConnectedHeader socketRef={socketRef} />}
  </WebSocketConsumer>
);

export default HeaderWithWebSocket;
