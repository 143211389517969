let MAGIC_LINK_CONSTANTS = {
  MAGIC_LINK: {
    LIST: {
      NAME: "Magic link Users",
      ROUTE: "/magic-link-users/list",
      HEADER_TITLE: "Magic Link Users",
      ADD_BUTTON_TITLE: "Add",
      EDIT_BUTTON_TITLE: "Edit",
      REMOVE_BUTTON_TITLE: "Remove",
      ACTIVE_BUTTON_TITLE: "Active",
      INACTIVE_BUTTON_TITLE: "In-Active",
      EDIT_USER: "Edit User",
      REMOVE_USER: "Remove User",
      STATUS: "Status",
      ACTIVATE_USER_TOOLTIP: "Activate",
      INACTIVATE_USER_TOOLTIP: "De-Activate",
      EDIT_USER_TOOLTIP: "Edit",
      REMOVE_USER_TOOLTIP: "Remove",
      RESEND_MAGIC_LINK: "Resend Magic Link",
      RESEND_MAGIC_LINK_TOOLTIP: "Resend Magic Link",
      DELETE_MAGIC_LINK_USER: "Delete Magic link User",
      DELETE_SUCCESS_MAGIC_LINK_USER: "Deleted Magic link user successfully",
      DELETE_CONFIRATION_MAGIC_LINK_USER:
        "Are you sure want to remove the magic link user?",
      INACTIVATE_MAGIC_LINK_USER:
        "Are you sure want to de-activate the magic link user?",
      ACTIVATE_MAGIC_LINK_USER:
        "Are you sure want to activate the magic link user?",
      COPY_MAGIC_LINK_TOOLTIP: "Copy Magic Link",
    },
    CREATE: {
      NAME: "Add User",
      ROUTE: "/magic-link-users/add",
      HEADER_TITLE: "Add User",
    },
    EDIT: {
      NAME: "Edit User",
      ROUTE: "/magic-link-users/:id/edit",
      HEADER_TITLE: "Edit User",
    },
  },
  MAGIC_LINK_PERMISSION: {
    MAGIC_LINK: {
      TITLE: "magic_link",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
      },
    },
  },
  MAGIC_LINK_USER_COLOR: ["#606B77", "#046CD9", "#FF5A5A"],
};

export default MAGIC_LINK_CONSTANTS;
