import React, { Component } from "react";
import DynamicTableComponentNew from "../Components/DynamicTableComponentsNew";
import { ReportsServices } from "../Services/Reports";
import { ClientServices } from "../Services/Client";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import DocumentTitle from "../Utils/DocumentTitle";
import { formatRoute } from "react-router-named-routes";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import CustomDropdownList from "../Components/CustomDropdownList";
import IsTBRIClient from "../Utils/IsTBRIClient";
import { clients, users } from "../Store/Actions/Client";
import moment from "moment";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import DailogNew from "../Components/DailogNew";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import AclAction from "../Components/ACL/AclAction";
import isRoleAccountManager from "../Utils/isRoleAccountManager";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import BoardsChart from "../Components/Charts/BoardsChart";
import Time_On_Boards from "../Components/Charts/Time_On_Boards";
import CustomDatePicker from "../Components/CustomDatePicker";
import DynamicTableComponentWithChevron from "../Components/DynamicTableComponentWithChevron";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import decodeJWT from "../lib/decodeJWT";

const tableHeader = [
  { id: "board_name", title: "Board Name", sortable: true, visibility: true },
  { id: "timestamp", title: "Time Stamp", sortable: true, visibility: true },
  { id: "timespent", title: "Time Spent", sortable: true, visibility: true },
  { id: "scroll", title: "Scroll(%)", sortable: true, visibility: true },
];
const tableHeader1 = [
  { id: "board_name", title: "Board Name", sortable: true, visibility: true },
  { id: "visits", title: "Visits", sortable: true, visibility: true },
  { id: "timespent", title: "Time Spent", sortable: true, visibility: true },
  { id: "downloadCount", title: "Downloads", sortable: true, visibility: true },
  { id: "minScroll", title: "Min Scroll(%)", sortable: true, visibility: true },
  { id: "maxScroll", title: "Max Scroll(%)", sortable: true, visibility: true },
  { id: "commentsCount", title: "Comments ", sortable: true, visibility: true },
  { id: "sharedCount", title: "Shares", sortable: true, visibility: true },
];
const innerHeaders = [
  {
    id: "timeStamp",
    title: "Time Stamp",
    sortable: false,
    visibility: true,
    rowStyle: { textAlign: "left" },
  },
  {
    id: "timeSpent",
    title: "Time Spent",
    sortable: false,
    visibility: true,
    rowStyle: { textAlign: "left" },
  },
  {
    id: "scroll",
    title: "Scroll(%)",
    sortable: false,
    visibility: true,
    rowStyle: { textAlign: "left" },
  },
];

class ReportsBoardsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      currentData: [],
      userData: [],
      columnData: [],
      sortColumn: null,
      sortOrder: null,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      count: 0,
      start_date: "2021-11-1",
      end_date: "2021-12-1",
      userId: null,
      clientId: null,
      clientName: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      disableStatus: false,
      selectReason: "",
      AssignedClients: [],
      showActions: false,
      isRoleAccountManager: false,
      totalTabs: [],
      selectedTab: constants.APPLICATION_ROUTE.REPORTS.BOARDS.TABS[0].KEY,
      isRoleMember: false,
      boardsVisitedData: {},
      boardsCreatedData: {},
      innerHeaders: [],
      clickedValues: [],
    };
  }

  componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.REPORTS.LIST3.HEADER_TITLE);
    this.setState({
      userId:
        this.props.location.state && this.props.location.state.id
          ? this.props.location.state.id
          : null,
      clientId:
        this.props.location.state && this.props.location.state.clientId
          ? this.props.location.state.clientId
          : null,
      start_date:
        this.props.location.state && this.props.location.state.fromDate
          ? this.props.location.state.fromDate
          : moment().subtract(60, "days").format("YYYY-MM-DD"),
      end_date:
        this.props.location.state && this.props.location.state.endDate
          ? this.props.location.state.endDate
          : moment().format("YYYY-MM-DD"),
      totalTabs: constants.APPLICATION_ROUTE.REPORTS.BOARDS.TABS,
      innerHeaders: innerHeaders,
    });
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.REPORTS.BOARDS.ROUTE);
    ClientServices.getAllClients()
      .then((res) => {
        let clients = [];
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        response = response.filter((f) => !IsTBRIClient(f));
        let totalClients = clients.concat(response);
        totalClients.forEach((r) => {
          r.is_selected = r.id === this.state.clientId ? true : false;
        });
        let clientid = totalClients.find((c) => c.is_selected === true)
          ? totalClients.find((c) => c.is_selected === true)
          : totalClients[0];
        let clientName = clientid.name;
        clientid = clientid["id"];
        if (clientid === totalClients[0]["id"]) {
          totalClients[0].is_selected = true;
        }
        this.setState(
          {
            clientId: clientid,
            clientName,
          },
          () => {
            let tokenDetails = decodeJWT(localStorage.getItem("token"));
            if (
              isRoleAccountManager(this.props.home.loginUserSuccess.role_id)
            ) {
              this.setState({ isRoleAccountManager: true });
              this.getAssignedClients();
            }
            if (
              this.props.home.loginUserSuccess.role_id !== 4 &&
              this.props.home.loginUserSuccess.role_id !== 28
            ) {
              this.setState({
                disableStatus: true,
              });
            } else {
              this.setState({
                showActions: true,
              });
            }
          }
        );
        this.props.setClients(totalClients);
        this.getUsersList();
      })

      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  getUserDetails() {
    ClientServices.getClientUserDetails(this.state.userId)
      .then((res) => {
        let created_at = res.data.created_at;
        let currentDate = moment();
        let diff = currentDate.diff(moment(created_at), "days");
        this.setState({
          userData: res.data,
          ...(diff < 60 && {
            start_date: moment(created_at).format("YYYY-MM-DD"),
          }),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  getAssignedClients() {
    ClientServices.getAssignedClients("/getAssignedClients")
      .then((res) => {
        let clientData = res.data.data;
        clientData = clientData.map((data) => {
          return {
            id: data.id,
            name: data.name,
          };
        });
        let index = clientData.find(
          (element) => element.id === this.state.clientId
        );
        if (index) {
          this.setState({
            showActions: true,
          });
        } else {
          this.setState({
            showActions: false,
          });
        }
        this.setState({ AssignedClients: clientData });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  resendWelcomeEmailConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          Resend Verification Link
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to Resend Verification Link?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "resend_welcomemail" },
      dailogModalStyleType: "success-modal",
    });
  };
  resendWelcomeEmail = () => {
    let arr = [];
    arr.push({ id: this.state.userData.id });
    ClientServices.resendEmailVerification(arr)
      .then((res) => {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">Success</h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="activate-body">
              <h2 className="font-weight-semibold mb-0">
                Verification mail has been sent
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "success-modal",
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  getBoardVisited() {
    const userId = this.state.userId;
    let body = {
      user_id: userId,
      event_name: constants.GA.EVENTS.PAGE_VISIT,
      page_type: constants.GA.PAGE_TYPE.BOARD,
      is_published: 1,
    };
    ReportsServices.getEventSpecificLogData(body).then((res) => {
      this.setState(
        {
          boardsVisitedData: res?.data,
        },
        () => {}
      );
    });
  }

  getBoardCreated() {
    let body = {
      user_id: this.state.userId,
    };
    ReportsServices.getBoardCreatedData(body).then((res) => {
      this.setState({
        boardsCreatedData: res?.data,
      });
    });
  }

  sendResetPasswordLink() {
    let body = { email: this.state.userData.email, is_client: true };
    UserServices.sendverificationmailForPasswordSetting(body)
      .then((data) => {
        Toast(data.data.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  getUserBoardViewsData(body) {
    if (this.validatePayload(body)) {
      ReportsServices.getModuleScrollAndTimeSpent(body)
        .then((res) => {
          let BoardData =
            res.data.data && res.data.data.length ? res.data.data : [];
          BoardData = BoardData.map((data, index) => {
            return {
              board_name: data.boardName,
              timestamp: ComapnyDetailsDateTimeFormatter(
                moment(data.timeStamp / 1000) - data.timeOnpage * 1000,
                false
              ),
              timespent: this.timeconversion(data.timeOnpage),
              timespent_sort: data.timeOnpage,
              scroll: `${data.scrollValue ?? 0}`,
            };
          });

          this.setState(
            {
              rowData: BoardData,
              currentData: BoardData.slice(0, this.state.pageSize),
              count: BoardData.length,
              sortColumn: constants.PAGINATION.DEFAULT_REPORTS4_SORT_COLUMN,
              sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
            },
            () => {
              this.sortTableData();
            }
          );
        })
        .catch((error) => {
          this.setState({ rowData: [], count: 0, currentData: [] });
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  }

  getTableData() {
    const userId = this.state.userId;
    this.changeRoute();
    let body = {
      user_id: userId,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      page_type: constants.GA.PAGE_TYPE.BOARD,
    };
    switch (this.state.selectedTab) {
      case "user-board-views":
        this.getUserBoardViewsData(body);
        break;
      case "group-by-boards":
        this.getUserGroupByBoards(body);
        break;
      case "board-created-by":
        this.getBoardCreatedBy(body);
        break;
      default:
    }
  }

  timeconversion(number) {
    if (!isNaN(number)) {
      let hours = number / 3600 > 0 ? Math.floor(number / 3600) : 0;
      let minutes =
        (number % 3600) / 60 > 0 ? Math.floor((number % 3600) / 60) : 0;
      let seconds = (number % 3600) % 60 > 0 ? (number % 3600) % 60 : 0;
      let hour_result =
        hours === 0 ? `` : hours > 1 ? `${hours} Hours` : `${hours} Hour`;
      let min_result =
        minutes === 0
          ? ``
          : minutes > 1
          ? `${minutes} Minutes`
          : `${minutes} Minute`;
      let sec_result =
        seconds === 0
          ? ``
          : seconds > 1
          ? `${seconds} Seconds`
          : `${seconds} Second`;
      return `${hour_result} ${min_result} ${sec_result}`.trim();
    }
    return "-";
  }

  getBoardsAccordianData = (body) => {
    let bool = this.state.selectedTab === "group-by-boards";
    ReportsServices.boardAccordianData(body)
      .then((res) => {
        let BoardData =
          res.data.data && res.data.data.length ? res.data.data : [];
        BoardData = BoardData.map((data, index) => {
          return {
            timeStamp: ComapnyDetailsDateTimeFormatter(
              moment(data.timeStamp / 1000 - data.timeOnPage * 1000),
              false
            ),
            scroll: data.scroll ? data.scroll : 0,
            timeSpent: this.timeconversion(data.timeOnPage),
            ...(!bool && { userName: data.userName }),
          };
        });
        let data = this.state.rowData;
        let index = data.findIndex((obj) => obj.id === body.board_id);
        data[index].children = BoardData;
        this.setState({ rowData: data });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0, currentData: [] });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  //checks for falsy values in payload like undefined ,null etc
  validatePayload(obj) {
    let isValid = !Object.values(obj).some((value) => !value);
    return isValid;
  }

  getUserGroupByBoards(body) {
    let headers = this.state.innerHeaders;
    let bool = headers.some((e) => e.id === "userName");
    if (bool) {
      headers.shift();
      this.setState({ innerHeaders: headers });
    }
    if (this.validatePayload(body)) {
      ReportsServices.groupByBoardData(body)
        .then((res) => {
          let BoardData =
            res.data.data && res.data.data.length ? res.data.data : [];
          BoardData = BoardData.map((data, index) => {
            return {
              id: data.id,
              board_name: data.name,
              visits: data.visitCount ?? 0,
              maxScroll: data.maxScroll ?? 0,
              minScroll: data.minScroll ?? 0,
              downloadCount: data.downloadCount ?? 0,
              commentsCount: data.commentsCount,
              sharedCount: data.sharedCount,
              timespent: data.timeSpentCount
                ? this.timeconversion(data.timeSpentCount)
                : 0,
              timespent_sort: data.timeSpentCount,
            };
          });
          this.setState(
            {
              rowData: BoardData,
              currentData: BoardData.slice(0, this.state.pageSize),
              count: BoardData.length,
              sortColumn:
                constants.PAGINATION
                  .DEFAULT_REPORTS_GROUP_BY_BOARDS_SORT_COLUMN,
              sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
            },
            () => {
              this.sortTableData();
            }
          );
        })
        .catch((error) => {
          this.setState({ rowData: [], count: 0, currentData: [] });
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  }

  getBoardCreatedBy = (body) => {
    body.created_by = body.user_id;
    let headers = this.state.innerHeaders;
    let bool = headers.some((e) => e.id === "userName");
    if (!bool) {
      headers.unshift({
        id: "userName",
        title: "User",
        sortable: false,
        visibility: true,
        rowStyle: { textAlign: "left" },
      });
      this.setState({ innerHeaders: headers });
    }
    if (this.validatePayload(body)) {
      ReportsServices.getCreatedByBoardsData(body)
        .then((res) => {
          let BoardData =
            res.data.data && res.data.data.length ? res.data.data : [];
          BoardData = BoardData.map((data, index) => {
            return {
              id: data.id,
              board_name: data.name,
              visits: data.visitCount ?? 0,
              maxScroll: data.maxScroll ?? 0,
              minScroll: data.minScroll ?? 0,
              downloadCount: data.downloadCount ?? 0,
              commentsCount: data.commentsCount,
              sharedCount: data.sharedCount,
              timespent: data.timeSpentCount
                ? this.timeconversion(data.timeSpentCount)
                : 0,
              timespent_sort: data.timeSpentCount,
            };
          });
          this.setState(
            {
              rowData: BoardData,
              currentData: BoardData.slice(0, this.state.pageSize),
              count: BoardData.length,
              sortColumn:
                constants.PAGINATION
                  .DEFAULT_REPORTS_GROUP_BY_BOARDS_SORT_COLUMN,
              sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
            },
            () => {
              this.sortTableData();
            }
          );
        })
        .catch((error) => {
          this.setState({ rowData: [], count: 0, currentData: [] });
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };
  handleAccordianClicks = (e, row) => {
    const index = this.state.clickedValues.indexOf(row.id);
    let values = this.state.clickedValues;
    if (index < 0) {
      this.handleInnerData(row);
      values.push(row.id);
    } else {
      values.splice(index, 1);
    }
    this.setState({
      clickedValues: values,
    });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      let start = (this.state.pageNo - 1) * this.state.pageSize;
      let end = start + this.state.pageSize;
      end = end > this.state.count ? this.state.count : end;
      this.setState({
        currentData: this.state.rowData.slice(start, end),
        clickedValues: [],
      });
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => {
        let start = (this.state.pageNo - 1) * this.state.pageSize;
        let end = start + this.state.pageSize;
        end = end > this.state.count ? this.state.count : end;
        this.setState({
          currentData: this.state.rowData.slice(start, end),
          clickedValues: [],
        });
      }
    );
  };

  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => {
        let start = (this.state.pageNo - 1) * this.state.pageSize;
        let end = start + this.state.pageSize;
        end = end > this.state.count ? this.state.count : end;
        this.setState({
          currentData: this.state.rowData.slice(start, end),
          clickedValues: [],
        });
      }
    );
  };

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => {
      let start = (this.state.pageNo - 1) * this.state.pageSize;
      let end = start + this.state.pageSize;
      end = end > this.state.count ? this.state.count : end;
      this.setState({
        currentData: this.state.rowData.slice(start, end),
        clickedValues: [],
      });
    });
  };
  getDateDifference = (fromDate, endDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(endDate);
    const diffTime = date2 - date1;
    if (diffTime < 0) {
      return false;
    }
    return true;
  };

  changeFromDate = (e, value) => {
    if (value !== null) {
      let bool =
        e === "start"
          ? this.getDateDifference(value, this.state.end_date)
          : this.getDateDifference(this.state.start_date, value);
      if (bool && e === "start") {
        this.setState(
          {
            start_date: moment(value).format("YYYY-MM-DD"),
            pageNo: 1,
          },
          () => {
            if (this.state.userId != null) {
              this.getBoardCreated();
              this.getBoardVisited();
              this.getTableData();
            }
          }
        );
      } else if (bool) {
        this.setState(
          {
            end_date: moment(value).format("YYYY-MM-DD"),
            pageNo: 1,
          },
          () => {
            if (this.state.userId != null) {
              this.getTableData();
              this.getBoardCreated();
              this.getBoardVisited();
            }
          }
        );
      }
    }
  };
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.REPORTS_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.REPORTS.BOARDS.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true)
      : "all";
    let clientName = clientId.name;
    clientId = clientId.id;
    if (this.state.isRoleAccountManager) {
      let clientData = this.state.AssignedClients;
      let index = clientData.find((element) => element.id === clientId);
      if (index) {
        this.setState({
          showActions: true,
        });
      } else {
        this.setState({
          showActions: false,
        });
      }
    }
    this.setState(
      {
        clientId: clientId,
        clientName: clientName,
        pageNo: 1,
        count: 0,
      },
      () => {
        this.getUsersList();
      }
    );
  };
  handleUserChange = (e) => {
    let users = [...this.props.client.users];
    let selectedUser = users[0];
    let userId, isRoleMember;
    users.forEach((c) => {
      if (c.id === e.id) {
        c.is_selected = true;
        selectedUser = c;
      } else {
        c.is_selected = false;
      }
    });
    this.props.setUsers(users);
    if (selectedUser) {
      userId = selectedUser.id;
      isRoleMember = selectedUser.isRoleMember;
    }
    this.setState(
      {
        userId: userId,
        pageNo: 1,
        isRoleMember,
      },
      () => {
        this.getUserDetails();
        this.getTableData();
        this.getBoardVisited();
        if (!isRoleMember) {
          this.setState(
            {
              totalTabs: constants.APPLICATION_ROUTE.REPORTS.BOARDS.TABS,
            },
            () => {
              this.getBoardCreated();
            }
          );
        } else {
          let bool = this.state.selectedTab === "board-created-by";
          this.setState({
            totalTabs: constants.APPLICATION_ROUTE.REPORTS.BOARDS.TABS.slice(
              0,
              2
            ),
            ...(bool && {
              selectedTab:
                constants.APPLICATION_ROUTE.REPORTS.BOARDS.TABS[0].KEY,
            }),
          });
        }
      }
    );
  };

  getUsersList() {
    let url = this.state.clientId
      ? `/client/${this.state.clientId}/null/users`
      : ""; // 3 is for all actve and inactive users for status as route is modified for users in client module
    ClientServices.getClientUsers(url)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let totalUsers = response;
        if (totalUsers.length != 0) {
          totalUsers = totalUsers.map((r) => {
            return {
              id: r.id,
              name: r.first_name + " " + r.last_name,
              is_selected: r.id === this.state.userId ? true : false,
              isRoleMember:
                r.role && r.role.short_name
                  ? r.role.short_name === "member"
                  : 0,
            };
          });

          totalUsers = totalUsers.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          );
          let selectedUser = totalUsers.find((c) => c.is_selected === true)
            ? totalUsers.find((c) => c.is_selected === true)
            : totalUsers[0];
          let userid = selectedUser.id;
          let isRoleMember = selectedUser.isRoleMember;
          if (userid === totalUsers[0]["id"]) {
            totalUsers[0].is_selected = true;
          }
          let totalTabs = constants.APPLICATION_ROUTE.REPORTS.BOARDS.TABS;
          if (isRoleMember) {
            totalTabs = constants.APPLICATION_ROUTE.REPORTS.BOARDS.TABS.slice(
              0,
              2
            );
          }
          this.setState(
            {
              userId: userid,
              pageNo: 1,
              isRoleMember,
              totalTabs,
            },
            () => {
              this.getUserDetails();
              this.getTableData();
              this.getBoardVisited();
              if (!isRoleMember) {
                this.getBoardCreated();
              }
            }
          );
          this.props.setUsers(totalUsers);
        } else {
          totalUsers = [];
          totalUsers.push({
            id: "na",
            name: "No Users Found",
            is_selected: true,
          });
          this.setState({
            userId: null,
            userData: [],
            rowData: [],
            pageNo: 1,
            currentData: [],
            boardsVisitedData: {},
            boardsCreatedData: {},
          });
          this.props.setUsers(totalUsers);
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }

  dateformatChanger = (input) => {
    let dateobj = new Date(input);
    let momentobj = new moment(dateobj);
    let old_date = momentobj.format("MM-DD-YYYY");
    return old_date;
  };

  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        pageNo: 1,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => {
        this.sortTableData();
      }
    );
  };
  sortTableData = () => {
    this.changeRoute();
    let sortOrder = this.state.sortOrder;
    let sortColumn = this.state.sortColumn;
    let totalData = this.state.rowData;
    let bool = false;
    sortColumn = sortColumn === "timespent" ? "timespent_sort" : sortColumn;
    totalData.sort((a, b) => {
      const aLocale = this.convertType(a[sortColumn]);
      const bLocale = this.convertType(b[sortColumn]);
      if (aLocale === bLocale) {
        return 0;
      } else if (aLocale === undefined || aLocale > bLocale) {
        bool = true;
        return 1;
      } else if (bLocale === undefined || aLocale < bLocale) {
        bool = true;
        return -1;
      }
    });
    if (bool) {
      totalData = sortOrder === "desc" ? totalData.reverse() : totalData;
    }
    this.setState({
      rowData: totalData,
      currentData: totalData.slice(0, this.state.pageSize),
      pageNo: 1,
    });
  };
  convertType = (data) => {
    switch (this.state.sortColumn) {
      case "timestamp":
        return new Date(data).getTime();
      case "board_name":
        return data;
      default:
        return Number(data);
    }
  };
  emailValidationHandler = () => {
    ClientServices.getSingleUserBounceValidation(
      this.state.userData.email,
      this.state.userData.id
    )
      .then((res) => {
        if (res.data.status.toLowerCase().trim() === "invalid") {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">User Invalid</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "danger-modal",
          });
        } else if (
          res.data.status.toLowerCase() === "catch_all" ||
          res.data.status.toLowerCase().trim() === "catchall"
        ) {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Verify Email</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="inactive-body">
                <h2 className="font-weight-semibold mb-0">
                  Please Verify User Email Manually
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "inactive-modal",
          });
        } else {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Success</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  User Email is Valid
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "success-modal",
          });
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  removeUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.CLIENT_USER.REMOVE_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_user", id: data },
      dailogModalStyleType: "danger-modal",
    });
  };
  sendResetPasswordLinkConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Reset Password Link</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to send reset password link?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "reset_password" },
      dailogModalStyleType: "success-modal",
    });
  };
  emailValidationConfirmation = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          User Email Validation
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to validate user?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "email_validation" },
      dailogModalStyleType: "success-modal",
    });
  };
  removeClientUser = (clientUserId) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.removeClientUser(arr)
      .then((res) => {
        Toast(constants.CLIENT_USER.REMOVE.SUCCESS, "success");
        this.getUsersList();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_user":
        this.removeClientUser(config.id);
        break;
      case "reset_password":
        this.sendResetPasswordLink();
        break;
      case "email_validation":
        this.emailValidationHandler();
        break;
      case "resend_welcomemail":
        this.resendWelcomeEmail();
        break;
      case "de-activate_user":
        this.toggleClientUser(config.id, 0);
        break;
      case "activate_user":
        this.toggleClientUser(config.id, 1);
        break;
      default:
        break;
    }
  };
  changeRoute = () => {
    let queryParams = "";
    if (this.state.selectedTab) {
      queryParams = "&selected_tab=" + this.state.selectedTab;
    }
    if (this.state.sortOrder) {
      queryParams +=
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.REPORTS.BOARDS.ROUTE),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };
  toggleClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
            <div className="custom-select-new custom-select-dropdown box-shadow-none d-inline-block mt-3">
              <select onChange={this.handleReasonchange}>
                <option value="1" selected>
                  User Requested
                </option>
                <option value="2">Email bounced</option>
                <option value="3">ClearOut Invalid</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.CLIENT_USER.ACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent,
      dailogModalStyleType:
        data.is_active == 1 ? "danger-modal" : "success-modal",
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_user" : "activate_user",
        id: this.state.userId,
      },
    });
  };
  handleReasonchange = (e) => {
    let value = e.target.value;
    let reason;
    switch (value) {
      case "1":
        reason = "User Requested";
        break;
      case "2":
        reason = "Email bounced";
        break;
      case "3":
        reason = "ClearOut Invalid";
        break;
    }
    this.setState({
      selectReason: reason,
    });
  };
  toggleClientUser = (clientUserId, isActive) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.toggleClientUser(isActive, {
      userIds: arr,
      reason: isActive === 1 ? "" : this.state.selectReason,
    })
      .then((res) => {
        Toast(
          isActive
            ? constants.CLIENT_USER.ACTIVATE.SUCCESS
            : constants.CLIENT_USER.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getUserDetails();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  onContentChangeTypeHandler = (s) => {
    this.setState(
      {
        selectedTab: s,
        rowData: [],
        currentData: [],
        pageNo: 1,
        clickedValues: [],
        count: 0,
      },
      () => {
        this.getTableData();
      }
    );
  };
  handleInnerData = (row) => {
    if (!row.children) {
      let body = {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        board_id: row.id,
      };
      if (this.state.selectedTab === "group-by-boards") {
        body.user_id = this.state.userId;
      }
      this.getBoardsAccordianData(body);
    }
  };

  render() {
    let clients =
      this.props.client && this.props.client.clients
        ? this.props.client.clients
        : [];
    let users =
      this.props.client && this.props.client.users
        ? this.props.client.users
        : [];
    return (
      <>
        <aside className="main_content">
          <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
            <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            <div className="tbr-client-list">
              <div className="row gutter-8">
                <div className="col-xl-3 col-md-4">
                  <h1 className="text-ligntblue2 font-weight-bold client-list-h1">
                    TBR Client User
                  </h1>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                      <div className="card custom-card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            #Boards Last Visited
                          </h5>
                          <div className="stats-group d-flex">
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.boardsVisitedData?.sevenDaysCount}
                              </h5>
                              <label>7 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.boardsVisitedData?.thirtyDaysCount}
                              </h5>
                              <label>30 days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.boardsVisitedData?.sixtyDaysCount}
                              </h5>
                              <label>60 Days</label>
                            </div>
                            <div className="stats-block flex-grow-1 d-flex flex-column">
                              <h5 className="font-weight-bold text-blue">
                                {this.state.boardsVisitedData?.nintyDaysCount}
                              </h5>
                              <label>90 Days</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!this.state.isRoleMember ? (
                      <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                        <div className="card custom-card">
                          <div className="card-body">
                            <h5 className="font-weight-bold text-black2 mb-1">
                              #Boards Last Created
                            </h5>
                            <div className="stats-group d-flex">
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {this.state.boardsCreatedData?.sevenDaysCount}
                                </h5>
                                <label>7 Days</label>
                              </div>
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {
                                    this.state.boardsCreatedData
                                      ?.thirtyDaysCount
                                  }
                                </h5>
                                <label>30 days</label>
                              </div>
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {this.state.boardsCreatedData?.sixtyDaysCount}
                                </h5>
                                <label>60 Days</label>
                              </div>
                              <div className="stats-block flex-grow-1 d-flex flex-column">
                                <h5 className="font-weight-bold text-blue">
                                  {this.state.boardsCreatedData?.nintyDaysCount}
                                </h5>
                                <label>90 Days</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            Boards
                          </h5>
                          <div className="chartpreview-content text-center">
                            <div className="mt-4">
                              <BoardsChart
                                userId={this.state.userId}
                                startDate={this.state.start_date}
                                endDate={this.state.end_date}
                                isRoleMember={this.state.isRoleMember}
                                isPublished={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-12 mt-5">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="font-weight-bold text-black2 mb-1">
                            Time spent in Minutes
                          </h5>
                          <div className="chartpreview-content text-center">
                            <div className="mt-4">
                              <Time_On_Boards
                                userId={this.state.userId}
                                startDate={this.state.start_date}
                                endDate={this.state.end_date}
                                isPublished={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-9 col-md-8">
                  <div className="reports-right-block">
                    <div className="d-flex row flex-wrap client-list-filter-block mt-1">
                      {/* <div className="col-md-3 col-xl-3"> */}
                      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                        <div className="custom-combobox-list with-noborder with-shadow-sm">
                          <CustomDropdownList
                            data={clients}
                            handleChange={this.handleClientChange}
                            className="reports_drop_down"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-6 clients_dropdown">
                        <div className="custom-combobox-list with-noborder with-shadow-sm">
                          <CustomDropdownList
                            data={users}
                            handleChange={this.handleUserChange}
                            className="reports_drop_down"
                          />
                        </div>
                      </div>
                      <div className="col clients_dropdown">
                        <div className="d-flex">
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="start"
                            date={this.state.start_date}
                            onChangeHandler={this.changeFromDate}
                            min={
                              this.state.userData &&
                              this.state.userData?.created_at
                                ? moment(
                                    this.state.userData?.created_at
                                  ).format("YYYY-MM-DD")
                                : null
                            }
                            max={this.state.end_date}
                          />
                          <CustomDatePicker
                            className="custom_dates custom-combobox-list with-noborder with-shadow-sm"
                            id="end"
                            date={this.state.end_date}
                            onChangeHandler={this.changeFromDate}
                            min={this.state.start_date}
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                    <h3 className="text-black2 main-h3 icon-fs-17 mb-0 caps sections-card-wrapper p-2">
                      {this.state.userData.first_name &&
                      this.state.userData.last_name
                        ? this.state.userData.first_name +
                          " " +
                          this.state.userData.last_name
                        : "User"}{" "}
                      (
                      {this.state.start_date
                        ? ComapnyDetailsDateFormatter(
                            this.state.start_date,
                            false
                          )
                        : "-"}{" "}
                      to{" "}
                      {this.state.end_date
                        ? ComapnyDetailsDateFormatter(
                            this.state.end_date,
                            false
                          )
                        : "-"}
                      )
                    </h3>
                    <div className="sections-card-wrapper user-details-card mt-3">
                      <div className="d-flex flex-wrap flex-xl-nowrap sections-card-row-1">
                        <div className="sections-card flex-grow-0 border-right border-bottom profile-card d-flex justify-content-center align-items-center">
                          <span className="material-icons">account_circle</span>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              {this.state.userData.first_name &&
                              this.state.userData.last_name
                                ? this.state.userData.first_name +
                                  " " +
                                  this.state.userData.last_name
                                : "-"}
                            </div>
                            <label>Name</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              {this.state.userData.client &&
                              this.state.userData.client.name
                                ? this.state.userData.client.name
                                : "-"}
                            </div>
                            <label>Client</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              <a
                                className="text-reset"
                                href={`mailto: ${
                                  this.state.userData &&
                                  this.state.userData.email
                                    ? this.state.userData.email
                                    : ""
                                }`}
                              >
                                {this.state.userData.email
                                  ? this.state.userData.email
                                  : "-"}
                              </a>
                            </div>
                            <label>Email Id</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            <div className="font-weight-semibold mb-1">
                              {this.state.userData.created_at &&
                              this.state.userData.created_at
                                ? ComapnyDetailsDateTimeFormatter(
                                    this.state.userData.created_at,
                                    false
                                  )
                                : "-"}
                            </div>
                            <label>User Created At</label>
                          </div>
                        </div>
                        <div className="sections-card border-right border-bottom text-center">
                          <div className="d-flex flex-column">
                            {this.state.userData.is_active === 1 ||
                            this.state.userData.is_active == 0 ? (
                              <div className="custom-control custom-switch success-toggle">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customSwitch${this.state.userData.id}`}
                                  checked={this.state.userData.is_active === 1}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    this.toggleClientUserConfirmationHandler(
                                      this.state.userData
                                    );
                                  }}
                                  disabled={!this.state.showActions}
                                />
                                <label
                                  className="custom-control-label"
                                  for={`customSwitch${this.state.userData.id}`}
                                ></label>
                              </div>
                            ) : this.state.userData?.is_active ? (
                              <label className="font-weight-semibold mb-1 text-red">
                                Pending
                              </label>
                            ) : (
                              "-"
                            )}
                            <label>Status</label>
                          </div>
                        </div>

                        {this.state.showActions && (
                          <AclAction
                            type={constants.ACL.PERMISSION_KEY}
                            entity={constants.ACL.REPORTS_USER_RESOURCE.TITLE}
                            action={constants.ACL.REPORTS_USER_RESOURCE.TAB}
                          >
                            <div
                              className="sections-card border-bottom text-center py-3"
                              tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                            >
                              <div className="d-flex justify-content-center">
                                {this.state.userData.is_active == 1 ? (
                                  <>
                                    <span
                                      className="material-icons-outlined text-orange mx-2 cursor-pointer"
                                      onClick={this.emailValidationConfirmation}
                                      data-for={"tool-tip-email"}
                                      data-tip={"User Bounce Validation"}
                                      data-iscapture="true"
                                    >
                                      email
                                      <CustomReactTooltip
                                        id={"tool-tip-email"}
                                      />
                                    </span>
                                    <span
                                      className="material-icons-outlined text-primary mx-2 cursor-pointer"
                                      onClick={
                                        this.sendResetPasswordLinkConfirmation
                                      }
                                      data-for={"tool-tip-resetpassword"}
                                      disabled={
                                        !this.state.userData.is_set_password
                                      }
                                      data-tip={
                                        !this.state.userData.is_set_password
                                          ? "Password cannot be set as your account is managed via SAML authentication."
                                          : "Send Reset Password Link"
                                      }
                                    >
                                      lock_reset
                                      <CustomReactTooltip
                                        id={"tool-tip-resetpassword"}
                                      />
                                    </span>
                                  </>
                                ) : this.state.userData.is_active == 0 ? (
                                  <>
                                    <span
                                      className="material-icons-outlined text-primary mx-2 cursor-pointer"
                                      onClick={
                                        this.sendResetPasswordLinkConfirmation
                                      }
                                      data-for={"tool-tip-resetpassword"}
                                      disabled={
                                        !this.state.userData.is_set_password
                                      }
                                      data-tip={
                                        !this.state.userData.is_set_password
                                          ? "Password cannot be set as your account is managed via SAML authentication."
                                          : "Send Reset Password Link"
                                      }
                                    >
                                      lock_reset
                                      <CustomReactTooltip
                                        id={"tool-tip-resetpassword"}
                                      />
                                    </span>
                                  </>
                                ) : this.state.userData.first_name &&
                                  this.state.userData.client.name &&
                                  this.state.userData.email ? (
                                  <span
                                    className="material-icons-round text-primary mx-2 cursor-pointer"
                                    onClick={
                                      this.resendWelcomeEmailConfirmation
                                    }
                                    data-for={"tool-tip-send"}
                                    data-tip={"Resend Email Verificaiton Link"}
                                  >
                                    send
                                    <CustomReactTooltip
                                      id={"tool-tip-send"}
                                    />{" "}
                                  </span>
                                ) : (
                                  "-"
                                )}
                                {this.state.userData.first_name &&
                                  this.state.userData.client.name &&
                                  this.state.userData.email && (
                                    <AclAction
                                      type={constants.ACL.PERMISSION_KEY}
                                      entity={
                                        constants.ACL.REPORTS_USER_RESOURCE
                                          .TITLE
                                      }
                                      action={
                                        constants.ACL.REPORTS_USER_RESOURCE
                                          .ACTIONS.DELETE
                                      }
                                    >
                                      <span
                                        tbrentitytype={
                                          constants.ACL.ENTITY_TYPE.BUTTON
                                        }
                                        className="material-icons-outlined text-red mx-2 cursor-pointer "
                                        onClick={() =>
                                          this.removeUserConfirmationHandler(
                                            this.state.userData.id
                                          )
                                        }
                                        data-for={"tool-tip-delete"}
                                        data-tip={"Delete"}
                                      >
                                        delete
                                        <CustomReactTooltip
                                          id={"tool-tip-delete"}
                                        />{" "}
                                      </span>
                                    </AclAction>
                                  )}
                              </div>
                              <label>Actions</label>
                            </div>
                          </AclAction>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="btn-items-card-wrapper tabs-card-wrapper">
                      <div className="btn-items-card card custom-card nav-tabs-card">
                        <div className="card-body">
                          <div className="d-flex">
                            <Nav
                              tabs
                              tbrentitytype={
                                constants.ACL.ENTITY_TYPE.NAVIGATION
                              }
                              className="custom-nav-tabs reports-nav-tabs"
                            >
                              {this.state.totalTabs.map((tab) => (
                                <NavItem key={tab.KEY}>
                                  <NavLink
                                    className={classnames({
                                      active:
                                        this.state.selectedTab === tab.KEY,
                                    })}
                                    onClick={() => {
                                      this.onContentChangeTypeHandler(tab.KEY);
                                    }}
                                  >
                                    {tab.TAB_TITLE}
                                  </NavLink>
                                </NavItem>
                              ))}
                            </Nav>
                          </div>
                        </div>
                      </div>
                    </div>
                    <TabContent activeTab={this.state.selectedTab}>
                      {this.state.totalTabs.map((tab) => (
                        <TabPane tabId={tab.KEY} key={tab.KEY}>
                          <div className="custom-table-block first-col-fixed-block sort-table-block detail-panel-table-block nowrap-table-block client-list-table-block">
                            {this.state.selectedTab &&
                            this.state.selectedTab === tab.KEY &&
                            tab.KEY === "user-board-views" ? (
                              <DynamicTableComponentNew
                                columnData={tableHeader}
                                rowData={this.state.currentData}
                                pagePrevious={this.pagePrevious}
                                pageNext={this.pageNext}
                                pageSize={this.state.pageSize}
                                pageNo={this.state.pageNo}
                                handlePage={this.handlePage}
                                handlePageSize={this.handlePageSize}
                                count={this.state.count}
                                colSpan={
                                  tableHeader.filter((h) => h.visibility).length
                                }
                                handleSort={this.handleSort}
                                sortColumn={this.state.sortColumn}
                                paginationClass={
                                  "center-pagination custom-pagination-new"
                                }
                                sortOrder={this.state.sortOrder}
                                showPagination={true}
                              />
                            ) : (
                              <DynamicTableComponentWithChevron
                                columnData={tableHeader1}
                                innerColumn={this.state.innerHeaders}
                                rowData={this.state.currentData}
                                pagePrevious={this.pagePrevious}
                                pageNext={this.pageNext}
                                pageSize={this.state.pageSize}
                                pageNo={this.state.pageNo}
                                handlePage={this.handlePage}
                                handlePageSize={this.handlePageSize}
                                count={this.state.count}
                                colSpan={9}
                                handleSort={this.handleSort}
                                sortColumn={this.state.sortColumn}
                                sortOrder={this.state.sortOrder}
                                handleInnerData={this.handleInnerData}
                                clickedValues={this.state.clickedValues}
                                handleAccordianClicks={
                                  this.handleAccordianClicks
                                }
                                showPagination={true}
                                paginationClass={
                                  "center-pagination custom-pagination-new"
                                }
                              />
                            )}
                          </div>
                        </TabPane>
                      ))}
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setUsers: (allUsers) => {
      dispatch(users(allUsers));
    },
  };
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
    user: state.user,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsBoardsPage);
