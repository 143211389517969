import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { NavLink } from "reactstrap";
import AclAction from "../Components/ACL/AclAction";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import DailogNew from "../Components/DailogNew";
import DynamicTableWithSelectAllComponentNew from "../Components/DynamicTableWithSelectAllNew";
import Search from "../Components/Search";
import permissionCheck from "../lib/permissionCheck";
import { UserServices } from "../Services/User";
import { clients } from "../Store/Actions/Client";
import { activeMenuTab } from "../Store/Actions/User";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";
import { downloadExcelFile } from "../Helpers/Helper";
import MAGIC_LINK_CONSTANTS from "./MagicLinkUsers/utils/constants";
import { ClientServices } from "../Services/Client";
import { MagicLinkUserServices } from "../Services/MagicLinkUserServices";
import Copy from "../Utils/Copy";
import { formatHoursToReadableTime } from "../Utils/helper";

const tableHeader = [
  {
    id: "first_name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "role",
    title: "Role",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "designation",
    title: "Designation",
    sortable: false,
    headerStyle: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
    visibility: true,
  },
  {
    id: "last_login_datetime",
    title: "Last Login At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "auth_type",
    title: "Auth Type",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "expires_on",
    title: "Expiration time",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];
const tableExportHeader = [
  { id: "first_name", title: "Name" },
  { id: "email", title: "Email" },
  { id: "role", title: "Role" },
  { id: "designation", title: "Designation" },
  { id: "status", title: "Status" },
  { id: "last_login_datetime", title: "Last Login At" },
  { id: "auth_type", title: "Auth Type" },
  { id: "created_by", title: "Created By" },
  { id: "created_at", title: "Created On" },
];
const showActions = false;

class ListAdminUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: "last_login_datetime",
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      totalData: [],
      downloadAction: null,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      documentModal: false,
      isFormSubmitted: false,
      formErrors: {},
      apiError: null,
      currentPageDataCount: 0,
      selectReason: "",
      disableStatus: false,
      addUsers: true,
      submitButtonLabel: "Confirm",
    };
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.TBRI_USER.LIST.NAME);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column ? params.sort_column : "created_at",
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE);
    let index = tableHeader.findIndex((element) => element.id == "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }

  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };

  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };

  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };

  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };

  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  toggleAdminUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TBRI_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.TBRI_USER.ACTIVATE_USER_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_user" : "activate_user",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  handleReasonchange = (e) => {
    let value = e.target.value;
    let reason;
    switch (value) {
      case "1":
        reason = "some option is there";
        break;
      case "2":
        reason = "User Requested";
        break;
      case "3":
        reason = "Email bounced";
        break;
      case "4":
        reason = "ClearOut Invalid";
        break;
    }
    this.setState({
      selectReason: reason,
    });
  };
  removeAdminUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Company</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.TBRI_USER.REMOVE_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_user", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeAdminUser = (userId) => {
    UserServices.removeUser(userId)
      .then((res) => {
        Toast(constants.TBRI_USER.REMOVE.SUCCESS, "success");
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  toggleAdminUser = (userId, isActive) => {
    let arr = [];
    arr.push({ id: userId });
    UserServices.toggleUser(isActive, {
      userIds: arr,
      reason: isActive === 1 ? "" : this.state.selectReason,
    })
      .then((res) => {
        Toast(
          isActive
            ? constants.TBRI_USER.ACTIVATE.SUCCESS
            : constants.TBRI_USER.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_user":
        this.removeAdminUser(config.id);
        break;
      case "de-activate_user":
        this.toggleAdminUser(config.id, 0);
        break;
      case "activate_user":
        this.toggleAdminUser(config.id, 1);
        break;
      case "remove_magic_link_user":
        this.deleteMagicLinkUser(config.id);
        break;
      case "resend_magic_link":
        this.resendEmailVerificationLink(config.id);
        break;
      default:
        break;
    }
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  getActions = (data) => {
    let disabled = data.id === this.props.home.loginUserSuccess.id;
    return (
      <>
        {data.is_magic ? (
          <>
            {data.is_active === 1 ? (
              <>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
                  }
                  action={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.LIST
                  }
                >
                  <button
                    disabled={disabled}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .VALIDATE_USER_TOOL_TIP
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                          .VALIDATE_USERS,
                        data,
                        e
                      );
                    }}
                    className="btn btn-md d-inline-flex align-items-center"
                    data-tip={
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .VALIDATE_USER_TOOL_TIP
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-outlined text-blue-clr icon-fs-20">
                      person_outline
                    </span>
                  </button>
                </AclAction>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
                  }
                  action={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.STATUS
                  }
                >
                  <button
                    disabled={disabled}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                        .RESEND_MAGIC_LINK_TOOLTIP
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK,
                        data,
                        e
                      );
                    }}
                    className={"btn btn-md d-inline-flex align-items-center"}
                    data-tip={
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                        .RESEND_MAGIC_LINK_TOOLTIP
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-blue-clr icon-fs-20">
                      send
                    </span>
                  </button>
                </AclAction>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
                  }
                  action={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.DELETE
                  }
                >
                  <button
                    disabled={disabled}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .DELETE_CLIENT_USER
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                          .DELETE_MAGIC_LINK_USER,
                        data,
                        e
                      );
                    }}
                    className={"btn btn-md d-inline-flex align-items-center"}
                    data-tip={
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .DELETE_CLIENT_USER
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-red icon-fs-20">
                      delete
                    </span>
                  </button>
                </AclAction>
              </>
            ) : (
              <>
                {data.is_active !== 2 && data.password ? (
                  <>
                    <div style={{ width: "100%" }}>-</div>
                  </>
                ) : null}
              </>
            )}
            {data.is_active === 2 && (
              <>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
                  }
                  action={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.STATUS
                  }
                >
                  <button
                    disabled={disabled}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                        .COPY_MAGIC_LINK_TOOLTIP
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                          .COPY_EMAIL_VERIFICATION_TOOLTIP,
                        data,
                        e
                      );
                    }}
                    className={"btn btn-md d-inline-flex align-items-center"}
                    data-tip={
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                        .COPY_MAGIC_LINK_TOOLTIP
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-blue-clr icon-fs-20">
                      add_link
                    </span>
                  </button>
                </AclAction>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
                  }
                  action={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.STATUS
                  }
                >
                  <button
                    disabled={disabled}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                        .RESEND_MAGIC_LINK_TOOLTIP
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK,
                        data,
                        e
                      );
                    }}
                    className={"btn btn-md d-inline-flex align-items-center"}
                    data-tip={
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                        .RESEND_MAGIC_LINK_TOOLTIP
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-blue-clr icon-fs-20">
                      send
                    </span>
                  </button>
                </AclAction>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
                  }
                  action={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.DELETE
                  }
                >
                  <button
                    disabled={disabled}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .DELETE_CLIENT_USER
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                          .DELETE_MAGIC_LINK_USER,
                        data,
                        e
                      );
                    }}
                    className={"btn btn-md d-inline-flex align-items-center"}
                    data-tip={
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .DELETE_CLIENT_USER
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-red icon-fs-20">
                      delete
                    </span>
                  </button>
                </AclAction>
              </>
            )}
          </>
        ) : (
          <>
            {data.is_active === 2 ? (
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
                action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.STATUS}
              >
                <button
                  tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                  disabled={disabled}
                  onClick={(e) => {
                    this.rowAction(
                      constants.APPLICATION_ROUTE.TBRI_USER.LIST
                        .RESEND_EMAIL_VERIFICATION,
                      data,
                      e
                    );
                  }}
                  className={`dynamicTableActionButton btn btn-md d-inline-flex align-items-center`}
                  data-for={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.TBRI_USER.LIST
                      .RESEND_EMAIL_VERIFICATION_TOOLTIP
                  )}-${data.id}`}
                  data-tip={
                    constants.APPLICATION_ROUTE.TBRI_USER.LIST
                      .RESEND_EMAIL_VERIFICATION_TOOLTIP
                  }
                  data-iscapture="true"
                >
                  <span className="material-icons-round text-blue-clr icon-fs-20">
                    send
                  </span>
                </button>
              </AclAction>
            ) : (
              "-"
            )}
          </>
        )}
      </>
    );
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url = `users`;
    UserServices.getUsers(
      `${url}?role_type=tbr_admin&page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let userData = res.data.data;
        this.setState({
          currentPageDataCount: userData.length,
        });

        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        let checkContentPageAccess = permissionCheck(
          constants.ACL.PERMISSION_KEY,
          constants.ACL.TBRI_USER_RESOURCE.TITLE,
          constants.ACL.TBRI_USER_RESOURCE.ACTIONS.EDIT
        );
        // checking permission for status toggle
        let userStatusUpdateAccess = permissionCheck(
          constants.ACL.PERMISSION_KEY,
          constants.ACL.TBRI_USER_RESOURCE.TITLE,
          constants.ACL.TBRI_USER_RESOURCE.ACTIONS.STATUS
        );
        userData = userData.map((data) => {
          return {
            id: data.id,
            first_name:
              checkContentPageAccess && !this.state.disableStatus ? (
                <NavLink
                  tag={RRNavLink}
                  style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
                  exact
                  to={formatRoute(
                    constants.APPLICATION_ROUTE.TBRI_USER.EDIT.ROUTE,
                    { id: data.id }
                  )}
                >
                  <label className={`mb-0`}>{`${
                    data.first_name ? data.first_name : ""
                  } ${data.last_name ? data.last_name : ""}`}</label>
                </NavLink>
              ) : (
                <span>{`${data.first_name ? data.first_name : ""} ${
                  data.last_name ? data.last_name : ""
                }`}</span>
              ),
            email: data.email,
            role: data.role.name,
            expires_on: data.is_magic
              ? data.expiration_time
                ? formatHoursToReadableTime(data.expiration_time)
                : "Unlimited"
              : "-",
            designation: data.designation,
            created_by:
              data.created_by_user && data.created_by_user.id
                ? `${
                    data.created_by_user.first_name
                      ? data.created_by_user.first_name
                      : ""
                  } ${
                    data.created_by_user.last_name
                      ? data.created_by_user.last_name
                      : ""
                  }`
                : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            last_login_datetime: data.last_login_datetime
              ? ComapnyDetailsDateTimeFormatter(data.last_login_datetime, false)
              : "-",
            auth_type: data.is_magic ? "Magic User" : "Normal User",
            inactiveReason: data.inactive_reason ? data.inactive_reason : "-",
            is_active: (data.is_active === 0 || data.is_active === 1) && (
              <div className="custom-control custom-switch success-toggle">
                <input
                  type="checkbox"
                  disabled={
                    !userStatusUpdateAccess ||
                    data.id === this.props.home.loginUserSuccess.id ||
                    this.state.disableStatus
                  }
                  className="custom-control-input"
                  id={`customSwitch${data.id}`}
                  checked={data.is_active === 1}
                  onChange={(e) => {
                    e.stopPropagation();
                    this.rowAction(
                      constants.APPLICATION_ROUTE.TBRI_USER.LIST.STATUS,
                      data,
                      e
                    );
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`customSwitch${data.id}`}
                ></label>
              </div>
            ),
            actions: this.getActions(data),
          };
        });
        this.setState({ rowData: userData, count: count });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  exportList = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url = `users`;
    UserServices.getUsers(`${url}?role_type=tbr_admin` + queryParams)
      .then((res) => {
        let userData = res.data.data;
        userData = userData.map((data) => {
          return {
            id: data.id,
            first_name: `${data.first_name ? data.first_name : ""} ${
              data.last_name ? data.last_name : ""
            }`,
            email: data.email,
            role: data.role.name,
            created_by:
              data.created_by_user && data.created_by_user.id
                ? `${
                    data.created_by_user.first_name
                      ? data.created_by_user.first_name
                      : ""
                  } ${
                    data.created_by_user.last_name
                      ? data.created_by_user.last_name
                      : ""
                  }`
                : "-",
            auth_type: data.is_magic ? "Magic User" : "Normal User",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            last_login_datetime: data.last_login_datetime
              ? ComapnyDetailsDateTimeFormatter(data.last_login_datetime, false)
              : "-",
            status: data.is_active ? "Active" : "InActive",
            actions: this.getActions(data),
          };
        });
        this.setState(
          {
            totalData: userData,
            downloadAction:
              this.state.downloadAction === null
                ? true
                : !this.state.downloadAction,
          },
          () => {
            // Filter the data to include only the defined columns
            const processedColumns = tableExportHeader
              .map((col) => ({
                ...col,
                accessor: col.title,
                header: col.title,
              }))
              .filter((row) => row.id !== "designation");

            // Filter the data to include only the processed columns
            const filteredData = this.state.totalData.map((row) =>
              processedColumns.reduce((obj, col) => {
                console.log(obj, col, row);
                obj[col.accessor] = row[col.id] || row[col.accessor];
                return obj;
              }, {})
            );

            downloadExcelFile("USERS", "Users", filteredData, processedColumns);
          }
        );
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.TBRI_USER.LIST.EDIT_USER:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TBRI_USER.EDIT.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.TBRI_USER.LIST.STATUS:
        this.toggleAdminUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.TBRI_USER.LIST.REMOVE_USER:
        this.removeAdminUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.TBRI_USER.LIST.RESEND_EMAIL_VERIFICATION:
        this.resendEmailVerification(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.VALIDATE_USERS:
        this.clientUserBounceValidationConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .COPY_EMAIL_VERIFICATION_TOOLTIP:
        this.copyEmailVerificationLink(data);
        break;
      case MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.DELETE_MAGIC_LINK_USER:
        this.deleteMagicLinkClientUser(data);
        break;
      case MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK:
        this.resendMagicLink(data);
        break;
      default:
        break;
    }
  };

  addUser = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.TBRI_USER.CREATE.ROUTE,
        {}
      ),
    });
  };

  clientUserBounceValidationConfirmationHandler = (data) => {
    ClientServices.getSingleUserBounceValidation(data.email, data.id)
      .then((res) => {
        if (res.data.status.toLowerCase().trim() === "invalid") {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">User Invalid</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: "de-activate_user", id: data.id },
            dailogModalStyleType: "danger-modal",
          });
        } else if (
          res.data.status.toLowerCase() === "catch_all" ||
          res.data.status.toLowerCase().trim() === "catchall"
        ) {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Verify Email</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="inactive-body">
                <h2 className="font-weight-semibold mb-0">
                  Please Verify User Email Manually
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "inactive-modal",
          });
        } else {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Success</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  User Email is Valid
                </h2>
              </div>
            </>
          );
          this.setState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "success-modal",
          });
        }
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  copyEmailVerificationLink = (user) => {
    const userId = user.id;
    MagicLinkUserServices.copyMagicLink(userId)
      .then((res) => {
        const link = res.data.magicLink;
        Copy(link);
        Toast("Copied!", "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  deleteMagicLinkClientUser = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          Remove Magic Link User
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                .DELETE_CONFIRATION_MAGIC_LINK_USER
            }
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_magic_link_user", id: data.id },
      dailogModalStyleType: "danger-modal",
      submitButtonLabel: "Confirm",
    });
  };

  resendMagicLink = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Resend Magic Link</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to Resend Magic Link?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "resend_magic_link", id: data.id },
      dailogModalStyleType: "success-modal",
      submitButtonLabel: "Confirm",
    });
  };

  deleteMagicLinkUser = (userId) => {
    MagicLinkUserServices.deleteMagicLinkUser(userId)
      .then((res) => {
        this.getTableData();
        Toast(
          MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.DELETE_SUCCESS_MAGIC_LINK_USER,
          "success"
        );
        this.setState({
          dailogModal: false,
          dailogModalConfig: { type: null },
          dailogModalStyleType: "",
        });
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  resendEmailVerificationLink = (clientUserId) => {
    MagicLinkUserServices.resendMagicLink(clientUserId)
      .then((res) => {
        this.setState({
          dailogModal: false,
          dailogModalHeader: null,
          dailogModalConfig: { type: null },
          dailogModalStyleType: "",
          dailogModalContent: <></>,
          submitButtonLabel: "Confirm",
        });
        Toast(res.data.result.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  resendEmailVerification = (data) => {
    let arr = [];
    arr.push({ id: data.id });
    UserServices.resendEmailVerification(arr)
      .then((res) => {
        Toast(constants.TBRI_USER.RESEND_EMAIL_VERIFICATION.SUCCESS, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE,
        {}
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.TBRI_USER.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  //
  handleUserTemplateDownload = () => {
    UserServices.getUserStandardTemplate()
      .then((response) => {
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            let fileName = "UsersStandardtemplate";
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleUserTemplateUpload = () => {
    this.setState({
      documentModal: true,
    });
  };

  closeDocumentModal = () => {
    this.setState({
      documentModal: false,
      doc: {},
      formErrors: {},
      isFormSubmitted: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
        }
      }
    );
  };

  onChangeHandlerFile = (name, content) => {
    this.setState(
      {
        doc: { name, content },
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  isFormValid = () => {
    let formError = false;
    let docError = false;
    if (!this.state.doc || (this.state.doc && !this.state.doc.content)) {
      docError = true;
      formError = true;
    }
    this.setState({
      formErrors: {
        docError: docError,
      },
      apiError: null,
    });
    return formError;
  };

  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
            <h1 className="text-ligntblue2 font-weight-bold mb-0">
              {constants.APPLICATION_ROUTE.TBRI_USER.LIST.HEADER_TITLE}
            </h1>
            <div className="ml-auto d-flex align-items-center justify-content-end title-right-block mb-0">
              <div className="min-width-266">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Filter Name / Email"}
                />
              </div>
              <div className="icon-group d-flex">
                <button
                  className="icon-btn-primary icon-btn d-flex align-items-center justify-content-center"
                  onClick={this.exportList}
                  data-for={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.COMPANY.LIST
                      .EXPORT_EXCEL_TOOLTIP
                  )}`}
                  data-tip={
                    constants.APPLICATION_ROUTE.COMPANY.LIST
                      .EXPORT_EXCEL_TOOLTIP
                  }
                  data-iscapture="true"
                >
                  <span className="material-icons-round icon">download</span>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.COMPANY.LIST
                        .EXPORT_EXCEL_TOOLTIP
                    )}`}
                    multiline={true}
                  />
                </button>
              </div>

              <AclAction
                type="permissions"
                entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
                action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.CREATE}
              >
                <div
                  tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                  className="ml-3"
                >
                  <button
                    className={
                      "btn btn-primary d-flex align-items-center icon-text-primary-btn title-btn"
                    }
                    type="button"
                    onClick={this.addUser}
                  >
                    <span className="material-icons-round icon-fs-14 mr-1">
                      add
                    </span>
                    <span className="mt-text">
                      {" " +
                        constants.APPLICATION_ROUTE.TBRI_USER.LIST
                          .ADD_BUTTON_TITLE}
                    </span>
                  </button>
                </div>
              </AclAction>
            </div>
          </div>
          <div className="custom-table-block custom-table-block-2 first-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
            <DynamicTableWithSelectAllComponentNew
              columnData={tableHeader}
              rowData={this.state.rowData}
              pagePrevious={this.pagePrevious}
              pageNext={this.pageNext}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
              handlePage={this.handlePage}
              handleSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              count={this.state.count}
              rowAction={this.rowAction}
              colSpan={tableHeader.filter((h) => h.visibility).length}
              showActions={showActions}
              isSelectAll={this.state.isSelectAll}
              selectedValues={this.state.selectedValues}
              handleSelectAll={this.handleSelectAll}
              handleCheckBoxButtonClick={this.onCheckboxBtnClick}
              showPagination={true}
              paginationClass={"center-pagination custom-pagination-new"}
              handlePageSize={this.handlePageSize}
              checkBoxDisable={this.state.disableStatus}
              statusId={this.state.statusId}
            />
          </div>
          {/* Tooltips for icons in table, don't delete */}
          {this.state.rowData.map((data, index) => {
            return (
              <div key={index}>
                <CustomReactTooltip
                  id={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.TBRI_USER.LIST
                      .RESEND_EMAIL_VERIFICATION_TOOLTIP
                  )}-${data.id}`}
                  multiline={true}
                />
                <CustomReactTooltip
                  id={`tooltip-${Slugify(
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.COPY_MAGIC_LINK_TOOLTIP
                  )}-${data.id}`}
                  multiline={true}
                />
                <CustomReactTooltip
                  id={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.TBRI_USER.LIST
                      .REMOVE_USER_TOOLTIP
                  )}-${data.id}`}
                  multiline={true}
                />
                <CustomReactTooltip
                  id={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                      .VALIDATE_USER_TOOL_TIP
                  )}-${data.id}`}
                  multiline={true}
                />
                <CustomReactTooltip
                  id={"tool-tip-resetpassword"}
                  multiline={true}
                />
                <CustomReactTooltip
                  id={`tooltip-${Slugify(
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                      .RESEND_MAGIC_LINK_TOOLTIP
                  )}-${data.id}`}
                  multiline={true}
                />
                <CustomReactTooltip
                  id={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                      .DELETE_CLIENT_USER
                  )}-${data.id}`}
                  multiline={true}
                />
              </div>
            );
          })}

          {/* <Export */}
          {/*   fileName={"users"} */}
          {/*   fileTitle={"USERS"} */}
          {/*   source={this.state.totalData} */}
          {/*   columns={tableExportHeader} */}
          {/* /> */}
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
        </div>
      </aside>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListAdminUsers);
