const home = {
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  SEGMENTS: "SEGMENTS",
  ACTIVE_MENU_TAB: "ACTIVE_MENU_TAB",
  TBRI_USERS: "TBRI_USERS",
  OPEN_SEARCHBAR: "OPEN_SEARCHBAR",
  CURRENCIES: "CURRENCIES",
  USER_ROLES: "USER_ROLES",
  USER_DESIGNATION: "USER_DESIGNATION",
  OPEN_PREFERENCE: "OPEN_PREFERENCE",
};

const company_details = {
  COMPANY_CATEGORIES: "COMPANY_CATEGORIES",
  COMPANY_SEGMENTS: "COMPANY_SEGMENTS",
  COMPANY_DETAILS: "COMPANY_DETAILS",
  CATEGORIES: "CATEGORIES",
  COMPANY_CONTENT: "COMPANY_CONTENT",
  COMPANIES: "COMPANIES",
  TEMPLATES: "TEMPLATES",
  TRANSACTION_DETAILS: "TRANSACTION_DETAILS",
};

const clients = {
  CLIENTS: "CLIENTS",
  CLIENT_ROLES: "CLIENT_ROLES",
  USERS: "USERS",
};

const project_details = {
  PROJECT_CATEGORIES: "PROJECT_CATEGORIES",
  PROJECT_SEGMENTS: "PROJECT_SEGMENTS",
  PROJECT_DETAILS: "PROJECT_DETAILS",
  CATEGORIES: "CATEGORIES",
  PROJECT_CONTENT: "PROJECT_CONTENT",
  PROJECT_TEMPLATES: "PROJECT_TEMPLATES",
  PROJECT_TRANSACTION_DETAILS: "PROJECT_TRANSACTION_DETAILS",
};

const benchmark_details = {
  BENCHMARK_CATEGORIES: "BENCHMARK_CATEGORIES",
  BENCHMARK_SEGMENTS: "BENCHMARK_SEGMENTS",
  BENCHMARK_DETAILS: "BENCHMARK_DETAILS",
  CATEGORIES: "CATEGORIES",
  BENCHMARK_CONTENT: "BENCHMARK_CONTENT",
  BENCHMARK_TEMPLATES: "BENCHMARK_TEMPLATES",
  BENCHMARK_TRANSACTION_DETAILS: "BENCHMARK_TRANSACTION_DETAILS",
};
const ecosystem_details = {
  ECOSYSTEM_CATEGORIES: "ECOSYSTEM_CATEGORIES",
  ECOSYSTEM_SEGMENTS: "ECOSYSTEM_SEGMENTS",
  ECOSYSTEM_DETAILS: "ECOSYSTEM_DETAILS",
  CATEGORIES: "CATEGORIES",
  ECOSYSTEM_CONTENT: "ECOSYSTEM_CONTENT",
  ECOSYSTEM_TEMPLATES: "ECOSYSTEM_TEMPLATES",
  ECOSYSTEM_TRANSACTION_DETAILS: "ECOSYSTEM_TRANSACTION_DETAILS",
};
const customer_study_details = {
  CUSTOMER_STUDY_DETAILS: "CUSTOMER_STUDY_DETAILS",
  CUSTOMER_STUDY_CATEGORIES: "CUSTOMER_STUDY_CATEGORIES",
  CUSTOMER_STUDY_SEGMENTS: "CUSTOMER_STUDY_SEGMENTS",
  CATEGORIES: "CATEGORIES",
  CUSTOMER_STUDY_CONTENT: "CUSTOMER_STUDY_CONTENT",
  CUSTOMER_STUDY_TEMPLATES: "CUSTOMER_STUDY_TEMPLATES",
  CUSTOMER_STUDY_TRANSACTION_DETAILS: "CUSTOMER_STUDY_TRANSACTION_DETAILS",
};
const special_report_details = {
  SPECIAL_REPORT_CATEGORIES: "SPECIAL_REPORT_CATEGORIES",
  SPECIAL_REPORT_SEGMENTS: "SPECIAL_REPORT_SEGMENTS",
  SPECIAL_REPORT_DETAILS: "SPECIAL_REPORT_DETAILS",
  CATEGORIES: "CATEGORIES",
  SPECIAL_REPORT_CONTENT: "SPECIAL_REPORT_CONTENT",
  SPECIAL_REPORT_TEMPLATES: "SPECIAL_REPORT_TEMPLATES",
  SPECIAL_REPORT_TRANSACTION_DETAILS: "SPECIAL_REPORT_TRANSACTION_DETAILS",
};

const market_forecast_details = {
  MARKET_FORECAST_CATEGORIES: "MARKET_FORECAST_CATEGORIES",
  MARKET_FORECAST_SEGMENTS: "MARKET_FORECAST_SEGMENTS",
  MARKET_FORECAST_DETAILS: "MARKET_FORECAST_DETAILS",
  CATEGORIES: "CATEGORIES",
  MARKET_FORECAST_CONTENT: "MARKET_FORECAST_CONTENT",
  MARKET_FORECAST_TEMPLATES: "MARKET_FORECAST_TEMPLATES",
  MARKET_FORECAST_TRANSACTION_DETAILS: "MARKET_FORECAST_TRANSACTION_DETAILS",
};
const market_landscape_details = {
  MARKET_LANDSCAPE_CATEGORIES: "MARKET_LANDSCAPE_CATEGORIES",
  MARKET_LANDSCAPE_SEGMENTS: "MARKET_LANDSCAPE_SEGMENTS",
  MARKET_LANDSCAPE_DETAILS: "MARKET_LANDSCAPE_DETAILS",
  CATEGORIES: "CATEGORIES",
  MARKET_LANDSCAPE_CONTENT: "MARKET_LANDSCAPE_CONTENT",
  MARKET_LANDSCAPE_TEMPLATES: "MARKET_LANDSCAPE_TEMPLATES",
  MARKET_LANDSCAPE_TRANSACTION_DETAILS: "MARKET_LANDSCAPE_TRANSACTION_DETAILS",
};

export {
  home,
  company_details,
  clients,
  project_details,
  benchmark_details,
  ecosystem_details,
  special_report_details,
  market_forecast_details,
  market_landscape_details,
  customer_study_details,
};
