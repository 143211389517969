import React, { useEffect, useState } from "react";
import CopyInput from "./CopyInput";
import { Button, ModalFooter } from "reactstrap";
import { isEmpty } from "lodash";
import constants from "../Utils/constants";
import Dailog from "./DailogNew";
import { SamlServices } from "../Services/SamlServices";
import Toast from "../Utils/Toast";

const SSOSamlModal = ({ handleSAMLData, closeModal, samlData = null }) => {
  const [formErrors, setFormErrors] = useState({});
  const [ssosamlConfigValues, setssosamlConfigValues] = useState({
    entityId: "",
    replayUrl: "",
    metadataURL: "",
    email: "",
    givenName: "",
    nameSSOSAML: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dailogModalContent, setdailogModalContent] = useState(null);
  const [dailogModalHeader, setdailogModalHeader] = useState(null);
  const [dailogModalConfig, setdailogModalConfig] = useState({});
  const [dailogModalStyleType, setdailogModalStyleType] = useState(null);
  const [samlVerifiedModalLabel, setsamlVerifiedModalLabels] =
    useState("Continue");
  const handleOnChangeSamlConfiguration = (e) => {
    const { id, value } = e.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id + "Error"]: false,
    }));
    setssosamlConfigValues((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const isSSOSAMLConfigValidated = () => {
    let formError = false;
    const errors = {
      metadataURLError: false,
      emailError: false,
      givenNameError: false,
      nameSSOSAMLError: false,
    };

    const regex = constants.REG_EX.WEBINAR_REG_EX;

    if (
      isEmpty(ssosamlConfigValues.metadataURL.trim()) ||
      !regex.test(ssosamlConfigValues.metadataURL)
    ) {
      errors.metadataURLError = true;
      formError = true;
    }

    if (isEmpty(ssosamlConfigValues.email.trim())) {
      errors.emailError = true;
      formError = true;
    }

    if (isEmpty(ssosamlConfigValues.givenName.trim())) {
      errors.givenNameError = true;
      formError = true;
    }

    if (isEmpty(ssosamlConfigValues.nameSSOSAML.trim())) {
      errors.nameSSOSAMLError = true;
      formError = true;
    }

    setFormErrors(errors); // Update state after collecting errors

    return formError;
  };

  const verifyModal = () => {
    if (!isSSOSAMLConfigValidated()) {
      verifyMetaDataURL();
    }
  };

  useEffect(() => {
    if (samlData) {
      let mappingAttributes =
        samlData && samlData?.mapping_attributes
          ? JSON.parse(samlData?.mapping_attributes)
          : null;

      setssosamlConfigValues((prev) => ({
        ...prev,
        metadataURL: samlData?.metadata_url,
        email: mappingAttributes?.email,
        givenName: mappingAttributes?.given_name,
        nameSSOSAML: mappingAttributes?.name,
      }));
    }
  }, [samlData]);
  useEffect(() => {
    SamlServices.getSAMLUrls()
      .then((res) => {
        setssosamlConfigValues((prev) => ({
          ...prev,
          entityId: res?.data?.entity_id,
          replayUrl: res?.data?.replay_url,
        }));
      })
      .catch((error) => {
        Toast(
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }, []);

  const dailogModalAccept = (config) => {
    setIsModalOpen(false);
    setdailogModalHeader("");
    setdailogModalContent("");
    setdailogModalConfig({});

    switch (config.type) {
      case "samlModalVerified":
        addSSOSAMLConfiguration(config);
        break;
      default:
        break;
    }
  };

  const dailogModalDecline = () => {
    setIsModalOpen(false);
    setdailogModalHeader("");
    setdailogModalContent("");
    setdailogModalConfig({});
  };

  const addSSOSAMLConfiguration = (config) => {
    let body = {
      metadataUrl: ssosamlConfigValues.metadataURL,
      mappingAttributes: JSON.stringify({
        email: ssosamlConfigValues.email,
        given_name: ssosamlConfigValues.givenName,
        name: ssosamlConfigValues.nameSSOSAML,
      }),
      isSamlConfigurationAdded: true,
    };
    handleSAMLData(body);
    closeModal();
    setssosamlConfigValues({
      metadataURL: "",
      email: "",
      givenName: "",
      nameSSOSAML: "",
    });
  };

  // verify metadata url
  const verifyMetaDataURL = () => {
    const metadata_url = ssosamlConfigValues.metadataURL;
    let body = {
      metadata_url: metadata_url,
    };
    SamlServices.verifyMetaDataURL(body)
      .then((res) => {
        if (res.status === 200) {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold align-text-center d-flex justify-content-center">
                Verify!
              </h3>
            </>
          );
          let dailogModalContent = (
            <>
              <h3
                className="text-darkblue2 font-weight-bold"
                style={{ fontSize: "14px" }}
              >
                Your metadata has been successfully verified, Would you like to
                proceed ahead{" "}
              </h3>
            </>
          );
          setIsModalOpen(true);
          setdailogModalHeader(dailogModalHeader);
          setdailogModalContent(dailogModalContent);
          setdailogModalConfig({ type: "samlModalVerified" });
          setdailogModalStyleType("success-modal");
        } else {
          Toast("your metadata url is wront not verified", "error");
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  return (
    <>
      <div className={"row first-row-identifier-reply"}>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label">
          <label for="entityId">Identifier (Entity ID)</label>
          <CopyInput
            value={ssosamlConfigValues.entityId}
            fontSize="12px"
            inputClass={`form-control`}
            inputId="entityId"
            readonly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label">
          <label for="replayUrl">
            Reply URL (Assertion Consumer Service URL)
          </label>
          <CopyInput
            value={ssosamlConfigValues.replayUrl}
            fontSize="12px"
            inputClass={`form-control`}
            inputId="replayUrl"
            readonly={true}
          />
        </div>
      </div>
      <div className={"row second-metadata"}>
        <div className="col-sm-12 form-group position-relative ssosaml-modal-label">
          <label for="endpointurl">
            Enter metadata document endpoint URL
            <span className={"mandatory ml-1"}>*</span>
          </label>
          <input
            type="text"
            placeholder="https://"
            id={"metadataURL"}
            value={ssosamlConfigValues.metadataURL}
            className={`form-control fs-12 ${
              formErrors.metadataURLError === true ? "is-invalid" : ""
            }
            ${formErrors.metadataURLError === false ? "" : ""}`}
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.metadataURLError && (
            <div className="saml-config-error">
              {constants.FORM.MANDATORY_FIELD}
            </div>
          )}
        </div>
      </div>
      <div className="row third-info-attribute">
        <div className="col-sm-12 form-group position-relative ssosaml-modal-label">
          <span style={{ fontWeight: "bold" }}>
            Map attributes between your SAML provider and your user pool
          </span>
          <span className="fs-12">
            Your required attribute are mapped to the equivalent SAML attribute.
            Each attribute you add must be mapped to a SAML attribute.
          </span>
        </div>
      </div>
      <div className="row fourth-pool-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <span className="mb-2">User pool attribute</span>
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <span className="mb-2">
            SAML Attribute <span className={"mandatory"}>*</span>
          </span>
        </div>
      </div>
      <div className="row fifth-email-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={"form-control mb-2"}
            aria-describedby="button-addon2"
            id="email_attribute"
            placeholder="email"
            readOnly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={`form-control ${
              formErrors.emailError === true ? "is-invalid" : ""
            }
            ${formErrors.emailError === false ? "" : ""}`}
            id={"email"}
            value={ssosamlConfigValues.email}
            placeholder="Enter SAML attribute name"
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.emailError && (
            <div className="saml-config-error mb-2">
              {constants.FORM.MANDATORY_FIELD}
            </div>
          )}
        </div>
      </div>
      <div className="row six-givenname-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={"form-control mb-2"}
            aria-describedby="button-addon2"
            id="givenName_attribute"
            placeholder="first_name"
            readOnly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={`form-control ${
              formErrors.givenNameError === true ? "is-invalid" : ""
            }
            ${formErrors.givenNameError === false ? "" : ""}`}
            id={"givenName"}
            value={ssosamlConfigValues.givenName}
            placeholder="Enter SAML attribute name"
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.givenNameError && (
            <span className="saml-config-error mb-2">
              {constants.FORM.MANDATORY_FIELD}
            </span>
          )}
        </div>
      </div>
      <div className="row seven-name-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={"form-control "}
            aria-describedby="button-addon2"
            id="name_attribute"
            placeholder="last_name"
            readOnly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={`form-control ${
              formErrors.nameSSOSAMLError === true ? "is-invalid" : ""
            }
            ${formErrors.nameSSOSAMLError === false ? "" : ""}`}
            id={"nameSSOSAML"}
            value={ssosamlConfigValues.nameSSOSAML}
            placeholder="Enter SAML attribute name"
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.nameSSOSAMLError && (
            <span className="saml-config-error">
              {constants.FORM.MANDATORY_FIELD}
            </span>
          )}
        </div>
      </div>

      <div className="modal-footer">
        <ModalFooter>
          <Button
            className={"modal-right-btn modal-btn mt-3"}
            onClick={verifyModal}
          >
            Verify
          </Button>
        </ModalFooter>
      </div>

      <Dailog
        isOpen={isModalOpen}
        accept={dailogModalAccept}
        decline={dailogModalDecline}
        header={dailogModalHeader}
        content={dailogModalContent}
        config={dailogModalConfig}
        modalStyleType={dailogModalStyleType}
        submitButtonLabel={samlVerifiedModalLabel}
      />
    </>
  );
};
export default SSOSamlModal;
