import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
} from "reactstrap";
import ReactTooltip from "react-tooltip";
import AclAction from "../../Components/ACL/AclAction";
import CustomDropdownList from "../../Components/CustomDropdownList";
import CustomPPTUploader from "../../Components/CustomPPTUploader";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import DailogNew from "../../Components/DailogNew";
import DynamicTableComponentNew from "../../Components/DynamicTableComponentsNew";
import Notification from "../../Components/Notification";
import Search from "../../Components/Search";
import permissionCheck from "../../lib/permissionCheck";
import { ClientServices } from "../../Services/Client";
import { CompanyServices } from "../../Services/Company";
import { HistoricalFileServices } from "../../Services/HistoricalFileServices";
import { clients } from "../../Store/Actions/Client";
import { activeMenuTab, segments } from "../../Store/Actions/User";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../../Utils/constants";
import CustomCloneDeep from "../../Utils/CustomCloneDeep";
import Slugify from "../../Utils/Slugify";
import Toast from "../../Utils/Toast";
import isRole_Acc_mgr_or_salesAdmin from "../../Utils/isRole_Acc_mgr_or_salesAdmin";
import { BenchmarkServices } from "../../Services/Bechmark";
import { SpecialReportServices } from "../../Services/SpecialReport";
import { MarketForecastServices } from "../../Services/MarketForecast";
import { MarketLandscapeServices } from "../../Services/MarketLandscape";
import { CustomerStudyServices } from "../../Services/CustomerStudy";
import { EcosystemServices } from "../../Services/Ecosystem";
import { ProjectServices } from "../../Services/Project";
import { delay } from "lodash";
import DownloadIcon from "./download.svg";
import EditIcon from "./edit.svg";
import DeleteIcon from "./delete.svg";
import { cleanUrl } from "../../Utils/helper";
import MultipleFileUpload from "../../Components/MultipleFileUpload";

const tableHeader = [
  {
    id: "file_name",
    title: "File Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "report_type",
    title: "Report Type",
    sortable: true,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "100px",
    },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "100px",
    },
    visibility: true,
  },
  {
    id: "report_name",
    title: "Report Name",
    sortable: true,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "100px",
    },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "100px",
    },
    visibility: true,
  },
  {
    id: "corelation_name",
    title: "Segment",
    sortable: true,
    headerStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      minWidth: "100px",
    },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },

  {
    id: "created_at",
    title: "Created Date",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "status",
    title: "Status",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "center" },
    visibility: true,
  },
  {
    id: "method",
    title: "Uploading Method",
    sortable: false,
    headerStyle: { textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "center" },
    visibility: true,
  },

  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
];
const showActions = false;
class HistoricalFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.COMPANIES_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      companyId: "",
      segmentId: "",
      segment_name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: { type: "remove" },
      dailogModalStyleType: "",
      documentModal: false,
      apiError: null,
      backendError: false,
      backendErrorContent: "",
      uploadSegments: [],
      availableUploaders: [
        {
          id: "groupdocs",
          name: "Groupdocs",
          is_selected: false,
        },
        {
          id: "buildvu",
          name: "BuildVu",
          is_selected: false,
        },
      ],
      selectedUploaderType: null,
      allSegments: [],
      uploadCompanies: [],
      reportFileNameList: [],
      allCompanies: [],
      conversions: [
        {
          id: "GroupDocs",
          name: "GroupDocs",
          value: "groupdocs",
          is_selected: false,
        },
      ],
      formErrors: {},
      mappingType: null,
      isFormSubmitted: false,
      disableStatus: false,
      skipValidation: false,
      editId: null,
      docList: [],
      tempDeleteData: {},
      reportType: constants.HISTORICAL_FILE.MAPPING_TYPES,
      filerReportType: [
        { id: "all", name: "All Report Type", is_selected: true },
        ...constants.HISTORICAL_FILE.MAPPING_TYPES,
      ],
      submitting: false,
    };
  }
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort
          ? params.sort
          : constants.PAGINATION.COMPANIES_DEFAULT_SORT_COLUMN,
        sortOrder: params.order
          ? params.order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
        companyId: params.companyId ? params.companyId : "all",
        segmentId: params.segmentId ? params.segmentId : "all",
        segment_name: params.segment_name ? params.segment_name : "all",
      },
      () => {
        this.getSegments();
        this.getCompanies();
        this.getTableData();
      }
    );
    this.props.activeMenuTab(constants.HISTORICAL_FILE.DOWNLOAD.LIST.ROUTE);
    let index = tableHeader.findIndex((element) => element.id === "actions");
    if (
      isRole_Acc_mgr_or_salesAdmin(this.props.home.loginUserSuccess.role_id)
    ) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  getSegments = () => {
    ClientServices.getSegmentDetails(1)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let segments = [];
        segments.push({ id: "all", name: "All Segments" });
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        let totalSegments = segments.concat(response);
        totalSegments.map((r) => {
          r.is_selected =
            r.id ===
            (this.state.segmentId === "all"
              ? "all"
              : parseInt(this.state.segmentId));
        });

        this.props.setSegments(totalSegments);
        this.setState({
          uploadSegments: CustomCloneDeep(response),
          allSegments: CustomCloneDeep(response),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getCompanies = () => {
    CompanyServices.getTotalCompanies("companies")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let companies = [];
        companies.push({ id: "all", name: "All Companies" });
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        let totalCompanies = companies.concat(response);
        totalCompanies.map((r) => {
          r.is_selected =
            r.id ===
            (this.state.companyId === "all"
              ? "all"
              : parseInt(this.state.companyId));
        });

        this.setState({
          companies: totalCompanies,
          uploadCompanies: CustomCloneDeep(response),
          allCompanies: CustomCloneDeep(response),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  getBenchmarksList = (reportId) => {
    BenchmarkServices.getBenchmarks("/benchmarks")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let data = CustomCloneDeep(response);
        this.setState({
          reportFileNameList: data.map((i) => ({
            ...i,
            is_selected: i.id === reportId,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  getSpecialReportsList = (reportId) => {
    SpecialReportServices.getSpecialReports("/specialreports")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let data = CustomCloneDeep(response);
        this.setState({
          reportFileNameList: data.map((i) => ({
            ...i,
            is_selected: i.id === reportId,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  getProjectList = (reportId) => {
    ProjectServices.getTotalProjects("/projects")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let data = CustomCloneDeep(response);
        this.setState({
          reportFileNameList: data.map((i) => ({
            ...i,
            is_selected: i.id === reportId,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  getMarketForecastsList = (reportId) => {
    MarketForecastServices.getMarketForecasts("/marketforecasts")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let data = CustomCloneDeep(response);
        this.setState({
          reportFileNameList: data.map((i) => ({
            ...i,
            is_selected: i.id === reportId,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  getMarketLandscapesList = (reportId) => {
    MarketLandscapeServices.getMarketLandscapes("/marketlandscapes")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let data = CustomCloneDeep(response);
        this.setState({
          reportFileNameList: data.map((i) => ({
            ...i,
            is_selected: i.id === reportId,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  getCustomerStudiesList = (reportId) => {
    CustomerStudyServices.getCustomerStudies("/customer-study/list")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let data = CustomCloneDeep(response);
        this.setState({
          reportFileNameList: data.map((i) => ({
            ...i,
            is_selected: i.id === reportId,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  getEcoSystemList = (reportId) => {
    EcosystemServices.getEcosystem("/ecosystem/list")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        let data = CustomCloneDeep(response);
        this.setState({
          reportFileNameList: data.map((i) => ({
            ...i,
            is_selected: i.id === reportId,
          })),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  dailogModalAccept = () => {
    this.removeFile(this.state.tempDeleteData.id);
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };
  editUpload = (data) => {
    this.setState({
      documentModal: true,
      editId: data.id,
      doc: { name: data.file_name },
    });

    const mappingType = constants.HISTORICAL_FILE.HF_MAPPING_TYPES.find(
      (f) => f.id === data.report_type
    );

    this.handleReportData(mappingType.value, data.report_id);

    const newReportType = this.state.reportType.map((c) => ({
      ...c,
      is_selected: c.id === mappingType.value,
    }));

    const selectedReportName = this.state.reportFileNameList.map((c) => {
      c.is_selected = c.id === data.report_id;
      return c;
    });

    this.setState(
      {
        reportType: newReportType,
        mappingType: newReportType.find((type) => type.is_selected)?.id,
        // Reset all companies' selection
        uploadCompanies: this.state.uploadCompanies.map((company) => ({
          ...company,
          is_selected: company.id === data.report_id ? true : false,
        })),
        // Reset all segment' selection
        uploadSegments: this.state.uploadSegments.map((segment) => ({
          ...segment,
          is_selected: segment.id === data.corelation_id ? true : false,
        })),
        // reportFileNameList: selectedReportName,

        backendError: false,
      },
      () => {
        this.setState({
          formErrors: {
            reportTypeError: false,
          },
        });
      }
    );
  };

  removeFile = (id) => {
    HistoricalFileServices.removeFile(id).then(() => {
      Toast(constants.HISTORICAL_FILE.REMOVE, "success");
      this.setState({
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
      });
      this.getTableData();
    });
  };

  getActions = (data) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <i
          id="edit"
          className="material-icons-outlined text-blue-clr icon-fs-20"
          data-for={`tooltip-${Slugify(
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.EDIT_DOWNLOAD
          )}-${data.id}`}
          style={{ cursor: "pointer" }}
          onClick={() => this.editUpload(data)}
          data-tip={
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.EDIT_DOWNLOAD_TOOLTIP
          }
        >
          edit
        </i>

        <CustomReactTooltip
          id={`tooltip-${Slugify(
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.EDIT_DOWNLOAD
          )}-${data.id}`}
        />
        <img
          src={DownloadIcon}
          alt="download-icon"
          onClick={(e) => {
            this.rowAction(
              constants.HISTORICAL_FILE.DOWNLOAD.LIST.DOWNLOAD,
              data,
              e
            );
          }}
          style={{ cursor: "pointer" }}
          data-tip={constants.HISTORICAL_FILE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP}
          data-for={`tooltip-${Slugify(
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.DOWNLOAD
          )}-${data.id}`}
        />
        <CustomReactTooltip
          id={`tooltip-${Slugify(
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.DOWNLOAD
          )}-${data.id}`}
        />
        <img
          src={DeleteIcon}
          alt="delete-icon"
          onClick={() => {
            let dailogModalHeader = (
              <>
                <h3 className="text-darkblue2 font-weight-bold">
                  Delete historical file
                </h3>
              </>
            );
            let dailogModalContent = (
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  Are you sure want to delete historical file?
                </h2>
              </div>
            );

            this.setState({
              dailogModal: true,
              tempDeleteData: data,
              dailogModalContent: dailogModalContent,
              dailogModalHeader: dailogModalHeader,
              dailogModalConfig: { type: "remove" },
              dailogModalStyleType: "danger-modal",
            });
          }}
          style={{ cursor: "pointer" }}
          data-tip={
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.REMOVE_DOWNLOAD_TOOLTIP
          }
          data-for={`tooltip-${Slugify(
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.REMOVE_DOWNLOAD
          )}-${data.id}`}
        />
        <CustomReactTooltip
          id={`tooltip-${Slugify(
            constants.HISTORICAL_FILE.DOWNLOAD.LIST.REMOVE_DOWNLOAD
          )}-${data.id}`}
        />
      </div>
    );
  };

  baseName = (str) => {
    let base = String(str).substring(str.lastIndexOf("\\") + 1);
    base = String(base).substring(base.lastIndexOf("/") + 1);
    return base;
  };

  getReportContent = (id, type) => {
    switch (type) {
      case "companies":
        return formatRoute(
          constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRANSACTION_DETAILS.ROUTE,
          {
            id,
            type,
          }
        );
      case "benchmark":
        return formatRoute(
          constants.APPLICATION_ROUTE
            .MANAGE_CONTENT_BENCHMARK_TRANSACTION_DETAILS.ROUTE,
          {
            id,
            type,
          }
        );

      case "market_forecast":
        return formatRoute(
          constants.APPLICATION_ROUTE
            .MANAGE_CONTENT_MARKET_FORECAST_TRANSACTION_DETAILS.ROUTE,
          {
            id,
            type,
          }
        );
      case "market_landscape":
        return formatRoute(
          constants.APPLICATION_ROUTE
            .MANAGE_CONTENT_MARKET_LANDSCAPE_TRANSACTION_DETAILS.ROUTE,
          {
            id,
            type,
          }
        );
      case "customer_study":
        return formatRoute(
          constants.APPLICATION_ROUTE
            .MANAGE_CONTENT_CUSTOMER_STUDY_TRANSACTION_DETAILS.ROUTE,
          {
            id,
            type,
          }
        );
      case "ecosystem":
        return formatRoute(
          constants.APPLICATION_ROUTE
            .MANAGE_CONTENT_ECOSYSTEM_TRANSACTION_DETAILS.ROUTE,
          {
            id,
            type,
          }
        );
      case "special_report":
        return formatRoute(
          constants.APPLICATION_ROUTE
            .MANAGE_CONTENT_SPECIAL_REPORT_TRANSACTION_DETAILS.ROUTE,
          {
            id,
            type,
          }
        );
      case "project":
        return formatRoute(
          constants.APPLICATION_ROUTE.MANAGE_CONTENT_PROJECT_TRANSACTION_DETAILS
            .ROUTE,
          {
            id,
            type,
          }
        );
    }
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&order=" + this.state.sortOrder + "&sort=" + this.state.sortColumn;
    }
    const mappingTypeIds = this.state.filerReportType.find(
      (c) => c.is_selected
    );
    if (this.state.name !== "") {
      queryParams += "&file_name=" + this.state.name;
    }
    if (
      this.state.companyId &&
      this.state.companyId !== "" &&
      this.state.companyId !== "all"
    ) {
      queryParams += "&report_id=" + this.state.companyId;
    }
    if (
      this.state.segmentId &&
      this.state.segmentId !== "" &&
      this.state.segmentId !== "all"
    ) {
      queryParams += "&corelation_id=" + this.state.segmentId;
    }
    if (mappingTypeIds && mappingTypeIds.id !== "all") {
      const type = constants.HISTORICAL_FILE.HF_MAPPING_TYPES.find(
        (f) => f.value === mappingTypeIds.id
      );
      queryParams += "&report_type=" + type.id;
    }
    this.changeRoute();
    let url = `/historical-files/list?page=${this.state.pageNo}&limit=${this.state.pageSize}${queryParams}`;
    url = cleanUrl(url);
    HistoricalFileServices.getFiles(url)
      .then((res) => {
        let clientData = res.data.data;
        let count = (res.data && res.data.meta && res.data.meta.total) || 0;
        clientData = clientData.map((data) => {
          const mappingTypeFiler =
            constants.HISTORICAL_FILE.HF_MAPPING_TYPES.find(
              (c) => c.id === data.report_type
            );

          return {
            id: data.id,
            report_type: mappingTypeFiler ? mappingTypeFiler?.name : "",
            file_name: data.file_name ? (
              <div className="nav-text">{this.baseName(data.file_name)}</div>
            ) : (
              "-"
            ),
            status: (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    background: data.status ? "#5CB85C" : "#BD2130",
                  }}
                ></div>
              </div>
            ),
            method: data.method ?? "-",

            // Added a temp mapping type
            corelation_name: data.corelation_name ? (
              data.corelation_name.length > 25 ? (
                <>
                  <span
                    data-for={`tooltip-${data.id + data.corelation_name}`}
                    data-tip={data.corelation_name}
                  >
                    {data.corelation_name.slice(0, 25)}...
                  </span>

                  <CustomReactTooltip
                    id={`tooltip-${data.id + data.corelation_name}`}
                  />
                </>
              ) : (
                data.corelation_name
              )
            ) : (
              "-"
            ),
            report_name: data.report_name ? (
              data.report_name.length > 25 ? (
                <>
                  <span
                    data-for={`tooltip-${data.id + data.report_name}`}
                    data-tip={data.report_name}
                  >
                    {data.report_name.slice(0, 25)}...
                  </span>

                  <CustomReactTooltip
                    id={`tooltip-${data.id + data.report_name}`}
                  />
                </>
              ) : (
                data.report_name
              )
            ) : (
              "-"
            ),
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
            actions: this.getActions(data),
            // };
          };
        });
        this.setState({ rowData: clientData, count: count, editId: null });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.HISTORICAL_FILE.DOWNLOAD.LIST.DOWNLOAD:
        this.downloadFile(data.uuid);
        break;
      default:
        break;
    }
  };

  upload = () => {
    this.setState({
      documentModal: true,
    });
  };

  downloadFile = (id) => {
    HistoricalFileServices.getFileById(id)
      .then((response) => {
        // Create a blob from the response
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
        delay(() => {
          // Create a link element
          const link = document.createElement("a");
          link.href = url;

          // Set the file name (if provided in response headers)
          const filename = response.headers["content-disposition"]
            ? response.headers["content-disposition"]
                .split("filename=")[1]
                .trim()
                .replace(/['"]/g, "")
            : "downloaded_file";

          link.setAttribute("download", filename);

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up by removing the link and revoking the object URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 500);
      })
      .catch((error) => {
        Toast(
          error?.data?.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&order=" + this.state.sortOrder + "&sort=" + this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    if (this.state.companyId !== "") {
      queryParams += "&companyId=" + this.state.companyId;
    }
    if (this.state.segmentId !== "") {
      queryParams += "&segmentId=" + this.state.segmentId;
    }
    if (this.props.selectedTab) {
      queryParams += "&selected_tab=" + this.props.selectedTab;
    }
    this.props.history.push({
      pathname: formatRoute(constants.HISTORICAL_FILE.DOWNLOAD.LIST.ROUTE, {}),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  breadcrumbs = () => {
    return [
      {
        title: constants.HISTORICAL_FILE.DOWNLOAD.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
  };

  handleSegmentChange = (e) => {
    const segments = this.props.home.segments.map((c) => {
      c.is_selected = c.id === e.id;
      return c;
    });

    this.props.setSegments(segments);

    this.setState(
      {
        segmentId: e.id ?? "All",
        pageNo: 1,
        segment_name: e.id !== "all" ? e.name : "",
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleCompanyChange = (e) => {
    let companies = this.state.companies.map((c) => {
      c.is_selected = c.id === e.id;
      return c;
    });

    this.setState(
      {
        companies: companies,
        companyId: e.id,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleUploadSegmentChange = (e) => {
    const segments = this.state.uploadSegments.map((c) => {
      c.is_selected = c.id === e.id;
      return c;
    });

    this.setState(
      {
        uploadSegments: segments,
        backendError: false,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  // Handler function for uploader change
  handleUploaderChange = (selectedSegment) => {
    const updatedSegments = this.state.availableUploaders.map((segment) => {
      if (segment.id === selectedSegment.id) {
        return { ...segment, is_selected: true };
      } else {
        return { ...segment, is_selected: false };
      }
    });

    const selectedUploader =
      updatedSegments.find((segment) => segment.is_selected)?.id || null;

    this.setState({
      availableUploaders: updatedSegments,
      selectedUploaderType: selectedUploader,
      formErrors: {
        ...this.state.formErrors,
        uploaderServiceError: false,
      },
    });
  };

  handleUploadCompanyChange = (e) => {
    const companies = this.state.uploadCompanies.map((c) => {
      c.is_selected = c.id === e.id;
      return c;
    });

    this.setState(
      {
        uploadCompanies: companies,
        uploadSegments: e?.segments ?? [],
        backendError: false,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleFilterReportTypeChange = (e) => {
    const { filerReportType, companies: companiesArray } = this.state;
    const newReportType = filerReportType.map((c) => ({
      ...c,
      is_selected: c.id === e.id,
    }));
    let companies = companiesArray.map((c) => {
      c.is_selected = false;
      return c;
    });
    const segments = this.props.home.segments.map((c) => {
      c.is_selected = false;
      return c;
    });

    this.props.setSegments(segments);
    this.setState(
      {
        filerReportType: newReportType,
        backendError: false,
        companies: companies,
        companyId: null,
        segmentId: null,
        segment_name: "",
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleReportTypeChange = (e) => {
    const newReportType = this.state.reportType.map((c) => ({
      ...c,
      is_selected: c.id === e.id,
    }));
    this.setState(
      {
        reportType: newReportType,
        mappingType: newReportType.find((type) => type.is_selected)?.id,
        // Reset all companies' selection
        uploadCompanies: this.state.uploadCompanies.map((company) => ({
          ...company,
          is_selected: false,
        })),
        // Reset all segment' selection
        uploadSegments: this.state.uploadSegments.map((segment) => ({
          ...segment,
          is_selected: false,
        })),
        // Reset all reportFileNameList' selection
        reportFileNameList: this.state.reportFileNameList.map((file) => ({
          ...file,
          is_selected: false,
        })),
        backendError: false,
      },
      () => {
        this.setState({
          formErrors: {
            reportTypeError: false,
          },
        });
        this.handleReportData();
      }
    );
  };

  handleReportData = (type = this.state.mappingType, reportId) => {
    switch (type) {
      case "benchmark":
        this.getBenchmarksList(reportId);
        break;
      case "market_forecast":
        this.getMarketForecastsList(reportId);
        break;
      case "market_landscape":
        this.getMarketLandscapesList(reportId);
        break;
      case "customer_study":
        this.getCustomerStudiesList(reportId);
        break;
      case "ecosystem":
        this.getEcoSystemList(reportId);
        break;
      case "special_report":
        this.getSpecialReportsList(reportId);
        break;
      case "project":
        this.getProjectList(reportId);
        break;
      default:
        break;
    }
  };

  handleSelectReportChange = (e) => {
    const selectedReportName = this.state.reportFileNameList.map((c) => {
      c.is_selected = c.id === e.id;
      return c;
    });
    this.setState(
      {
        reportFileNameList: selectedReportName,
        backendError: false,
      },
      () => {
        this.setState({
          formErrors: {
            reportTypeError: false,
          },
        });
      }
    );
  };

  closeDocumentModal = () => {
    this.setState((prevState) => ({
      backendError: false,
      documentModal: false,
      uploadCompanies: prevState.allCompanies.map((c) => {
        c.is_selected = false;
        return c;
      }),
      uploadSegments: prevState.allSegments.map((c) => {
        c.is_selected = false;
        return c;
      }),
      doc: {},
      docList: [],
      types: [],
      formErrors: {},
      isFormSubmitted: false,
      skipValidation: false,
      mappingType: null,
      editId: null,
      reportFileNameList: prevState.reportFileNameList.map((c) => {
        c.is_selected = false;
        return c;
      }),
      availableUploaders: prevState.availableUploaders.map((c) => {
        c.is_selected = false;
        return c;
      }),
      reportType: prevState.reportType.map((c) => {
        c.is_selected = false;
        return c;
      }),
      submitting: false,
    }));
  };

  onChangeHandlerFile = (name, content) => {
    this.setState(
      {
        backendError: false,
        doc: { name, content },
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  onChangeHandlerMultipleFile = (fileList, remove) => {
    let tempDocList = remove
      ? fileList.map((file) => ({
          id: file.name,
          name: file.name,
          content: file.content,
        }))
      : [...this.state.docList, ...fileList];
    this.setState(
      {
        backendError: false,
        // doc: { name, content },
        docList: tempDocList,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState(
      (prevState) => ({
        isFormSubmitted: true,
      }),
      () => {
        if (!this.isFormValid()) {
          if (this.state.submitting) return; // Prevent multiple submissions

          this.setState({ submitting: true });
          const companyId = this.state.uploadCompanies.find(
            (f) => f.is_selected === true
          );
          const segmentId = this.state.uploadSegments.find(
            (f) => f.is_selected === true
          );

          const mappingType = this.state.reportType.find(
            (f) => f.is_selected === true
          );

          const type = constants.HISTORICAL_FILE.HF_MAPPING_TYPES.find(
            (f) => f.value === mappingType.id
          );

          const mappingId = this.state.reportFileNameList.find(
            (f) => f.is_selected === true
          );

          const addInput = [];

          this.state.docList.forEach((doc) => {
            addInput.push({
              // type: "report_content",
              base64data: doc?.content?.split(";base64,")[1],
              report_id:
                type.id === "companies" ? companyId?.id : mappingId?.id,
              corelation_id: segmentId?.id ?? null,
              report_type: type.id,
              file_name: doc.name,
            });
          });

          const base64_data = this.state.doc?.content?.split(";base64,")[1];

          let body = this.state.editId
            ? {
                // type: "report_content",
                base64data: base64_data,
                report_id:
                  type.id === "companies" ? companyId?.id : mappingId?.id,
                corelation_id: segmentId?.id ?? null,
                report_type: type.id,
                file_name: this.state.doc.name,
              }
            : addInput;

          HistoricalFileServices.uploadHistoricalFile(this.state.editId, body)
            .then((res) => {
              Toast(
                constants.HISTORICAL_FILE?.[
                  this.state.editId ? "EDIT" : "CREATE"
                ].SUCCESS,
                "success"
              );
              this.closeDocumentModal();
              this.getTableData();
              this.setState({ submitting: false });
            })
            .catch((error) => {
              this.setState({
                backendError: true,
                skipValidation: false,
                backendErrorContent:
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                submitting: false,
              });

              setTimeout(() => {
                this.setState({
                  backendError: false,
                  backendErrorContent: "",
                });
              }, 3400);
            });
        }
      }
    );
  };

  isFormValid = () => {
    let reportTypeError = false;
    let formError = false;
    let companyError = false;
    let segmentError = false;
    let reportIdError = false;
    let uploaderServiceError = false;
    let docError = false;

    const reportType = this.state.reportType.find(
      (r) => r.is_selected === true
    );
    let segmentId = this.state.uploadSegments.find(
      (f) => f.is_selected === true
    );
    if (!reportType) {
      reportTypeError = true;
      formError = true;
    } else {
      if (reportType && reportType.id === "company_segment") {
        let companyId = this.state.uploadCompanies.find(
          (f) => f.is_selected === true
        );

        if (!companyId) {
          companyError = true;
          formError = true;
        }
      } else {
        let mappingId = this.state.reportFileNameList.find(
          (f) => f.is_selected === true
        );
        if (!mappingId) {
          reportIdError = true;
          formError = true;
        }
      }

      if (!segmentId) {
        segmentError = true;
        formError = true;
      }
      if (this.state.editId) {
        if (!this.state.doc && !this.state.doc.content) {
          docError = true;
          formError = true;
        }
      } else {
        if (!this.state.docList.length) {
          docError = true;
          formError = true;
        }
      }
    }

    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        reportTypeError: reportTypeError,
        companyError: companyError,
        segmentError: segmentError,
        reportIdError: reportIdError,
        uploaderServiceError: uploaderServiceError,
        docError: docError,
      },
      apiError: null,
    });
    return formError;
  };

  handleDownload = () => {
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_SHAREPOINT_STANDARD_TEMPLATE_LINK;
    document.body.appendChild(link);
    link.click();
  };

  handlerefresh = () => {
    const params = queryString.parse(this.props.location.search);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort
          ? params.sort
          : constants.PAGINATION.FILE_DEFAULT_SORT_COLUMN,
        sortOrder: params.order
          ? params.order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
        companyId: params.companyId ? params.companyId : "all",
        segmentId: params.segmentId ? params.segmentId : "all",
      },
      () => {
        this.getTableData();
      }
    );
  };

  handleCheckEvent = (e) => {
    this.setState({ skipValidation: e });
  };

  render() {
    let segments =
      this.props.home && this.props.home.segments
        ? this.props.home.segments
        : [];
    let companies = this.state.companies ? this.state.companies : [];

    return (
      <>
        <div className="row align-items-center flex-wrap-reverse">
          <div className="col-lg-12 col-xl-8">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="w-100-search border-search search-box-shadow-2">
                  <Search
                    handleSearch={this.handleSearch}
                    handleInput={this.handleInput}
                    value={this.state.name}
                    placeHolder={"Filter Name"}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="custom-combobox-list">
                  <CustomDropdownList
                    data={this.state.filerReportType}
                    handleChange={this.handleFilterReportTypeChange}
                    placeholder={"Select type for report"}
                  />
                </div>
              </div>
              {companies &&
                companies.length > 0 &&
                this.state.filerReportType.find(
                  (i) => i.is_selected && i.id === "company_segment"
                ) && (
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12 clients_dropdown mb-18px">
                    <div className="custom-combobox-list">
                      <CustomDropdownList
                        data={companies}
                        handleChange={this.handleCompanyChange}
                        placeholder={"Filter Company"}
                      />
                    </div>
                  </div>
                )}
              {segments && segments.length > 0 && (
                <div className="col-lg-3 col-md-3 col-sm-6 col-12 clients_dropdown mb-18px">
                  <div className="custom-combobox-list">
                    <CustomDropdownList
                      data={segments}
                      handleChange={this.handleSegmentChange}
                      placeholder={"Filter Segment"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12 col-xl-4">
            <div className="d-flex align-items-center flex-wrap mb-18px">
              <div className="ml-auto">
                <AclAction
                  type="permissions"
                  entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
                  action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.DOWNLOAD}
                >
                  <div
                    tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                    className=""
                  >
                    <button
                      className={
                        "btn btn-custom btn-info icon-text-btn title-btn mr-2 d-inline-flex align-items-center my-2 my-sm-0"
                      }
                      type="button"
                      onClick={this.handlerefresh}
                    >
                      <span className="material-icons-round icon-fs-16 mr-1">
                        refresh
                      </span>
                      Refresh
                    </button>

                    {permissionCheck(
                      constants.ACL.PERMISSION_KEY,
                      constants.ACL.DOWNLOADS_RESOURCE.TITLE,
                      constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.UPLOAD
                    ) && (
                      <button
                        className={
                          "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center my-2 my-sm-0"
                        }
                        type="button"
                        onClick={this.upload}
                      >
                        <span className="material-icons-round icon-fs-16 mr-1">
                          upload
                        </span>
                        {" " +
                          constants.HISTORICAL_FILE.DOWNLOAD.LIST
                            .ADD_BUTTON_TITLE}
                      </button>
                    )}
                  </div>
                </AclAction>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-table-block custom-table-block-2 th-nowrap sort-table-block center-th-align">
          <DynamicTableComponentNew
            columnData={tableHeader}
            rowData={this.state.rowData}
            pagePrevious={this.pagePrevious}
            pageNext={this.pageNext}
            pageSize={this.state.pageSize}
            pageNo={this.state.pageNo}
            handlePage={this.handlePage}
            handleSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            sortOrder={this.state.sortOrder}
            count={this.state.count}
            rowAction={this.rowAction}
            colSpan={tableHeader.filter((h) => h.visibility).length}
            showActions={showActions}
            showPagination={true}
            paginationClass={"center-pagination custom-pagination-new"}
            handlePageSize={this.handlePageSize}
          />
        </div>

        {/* Tooltips for icons in table, don't delete */}
        {this.state.rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.HISTORICAL_FILE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}

        <Modal
          isOpen={this.state.documentModal}
          toggle={this.closeDocumentModal}
          className={"modal-md modal-w-header custom-modal primary-modal"}
        >
          <form
            className={"col-lg-12 col-sm-12 form-wrapper p-0"}
            method="POST"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <ModalHeader toggle={this.closeDocumentModal}>Upload</ModalHeader>
            <ModalBody>
              {this.state.apiError !== null && (
                <Notification color={"danger"} message={this.state.apiError} />
              )}
              <div className={"row"}>
                <div className="col-sm-12 form-group position-relative">
                  <label htmlFor="type_of_report">
                    Type of Report <span className={"mandatory"}>* </span>
                  </label>
                  <div
                    className={`${
                      this.state.formErrors.reportTypeError ? "is-invalid" : ""
                    } custom-combobox-list`}
                  >
                    <CustomDropdownList
                      data={this.state.reportType}
                      handleChange={this.handleReportTypeChange}
                      placeholder={"Select type for report"}
                      isDisabled={this.state.editId ? true : false}
                    />
                  </div>
                  {this.state.formErrors.reportTypeError && (
                    <div className="invalid-tooltip d-block">
                      {constants.FORM.MANDATORY_FIELD}
                    </div>
                  )}
                </div>

                {this.state.mappingType &&
                this.state.mappingType === "company_segment" ? (
                  <>
                    <div className="col-sm-12 form-group position-relative">
                      <label htmlFor="company">
                        Company <span className={"mandatory"}>* </span>
                      </label>
                      <div
                        className={`${
                          this.state.formErrors.companyError ? "is-invalid" : ""
                        } custom-combobox-list`}
                      >
                        <CustomDropdownList
                          data={this.state.uploadCompanies}
                          handleChange={this.handleUploadCompanyChange}
                          placeholder={"Select Company"}
                        />
                      </div>
                      {this.state.formErrors.companyError && (
                        <div className="invalid-tooltip d-block">
                          {constants.FORM.MANDATORY_FIELD}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-12 form-group position-relative">
                      <label htmlFor="segment">
                        Segment <span className={"mandatory"}>* </span>
                      </label>
                      <div
                        className={`${
                          this.state.formErrors.segmentError ? "is-invalid" : ""
                        } custom-combobox-list`}
                      >
                        <CustomDropdownList
                          data={this.state.uploadSegments}
                          handleChange={this.handleUploadSegmentChange}
                          placeholder={"Select Segment"}
                        />
                      </div>
                      {this.state.formErrors.segmentError && (
                        <div className="invalid-tooltip d-block">
                          {constants.FORM.MANDATORY_FIELD}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  this.state.mappingType && (
                    <>
                      <div className="col-sm-12 form-group position-relative">
                        <label htmlFor="reportfilename">
                          Report File Name{" "}
                          <span className={"mandatory"}>* </span>
                        </label>
                        <div
                          className={`${
                            this.state.formErrors.reportIdError
                              ? "is-invalid"
                              : ""
                          } custom-combobox-list`}
                        >
                          <CustomDropdownList
                            data={this.state.reportFileNameList}
                            handleChange={this.handleSelectReportChange}
                            placeholder={"Select Report File"}
                          />
                        </div>
                        {this.state.formErrors.reportIdError && (
                          <div className="invalid-tooltip d-block">
                            {constants.FORM.MANDATORY_FIELD}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 form-group position-relative">
                        <label htmlFor="segment">
                          Segment <span className={"mandatory"}>* </span>
                        </label>
                        <div
                          className={`${
                            this.state.formErrors.segmentError
                              ? "is-invalid"
                              : ""
                          } custom-combobox-list`}
                        >
                          <CustomDropdownList
                            data={this.state.uploadSegments}
                            handleChange={this.handleUploadSegmentChange}
                            placeholder={"Select Segment"}
                          />
                        </div>
                        {this.state.formErrors.segmentError && (
                          <div className="invalid-tooltip d-block">
                            {constants.FORM.MANDATORY_FIELD}
                          </div>
                        )}
                      </div>
                    </>
                  )
                )}
                {this.state.mappingType && (
                  <>
                    <div className="col-sm-12 form-group position-relative">
                      <div className="d-flex">
                        <label htmlFor="description">
                          Document <span className={"mandatory"}>* </span>
                        </label>
                        <div>
                          <span
                            data-tip=""
                            data-for="document-info"
                            style={{ color: "#1E81E8" }}
                            className="material-icons-round icon-fs-16 ml-2 cursor-pointer valign-middle"
                          >
                            info
                          </span>
                          <ReactTooltip
                            id="document-info"
                            place="right"
                            effect="solid"
                          >
                            Format: TBR_Company_Quater_Segment.pptx
                          </ReactTooltip>
                        </div>
                      </div>
                      {this.state.editId ? (
                        <CustomPPTUploader
                          value={this.state.doc}
                          onChangeHandler={this.onChangeHandlerFile}
                          type={""}
                        />
                      ) : (
                        <MultipleFileUpload
                          value={this.state.docList}
                          onChangeHandler={this.onChangeHandlerMultipleFile}
                          type={""}
                          multiple={true}
                        />
                      )}

                      <div className="list_metric_dialog">
                        {this.state.formErrors.docError && (
                          <div className="error-msg clearfix">
                            <div className="empty-files">
                              {constants.FORM.MANDATORY_FIELD_FILE_UPLOAD}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 form-group position-relative">
                      <div className="file-restriction-message text-center">
                        Note: Only PPT, PDF, DOC, XSLX files are allowed
                        {!this.state.editId && (
                          <>and you can upload a maximum upto 5 files.</>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {this.state.backendError && (
                  <div className="col-sm-12 form-group position-relative">
                    <div className="ppt-upload-error">
                      {this.state.backendErrorContent}
                    </div>
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className="modal-btn btn-outline-primary"
                onClick={this.closeDocumentModal}
              >
                Cancel
              </Button>
              <Button
                className="modal-btn"
                color="primary"
                type="submit"
                disabled={this.state.submitting}
              >
                {this.state.submitting ? "Uploading..." : "Upload"}
              </Button>{" "}
            </ModalFooter>
          </form>
        </Modal>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalFile);
