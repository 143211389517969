import { Axios } from "../lib/axios";

const magicLinkRoute = "magic-link/";

const addMagicLinkUser = (body) => {
  return Axios.post(`${magicLinkRoute}user`, body);
};

const editMagicLinkUser = (userId, body) => {
  return Axios.put(`${magicLinkRoute}user/${userId}`, body);
};
const getAllMagicLinkUsers = (queryParams) => {
  return Axios.get(`${magicLinkRoute}user${queryParams}`);
};

const getSpecificMagikLinkUser = (userId) => {
  return Axios.get(`${magicLinkRoute}user/${userId}`);
};

const updateUserApiStatus = (userId, body) => {
  return Axios.patch(`${magicLinkRoute}user/${userId}/status/${body}`);
};
const deleteMagicLinkUser = (userId) => {
  return Axios.delete(`${magicLinkRoute}user/${userId}`);
};

const copyMagicLink = (queryParams) => {
  return Axios.get(`${magicLinkRoute}copy/${queryParams}`);
};

const resendMagicLink = (queryParams) => {
  return Axios.get(`${magicLinkRoute}renewal/${queryParams}`);
};
const magicLinkLogin = (body) => {
  return Axios.post(`${magicLinkRoute}/login`, body);
};
const requestMagicLink = (token) => {
  return Axios.get(`${magicLinkRoute}linkReq/${token}`);
};
const renewMagicLinkRequest = (userId) => {
  return Axios.get(`${magicLinkRoute}request/${userId}`);
};
export const MagicLinkUserServices = {
  getAllMagicLinkUsers,
  addMagicLinkUser,
  editMagicLinkUser,
  getSpecificMagikLinkUser,
  updateUserApiStatus,
  deleteMagicLinkUser,
  copyMagicLink,
  resendMagicLink,
  magicLinkLogin,
  requestMagicLink,
  renewMagicLinkRequest,
};
