import React, { Component } from "react";
import constants from "../Utils/constants";
import AclAction from "../Components/ACL/AclAction";
import { ClientServices } from "../Services/Client";
import { UserServices } from "../Services/User";
import { CompanyServices } from "../Services/Company";
import { formatRoute } from "react-router-named-routes";
import DailogNew from "../Components/DailogNew";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import { connect } from "react-redux";
import { companies } from "../Store/Actions/CompanyDetails";
import { userDesignation, userRoles } from "../Store/Actions/User";
import Toast from "../Utils/Toast";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import UserPermissions from "../Components/UserPermissions";
import DocumentTitle from "../Utils/DocumentTitle";
import capitalize from "../Utils/Capitalize";
import decodeJWT from "../lib/decodeJWT";
import { isEmpty, isNumber } from "lodash";
import { normalizeToHours } from "../Utils/helper";

class ManageAdminUser extends Component {
  state = {
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userContactNumber: "",
    userDesignation: "",
    userAddress: "",
    userDescription: "",
    userRoleId: "",
    isAllCompany: false,
    isAllSegment: false,
    segmentList: [],
    segmentCompanies: [],
    formErrors: {},
    modifiedValueType: "profile",
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    isFormSubmitted: false,
    apiError: null,
    totalPermissions: [],
    selectedPermissions: [],
    isExpirationTimeUnlimited: false,
    expirationTime: "",
    isMagicUserEnabled: false,
    time: "In Hours",
  };
  componentDidMount() {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    DocumentTitle(
      userId === null
        ? constants.APPLICATION_ROUTE.TBRI_USER.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.TBRI_USER.EDIT.HEADER_TITLE
    );
    let totalCompanies = [];
    CompanyServices.getTotalCompanies("companies")
      .then((res) => {
        let companyResponse = res && res.data && res.data.data;
        companyResponse.forEach((com) => {
          com.company_segment_id = com.id;
        });
        totalCompanies.push(...companyResponse);
        ClientServices.getSegmentDetails(1)
          .then((res) => {
            let segmentsResponse = res && res.data && res.data.data;
            let segmentList = [];
            segmentList.push({
              uid: Math.random(),
              id: "all",
              name: "All",
              companies: totalCompanies,
            });
            segmentsResponse.forEach((seg) => {
              seg.companies.forEach((com) => {
                com.company_segment_id = com.company_segment
                  ? com.company_segment.id
                  : "-";
              });
            });
            segmentList = [...segmentList, ...segmentsResponse];
            this.setState({ segmentList: segmentList });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
        UserServices.getUserRoles()
          .then((res) => {
            let response = res && res.data;
            this.props.setUserRoles(response);
            if (userId !== null) this.prepopulateData(userId);
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  }
  prepopulateData = (userId) => {
    UserServices.getUserDetails(userId)
      .then((res) => {
        let response = res.data;
        let segmentCompanies = [];
        response.company_segment.forEach((s) => {
          segmentCompanies.push({
            uid: Math.random(),
            id: s.id,
            name: s.name,
            companies:
              (s.companies &&
                s.companies.map((c) => {
                  return { id: c.company_segment_id, name: c.name };
                })) ||
              [],
          });
        });
        if (response.segments.length > 0) {
          response.segments.forEach((e) => {
            segmentCompanies.push({
              uid: Math.random(),
              id: e.id,
              name: e.name,
              companies: [{ id: "all", name: "All" }],
            });
          });
        }
        if (response.allow_all_segments) {
          segmentCompanies.push({
            uid: Math.random(),
            id: "all",
            name: "All",
            companies: [{ id: "all", name: "All" }],
          });
        }
        if (response.companies.length > 0) {
          segmentCompanies.push({
            uid: Math.random(),
            id: "all",
            name: "All",
            companies: response.companies.map((c) => {
              return { id: c.id, name: c.name, company_segment_id: c.id };
            }),
          });
          response.companies.forEach((e) => {});
        }

        let expiration_time = response?.expiration_time >= 24;
        let tempExpTime = response?.expiration_time;
        if (expiration_time) {
          this.setState({ time: "In Days" });
          tempExpTime = tempExpTime / 24;
        }

        this.setState(
          {
            userFirstName: response.first_name ? response.first_name : "",
            userLastName: response.last_name ? response.last_name : "",
            userEmail: response.email ? response.email : "",
            userContactNumber: response.mobile ? response.mobile : "",
            userDesignation: response.designation ? response.designation : "",
            userAddress: response.address ? response.address : "",
            userDescription: response.description ? response.description : "",
            userRoleId:
              response.role && response.role.id ? response.role.id : "",
            segmentCompanies: segmentCompanies,
            isFormSubmitted: false,
            apiError: null,
            isExpirationTimeUnlimited: response.expiration_time === null,
            expirationTime: tempExpTime || "",
            isMagicUserEnabled: response.is_magic,
          },
          () => {
            this.setRole();
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleSegmentChange = (e) => {
    let segments = [...this.state.segmentList];
    this.setState({ isAllCompany: false });
    if (e.id === "all") {
      this.setState({ isAllSegment: true });
    } else {
      this.setState({ isAllSegment: false });
    }
    segments.forEach((s) => {
      s.is_selected = e.id === s.id ? true : false;
      s.companies &&
        s.companies.forEach((com) => {
          com.is_selected = false;
        });
    });
    this.setState({ segmentList: segments });
  };
  handleCompanyChange = (e) => {
    let selectedCompanies = e.map((a) => a.id);
    let segments = [...this.state.segmentList];
    let s = segments.find((s) => s.is_selected === true);
    s.companies &&
      s.companies.forEach((c) => {
        c.is_selected = selectedCompanies.includes(c.id);
      });
    this.setState({ segmentList: segments });
  };
  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleCompanyCheckboxChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.checked,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleAddSegmentCompany = () => {
    let segments = [...this.state.segmentList];
    let selectedCompanies = [];
    let finalObject = [];
    let selectedSegments = {};
    selectedSegments = segments.find((s) => s.is_selected === true);
    if (this.state.isAllCompany) {
      selectedCompanies = [
        {
          id: "all",
          is_selected: true,
          name: "All",
          company_segment_id: "all",
        },
      ];
    } else
      selectedCompanies = selectedSegments
        ? selectedSegments.companies.filter((c) => c.is_selected === true)
        : [];
    // }
    finalObject = [...this.state.segmentCompanies];
    if (selectedCompanies.length > 0) {
      let segmentIndex = finalObject.findIndex(
        (f) => f.id === selectedSegments.id
      );
      if (segmentIndex < 0) {
        let newO = {
          uid: Math.random(),
          id: selectedSegments.id,
          name: selectedSegments.name,
          companies: selectedCompanies.map((c) => {
            return { id: c.company_segment_id, name: c.name };
          }),
        };
        finalObject.push(newO);
      } else {
        let existing = [...finalObject[segmentIndex].companies];
        selectedCompanies.forEach((c) => {
          if (existing.findIndex((f) => f.id === c.company_segment_id) < 0) {
            if (
              c.company_segment_id !== "all" ||
              (c.company_segment_id === "all" &&
                selectedCompanies.length === 1 &&
                existing.length === 0)
            )
              if (typeof existing.find((e) => e.id === "all") === "undefined") {
                existing.push({ id: c.company_segment_id, name: c.name });
              }
          }
        });
        if (
          typeof selectedCompanies.find((e) => e.id === "all") !== "undefined"
        ) {
          finalObject[segmentIndex].companies = [
            {
              id: "all",
              is_selected: true,
              name: "All",
              company_segment_id: "all",
            },
          ];
        } else finalObject[segmentIndex].companies = existing;
      }
    }
    this.setState(
      {
        ...this.state,
        segmentCompanies: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeSegmentConfirmation = (uid) => {
    let dailogModalHeader = (
      <div>
        <h3 className="text-darkblue2 font-weight-bold">Remove Segment</h3>
      </div>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.TBRI_USER.REMOVE_SEGMENT_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_segment", id: uid },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeSegment = (uid) => {
    let finalObject = [...this.state.segmentCompanies];
    finalObject = finalObject.filter((ob) => ob.uid !== uid);
    this.setState(
      {
        ...this.state,
        segmentCompanies: finalObject,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  removeCompanyConfirmation = (uid, companyId) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Company</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.COMPANY.REMOVE_COMPANY_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: "remove_company",
        id: uid,
        companyId: companyId,
      },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeCompany = (uid, companyId) => {
    let finalObject = [...this.state.segmentCompanies];
    let index = finalObject.findIndex((f) => f.uid === uid);
    let m = finalObject[index].companies.filter((ob) => ob.id !== companyId);
    finalObject[index].companies = m;
    this.setState(
      {
        ...this.state,
        segmentCompanies: finalObject,
      },
      () => {
        if (m.length === 0) this.removeSegment(uid);
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  setRole = () => {
    let userRoles = [...this.props.home.userRoles];
    userRoles.forEach((c) => {
      c.is_selected = c.id === this.state.userRoleId ? true : false;
    });
    let p;
    if (this.props.match.params.id)
      p = UserServices.getTotalRoleUserPermissions(
        this.state.userRoleId,
        this.props.match.params.id
      );
    else p = UserServices.getTotalRolePermissions(this.state.userRoleId);
    p.then((res) => {
      let response = res && res.data && res.data.data;
      this.setState({
        totalPermissions: response,
        selectedPermissions: [],
      });
    }).catch((error) => {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    });
    this.props.setUserRoles(userRoles);
  };

  handleRoleChange = (e) => {
    this.setState(
      {
        userRoleId: e.id,
      },
      () => {
        this.setRole();
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };

  handleCheckboxChangePermissions = (e, id, moduleId, parentModuleId) => {
    let selectedPermissions = this.state.selectedPermissions;
    let totalPermissions = this.state.totalPermissions;
    if (parentModuleId) {
      let i = totalPermissions.findIndex((s) => s.module === parentModuleId);
      let sbi = totalPermissions[i].sub_modules.findIndex(
        (s) => s.module === moduleId
      );
      let fi = totalPermissions[i].sub_modules[sbi].permissions.findIndex(
        (f) => f.id === id
      );
      totalPermissions[i].sub_modules[sbi].permissions[fi].is_allowed = e.target
        .checked
        ? 1
        : 0;
    } else {
      let i = totalPermissions.findIndex((s) => s.module === moduleId);
      let fi = totalPermissions[i].permissions.findIndex((f) => f.id === id);
      totalPermissions[i].permissions[fi].is_allowed = e.target.checked ? 1 : 0;
    }
    let findIndex = selectedPermissions.findIndex((f) => f.id === id);
    if (findIndex >= 0)
      selectedPermissions[findIndex].is_deleted = !e.target.checked;
    else selectedPermissions.push({ id: id, is_deleted: !e.target.checked });
    this.setState({
      selectedPermissions: selectedPermissions,
      totalPermissions: totalPermissions,
    });
  };

  isFormValid = () => {
    let formError = false;
    let userFirstNameError = false;
    let userLastNameError = false;
    let userDesignationError = false;
    let userEmailError = false;
    let userRoleIdError = false;
    let userContactNumberError = false;
    let userExpirationTimeError = false;
    let roles =
      this.props.home && this.props.home.userRoles
        ? this.props.home.userRoles
        : [];
    let selectedRole = roles.find((r) => r.is_selected)
      ? roles.find((r) => r.is_selected).short_name
      : null;
    if (!isNumber(this.state.userRoleId)) {
      userRoleIdError = true;
      formError = true;
    }
    if (isEmpty(this.state.userFirstName.trim())) {
      userFirstNameError = true;
      formError = true;
    }
    if (isEmpty(this.state.userLastName.trim())) {
      userLastNameError = true;
      formError = true;
    }
    if (isEmpty(this.state.userDesignation.trim())) {
      userDesignationError = true;
      formError = true;
    }
    if (isEmpty(this.state.userEmail.trim())) {
      userEmailError = true;
      formError = true;
    } else {
      var re = constants.REG_EX.EMAIL_REG_EX;
      userEmailError = !re.test(String(this.state.userEmail).toLowerCase());
      if (userEmailError === true) {
        formError = true;
      }
    }
    if (this.state.userContactNumber !== "") {
      var re_phone = constants.REG_EX.PHONE_REG_EX;
      userContactNumberError = !re_phone.test(this.state.userContactNumber);
      if (userContactNumberError === true) {
        formError = true;
      }
    }
    if (
      selectedRole &&
      selectedRole !== constants.USER_ROLES.EDITOR.SLUG &&
      selectedRole !== constants.USER_ROLES.ADMIN.SLUG
    ) {
      let companies = [];
      this.state.segmentCompanies.forEach((s) => {
        s.companies.forEach((c) => {
          companies.push(c.id);
        });
      });
      if (companies.length === 0) {
        formError = true;
      }
    }

    if (
      this.state.isMagicUserEnabled &&
      !this.state.isExpirationTimeUnlimited &&
      isEmpty(this.state.expirationTime)
    ) {
      userExpirationTimeError = true;
      formError = true;
    }

    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        userRoleIdError: userRoleIdError,
        userFirstNameError: userFirstNameError,
        userLastNameError: userLastNameError,
        userDesignationError: userDesignationError,
        userEmailError: userEmailError,
        userContactNumberError: userContactNumberError,
        userExpirationTimeError: userExpirationTimeError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let companies = [];
          let company_segments = [];
          let segments = [];
          let isAllSegmentCheck = 0;
          let roles =
            this.props.home && this.props.home.userRoles
              ? this.props.home.userRoles
              : [];
          let selectedRole = roles.find((r) => r.is_selected)
            ? roles.find((r) => r.is_selected).short_name
            : null;
          if (
            selectedRole &&
            selectedRole !== constants.USER_ROLES.EDITOR.SLUG &&
            selectedRole !== constants.USER_ROLES.ADMIN.SLUG
          )
            this.state.segmentCompanies.forEach((s) => {
              s.companies.forEach((c) => {
                if (c.id !== "all" && c.id !== "-" && s.id !== "all")
                  company_segments.push(c.id);
                if (c.id === "all" && s.id === "all") isAllSegmentCheck = 1;
                if (c.id === "all" && s.id !== "all") segments.push(s.id);
                if (c.id !== "all" && s.id === "all") companies.push(c.id);
              });
            });
          else {
            isAllSegmentCheck = 1;
          }
          let userId = this.props.match.params.id
            ? this.props.match.params.id
            : null;
          let prepareObject = {
            first_name: capitalize(this.state.userFirstName),
            last_name: capitalize(this.state.userLastName),
            role_id: this.state.userRoleId,
            email: this.state.userEmail,
            designation: this.state.userDesignation,
            mobile: this.state.userContactNumber,
            address: this.state.userAddress,
            description: this.state.userDescription,
            company_segment_ids: company_segments,
            allow_all_companies: this.state.isAllCompany ?? 0,
            allow_all_segments: isAllSegmentCheck,
            company_ids: companies,
            segment_ids: segments,
            is_magic: Number(this.state.isMagicUserEnabled),
            expiration_time: this.state.isExpirationTimeUnlimited
              ? null
              : normalizeToHours(this.state.expirationTime, this.state.time),
          };
          // return false;
          if (userId === null) {
            //create
            UserServices.addUser(prepareObject)
              .then((res) => {
                let userIdCreated = res.data.data.user[0].id;
                let permissions = this.state.selectedPermissions.map((s) => ({
                  id: s.id,
                  is_deleted: s.is_deleted === false ? 0 : 1,
                }));
                if (permissions.length > 0) {
                  UserServices.savePermissions(userIdCreated, {
                    user_permissions: permissions,
                  })
                    .then((res) => {
                      this.saveUserSuccess();
                    })
                    .catch((error) => {
                      this.setState({
                        apiError:
                          error && error.data && error.data.error
                            ? error.data.error
                            : constants.ERROR.SOMETHING_WENT_WRONG,
                      });
                      Toast(
                        error && error.data && error.data.error
                          ? error.data.error
                          : constants.ERROR.SOMETHING_WENT_WRONG,
                        "error"
                      );
                    });
                } else {
                  this.saveUserSuccess();
                }
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
                Toast(
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          } else {
            //edit
            UserServices.editUser(userId, prepareObject)
              .then((res) => {
                if (this.props.home.loginUserSuccess.id == userId) {
                  let { designation } = JSON.parse(res.config.data);
                  this.props.setUserDesignation(designation);
                }
                let permissions = this.state.selectedPermissions.map((s) => ({
                  id: s.id,
                  is_deleted: s.is_deleted === false ? 0 : 1,
                }));
                if (permissions.length > 0) {
                  UserServices.savePermissions(userId, {
                    user_permissions: permissions,
                  })
                    .then((res) => {
                      this.saveUserSuccess();
                    })
                    .catch((error) => {
                      this.setState({
                        apiError:
                          error && error.data && error.data.error
                            ? error.data.error
                            : constants.ERROR.SOMETHING_WENT_WRONG,
                      });
                      Toast(
                        error && error.data && error.data.error
                          ? error.data.error
                          : constants.ERROR.SOMETHING_WENT_WRONG,
                        "error"
                      );
                    });
                } else {
                  this.saveUserSuccess();
                }
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
                Toast(
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          }
        }
      }
    );
  };
  saveUserSuccess = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    Toast(
      userId === null
        ? constants.TBRI_USER.CREATE.SUCCESS
        : constants.TBRI_USER.EDIT.SUCCESS,
      "success"
    );
    setTimeout(
      () =>
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE
          ),
        }),
      1500
    );
  };
  dailogModalAccept = (config) => {
    this.setState(
      {
        dailogModal: false,
        dailogModalHeader: "",
        dailogModalContent: "",
        dailogModalConfig: {},
      },
      () => {
        switch (config.type) {
          case "remove_company":
            this.removeCompany(config.id, config.companyId);
            break;
          case "remove_segment":
            this.removeSegment(config.id);
            break;
          case "remove_user":
            this.removeAdminUser();
            break;
          default:
            break;
        }
      }
    );
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  resetForm = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        userRoleIdError: false,
        userFirstNameError: false,
        userLastNameError: false,
        userDesignationError: false,
        userEmailError: false,
        userContactNumberError: false,
      },
      apiError: null,
    });
    if (userId !== null) {
      this.prepopulateData(userId);
    } else {
      this.setState({
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userContactNumber: "",
        userAddress: "",
        userDesignation: "",
        selectedRole: "",
        companySegments: [],
        segmentCompanies: [],
        selectedPermissions: [],
        formErrors: {},
        dailogModal: false,
        dailogModalContent: "",
        dailogModalHeader: "",
        dailogModalConfig: {},
        dailogModalStyleType: "",
        isFormSubmitted: false,
        apiError: null,
      });
    }
  };
  breadcrumbs = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    return [
      {
        title: constants.APPLICATION_ROUTE.TBRI_USER.LIST.NAME,
        link: constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE,
        is_active: false,
      },
      {
        title: userId
          ? constants.APPLICATION_ROUTE.TBRI_USER.EDIT.NAME
          : constants.APPLICATION_ROUTE.TBRI_USER.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
  };

  tabChangeHandler = (e) => {
    if (e === "permissions") {
      if (this.state.totalPermissions.length) {
        this.setState({
          modifiedValueType: e,
        });
      }
    } else {
      this.setState({
        modifiedValueType: e,
      });
    }
  };
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE),
    });
  };

  removeAdminUserConfirmationHandler = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.TBRI_USER.REMOVE_USER_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_user" },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeAdminUser = () => {
    UserServices.removeUser(this.props.match.params.id)
      .then((res) => {
        Toast(constants.TBRI_USER.REMOVE.SUCCESS, "success");
        setTimeout(
          () =>
            this.props.history.push({
              pathname: formatRoute(
                constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE
              ),
            }),
          1500
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  // Handle checkbox change
  handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    this.setState({
      isExpirationTimeUnlimited: isChecked,
    });

    if (isChecked) {
      this.setState((prev) => ({
        ...prev,
        expirationTime: "",
      }));
    }
  };

  // Handle input change
  handleExpirationTimeChange = (e) => {
    const { value } = e.target;
    this.setState((prev) => ({
      ...prev,
      expirationTime: value,
    }));

    if (value) {
      this.setState({
        isExpirationTimeUnlimited: false,
      });
    }
  };

  handleMagicLinkToggle = (e) => {
    const isChecked = e.target.checked;
    this.setState({
      isMagicUserEnabled: isChecked,
    });
  };

  render() {
    const segmentList = this.state.segmentList;
    let segments = segmentList;
    let companies = segments.filter(
      (segment) => segment.is_selected === true
    )[0]
      ? segments.filter((segment) => segment.is_selected === true)[0].companies
      : [];
    let userId = this.props.match.params.id ? this.props.match.params.id : null;
    let segmentSelected = segments.find(
      (segment) => segment.is_selected === true
    )
      ? true
      : false;
    let roles =
      this.props.home && this.props.home.userRoles
        ? this.props.home.userRoles
        : [];
    let selectedRole = roles.find((r) => r.is_selected)
      ? roles.find((r) => r.is_selected).short_name
      : null;
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"add-edit-form-card"}>
                <form className={"form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card border-0">
                    <div className="card-header title-cancel-header d-flex align-items-center">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {userId === null
                          ? constants.APPLICATION_ROUTE.TBRI_USER.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.TBRI_USER.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <div className="ml-auto manage-delete-button">
                        {this.props.match.params.id && (
                          <AclAction
                            type={constants.ACL.PERMISSION_KEY}
                            entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
                            action={
                              constants.ACL.TBRI_USER_RESOURCE.ACTIONS.DELETE
                            }
                          >
                            <button
                              tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                              type="button"
                              className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                              onClick={this.removeAdminUserConfirmationHandler}
                              style={{
                                visibility:
                                  this.props.home.loginUserSuccess.id ==
                                  this.props.match.params.id
                                    ? "hidden"
                                    : "visible",
                              }}
                            >
                              <span className="mt-2px">Delete User</span>
                              <span className="material-icons icon-fs-16 ml-1">
                                delete
                              </span>
                            </button>
                          </AclAction>
                        )}
                      </div>
                      <span
                        className="material-icons-round text-darkblue2 ml-4 cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    <div className="card-body">
                      <Nav tabs className="custom-nav-tabs mb-3">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active:
                                this.state.modifiedValueType === "profile",
                            })}
                            onClick={() => {
                              this.tabChangeHandler("profile");
                            }}
                          >
                            Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active:
                                this.state.modifiedValueType === "permissions",
                            })}
                            onClick={() => {
                              this.tabChangeHandler("permissions");
                            }}
                          >
                            Permissions
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={this.state.modifiedValueType}
                        className={`mt-3`}
                      >
                        <TabPane tabId="profile">
                          {this.state.modifiedValueType &&
                            this.state.modifiedValueType === "profile" && (
                              <>
                                <div className="card-body-header">
                                  <h5 className="mb-0 font-weight-semibold">
                                    User Information
                                  </h5>
                                </div>
                                <div className="form-field-wrapper">
                                  <div className="row">
                                    <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                                      <div className={"row"}>
                                        <div className="col-sm-6 form-group position-relative">
                                          <label htmlFor="userFirstName">
                                            First Name{" "}
                                            <span className={"mandatory"}>
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className={`form-control capitalize ${
                                              this.state.formErrors
                                                .userFirstNameError === true
                                                ? "is-invalid"
                                                : ""
                                            } ${
                                              this.state.formErrors
                                                .userFirstNameError === false
                                                ? ""
                                                : ""
                                            }`}
                                            id="userFirstName"
                                            placeholder="First Name"
                                            value={this.state.userFirstName}
                                            onChange={this.handleInputChange}
                                          />
                                          {this.state.formErrors
                                            .userFirstNameError && (
                                            <div className="invalid-tooltip">
                                              {constants.FORM.MANDATORY_FIELD}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-sm-6 form-group position-relative">
                                          <label htmlFor="userLastName">
                                            Last Name{" "}
                                            <span className={"mandatory"}>
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className={`form-control capitalize ${
                                              this.state.formErrors
                                                .userLastNameError === true
                                                ? "is-invalid"
                                                : ""
                                            } ${
                                              this.state.formErrors
                                                .userLastNameError === false
                                                ? ""
                                                : ""
                                            }`}
                                            id="userLastName"
                                            placeholder="Last Name"
                                            value={this.state.userLastName}
                                            onChange={this.handleInputChange}
                                          />
                                          {this.state.formErrors
                                            .userLastNameError && (
                                            <div className="invalid-tooltip">
                                              {constants.FORM.MANDATORY_FIELD}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className={"row"}>
                                        <div className="col-sm-6 form-group position-relative">
                                          <label htmlFor="userEmail">
                                            Email{" "}
                                            <span className={"mandatory"}>
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            className={`form-control ${
                                              this.state.formErrors
                                                .userEmailError === true
                                                ? "is-invalid"
                                                : ""
                                            } ${
                                              this.state.formErrors
                                                .userEmailError === false
                                                ? ""
                                                : ""
                                            }`}
                                            id="userEmail"
                                            placeholder="Email (example@domain.com)"
                                            value={this.state.userEmail}
                                            onChange={this.handleInputChange}
                                          />
                                          {this.state.formErrors
                                            .userEmailError && (
                                            <div className="invalid-tooltip">
                                              {constants.FORM.INVALID_EMAIL}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-lg-6 col-sm-6 form-group position-relative">
                                          <label htmlFor="segments">
                                            Role{" "}
                                            <span className={"mandatory"}>
                                              *
                                            </span>
                                          </label>
                                          <div
                                            className={`${
                                              this.state.formErrors
                                                .userRoleIdError
                                                ? "is-invalid"
                                                : ""
                                            } custom-combobox-list`}
                                          >
                                            <CustomDropdownList
                                              data={roles}
                                              handleChange={
                                                this.handleRoleChange
                                              }
                                              placeholder={"Select Role"}
                                            />
                                          </div>
                                          {this.state.formErrors
                                            .userRoleIdError && (
                                            <div className="invalid-tooltip d-block">
                                              {constants.FORM.MANDATORY_FIELD}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className={"row"}>
                                        <div className="col-sm-6 form-group position-relative">
                                          <label htmlFor="userContactNumber">
                                            Contact No.
                                          </label>
                                          <input
                                            type="text"
                                            className={`form-control ${
                                              this.state.formErrors
                                                .userContactNumberError === true
                                                ? "is-invalid"
                                                : ""
                                            } ${
                                              this.state.formErrors
                                                .userContactNumberError ===
                                              false
                                                ? ""
                                                : ""
                                            }`}
                                            id="userContactNumber"
                                            placeholder="Contact No. (99999999)"
                                            value={this.state.userContactNumber}
                                            onChange={this.handleInputChange}
                                          />
                                          {this.state.formErrors
                                            .userContactNumberError && (
                                            <div className="invalid-tooltip">
                                              {
                                                constants.FORM
                                                  .INVALID_CONTACT_NUMBER
                                              }
                                            </div>
                                          )}
                                        </div>
                                        {tokenDetails.role.short_name ===
                                          "admin" && (
                                          <div className="col-sm-6 form-group position-relative">
                                            <label htmlFor="userLastName">
                                              Designation{" "}
                                              <span className={"mandatory"}>
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              className={`form-control capitalize ${
                                                this.state.formErrors
                                                  .userDesignationError === true
                                                  ? "is-invalid"
                                                  : ""
                                              } ${
                                                this.state.formErrors
                                                  .userDesignationError ===
                                                false
                                                  ? ""
                                                  : ""
                                              }`}
                                              id="userDesignation"
                                              placeholder="Designation (Account Executives)"
                                              value={this.state.userDesignation}
                                              onChange={this.handleInputChange}
                                            />
                                            {this.state.formErrors
                                              .userDesignationError && (
                                              <div className="invalid-tooltip">
                                                {constants.FORM.MANDATORY_FIELD}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-8 form-group position-relative">
                                          {/* Magic Link Label */}
                                          <label>Magic Link</label>

                                          {/* Toggle Switch */}
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ fontSize: "12px" }}
                                          >
                                            <span
                                              className="form-check-label mr-1 fs-14"
                                              style={{
                                                color: !this.state
                                                  .isMagicUserEnabled
                                                  ? "#0461c2"
                                                  : "#606b77",
                                              }}
                                            >
                                              Disable
                                            </span>
                                            <div className="custom-control custom-switch success-toggle sso-saml-toggle-switch">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="magicLinkSwitch"
                                                checked={
                                                  this.state.isMagicUserEnabled
                                                }
                                                onChange={
                                                  this.handleMagicLinkToggle
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="magicLinkSwitch"
                                              />
                                            </div>
                                            <span
                                              className="form-check-label fs-14"
                                              style={{
                                                color: this.state
                                                  .isMagicUserEnabled
                                                  ? "#0461c2"
                                                  : "#606b77",
                                              }}
                                            >
                                              Enable
                                            </span>
                                          </div>

                                          {/* Expiration Time Section - Only Visible if Magic Link is Enabled */}
                                          {this.state.isMagicUserEnabled && (
                                            <>
                                              <label
                                                htmlFor="expirationTime"
                                                className="mt-3"
                                              >
                                                Expiration Time
                                              </label>
                                              <div className="d-flex flex-column flex-sm-row align-items-start">
                                                <div className="mr-sm-2 d-flex align-items-center w-100 w-sm-auto mt-2">
                                                  <input
                                                    className="form-check-input custom-checkbox-new ml-0 mt-0"
                                                    type="checkbox"
                                                    id="expirationTimeUnlimited"
                                                    checked={
                                                      this.state
                                                        .isExpirationTimeUnlimited ||
                                                      this.state
                                                        .expirationTime == null
                                                    }
                                                    onChange={
                                                      this.handleCheckboxChange
                                                    }
                                                    disabled={
                                                      this.state.expirationTime
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label ml-1"
                                                    htmlFor="expirationTimeUnlimited"
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    Unlimited
                                                  </label>
                                                </div>
                                                <div
                                                  style={{
                                                    padding: "4px",
                                                    minWidth: "140px",
                                                  }}
                                                >
                                                  <div
                                                    className="input-group"
                                                    id="expirationTime"
                                                    style={{
                                                      position: "relative",
                                                      alignItems: "baseline",
                                                    }}
                                                  >
                                                    <input
                                                      type="number"
                                                      className={`form-control ${
                                                        this.state.formErrors
                                                          ?.userExpirationTimeError
                                                          ? "is-invalid"
                                                          : ""
                                                      }`}
                                                      id="expirationTime"
                                                      style={{
                                                        maxHeight: "29px",
                                                        maxWidth: "60px",
                                                      }}
                                                      value={
                                                        this.state
                                                          .expirationTime
                                                      }
                                                      onChange={
                                                        this
                                                          .handleExpirationTimeChange
                                                      }
                                                      disabled={
                                                        this.state
                                                          .isExpirationTimeUnlimited
                                                      }
                                                      min={1}
                                                    />
                                                    <div className="input-group-append">
                                                      <span
                                                        className="ml-3"
                                                        style={{
                                                          color:
                                                            !this.state
                                                              .isExpirationTimeUnlimited &&
                                                            this.state.time ===
                                                              "In Days"
                                                              ? "#1E81E8"
                                                              : "#606B77",
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        In Days
                                                      </span>
                                                      <label className="exp-switch ml-2">
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            this.state.time ===
                                                            "In Hours"
                                                          }
                                                          disabled={
                                                            this.state
                                                              .isExpirationTimeUnlimited
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              this.setState({
                                                                time: "In Hours",
                                                              });
                                                            } else {
                                                              this.setState({
                                                                time: "In Days",
                                                              });
                                                            }
                                                          }}
                                                        />
                                                        <span className="exp-slider" />
                                                      </label>
                                                      <span
                                                        style={{
                                                          color:
                                                            !this.state
                                                              .isExpirationTimeUnlimited &&
                                                            this.state.time ===
                                                              "In Hours"
                                                              ? "#1E81E8"
                                                              : "#606B77",
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        In Hours
                                                      </span>
                                                    </div>
                                                    {this.state.formErrors
                                                      ?.userExpirationTimeError && (
                                                      <div className="ml-2 d-flex align-items-center">
                                                        <span
                                                          className="material-icons-round icon-fs-16 cursor-pointer"
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          info
                                                        </span>
                                                        <span
                                                          className="ml-1"
                                                          style={{
                                                            color: "red",
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          Please fill at least
                                                          one field
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      <div className={"row"}>
                                        <div className="col-sm-12 form-group position-relative">
                                          <label htmlFor="address">
                                            Address
                                          </label>
                                          <textarea
                                            className={`form-control ${
                                              this.state.formErrors
                                                .addressError === true
                                                ? "is-invalid"
                                                : ""
                                            } ${
                                              this.state.formErrors
                                                .addressError === false
                                                ? ""
                                                : ""
                                            }`}
                                            id="userAddress"
                                            placeholder="Address"
                                            value={this.state.userAddress}
                                            onChange={this.handleInputChange}
                                          />
                                          {this.state.formErrors
                                            .addressError && (
                                            <div className="invalid-tooltip">
                                              {constants.FORM.MANDATORY_FIELD}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className={"row"}>
                                        <div className="col-sm-12 form-group position-relative">
                                          <label htmlFor="description">
                                            User Description
                                          </label>
                                          <textarea
                                            className={`form-control ${
                                              this.state.formErrors
                                                .descriptionError === true
                                                ? "is-invalid"
                                                : ""
                                            } ${
                                              this.state.formErrors
                                                .descriptionError === false
                                                ? ""
                                                : ""
                                            }`}
                                            id="userDescription"
                                            placeholder="Description"
                                            value={this.state.userDescription}
                                            onChange={this.handleInputChange}
                                          />
                                          {this.state.formErrors
                                            .descriptionError && (
                                            <div className="invalid-tooltip">
                                              {constants.FORM.MANDATORY_FIELD}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {selectedRole &&
                                  selectedRole !==
                                    constants.USER_ROLES.EDITOR.SLUG &&
                                  selectedRole !==
                                    constants.USER_ROLES.ADMIN.SLUG && (
                                    <>
                                      <div className="card-body-header">
                                        <h5 className="mb-0 font-weight-semibold">
                                          LORs{" "}
                                          <span className={"mandatory"}>*</span>
                                        </h5>
                                      </div>
                                      <div className="form-field-wrapper">
                                        <div className="row">
                                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                                            <div className={"row"}>
                                              <div className="col-lg-6 col-sm-6 mb-2 position-relative">
                                                <label htmlFor="segments">
                                                  Segments
                                                </label>
                                                <div className="custom-combobox-list">
                                                  <CustomDropdownList
                                                    data={segments}
                                                    handleChange={
                                                      this.handleSegmentChange
                                                    }
                                                    placeholder={
                                                      "Select Segment"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-6 col-sm-6 mb-2 position-relative">
                                                <label htmlFor="companies">
                                                  Company
                                                  <span
                                                    style={{
                                                      marginLeft: "25px",
                                                    }}
                                                  >
                                                    <input
                                                      disabled={
                                                        !segmentSelected
                                                      }
                                                      className="form-check-input custom-checkbox-new"
                                                      type="checkbox"
                                                      id="isAllCompany"
                                                      value="1"
                                                      checked={
                                                        this.state.isAllCompany
                                                      }
                                                      onChange={
                                                        this
                                                          .handleCompanyCheckboxChange
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label ml-1"
                                                      htmlFor="isAllCompany"
                                                    >
                                                      All
                                                    </label>
                                                  </span>
                                                </label>
                                                <div className="custom-multiselect">
                                                  <CustomMultiSelect
                                                    disabled={
                                                      !segmentSelected ||
                                                      this.state.isAllCompany
                                                    }
                                                    data={companies}
                                                    handleChange={
                                                      this.handleCompanyChange
                                                    }
                                                    placeholder={
                                                      "Select Companies"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className={"row"}>
                                              <div className="col-sm-12 text-right">
                                                <button
                                                  type="button"
                                                  className="btn btn-md btn-primary modal-btn"
                                                  onClick={
                                                    this.handleAddSegmentCompany
                                                  }
                                                >
                                                  Add
                                                </button>
                                                <div className="clearfix">
                                                  {this.state.isFormSubmitted &&
                                                    this.state.segmentCompanies
                                                      .length === 0 && (
                                                      <div className="empty-segments">
                                                        {
                                                          constants.CLIENT
                                                            .CREATE
                                                            .SEGMENTS_MANDATORY
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                                              <div className="responsive-table">
                                                <table
                                                  className="table table-md"
                                                  cellSpacing="0"
                                                  width="100%"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>Segments</th>
                                                      <th>Company</th>
                                                      <th></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.segmentCompanies.map(
                                                      (seg) => (
                                                        <tr key={seg.uid}>
                                                          <td>{seg.name}</td>
                                                          <td>
                                                            {seg.companies.map(
                                                              (com) => (
                                                                <span
                                                                  key={`${seg.uid}_${com.id}`}
                                                                  className={
                                                                    "segment-tag align-items-center"
                                                                  }
                                                                >
                                                                  {com.name}
                                                                  {com.id ===
                                                                    "all" ||
                                                                  com.id ===
                                                                    "-" ? null : (
                                                                    <span
                                                                      className="material-icons-round icon-fs-16 text-grey mt-2px ml-2 cursor-pointer"
                                                                      onClick={() => {
                                                                        this.removeCompanyConfirmation(
                                                                          seg.uid,
                                                                          com.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      clear
                                                                    </span>
                                                                  )}
                                                                </span>
                                                              )
                                                            )}
                                                          </td>
                                                          <td className="text-center">
                                                            <span
                                                              className="material-icons-outlined text-grey icon-fs-20 cursor-pointer"
                                                              onClick={() => {
                                                                this.removeSegmentConfirmation(
                                                                  seg.uid
                                                                );
                                                              }}
                                                            >
                                                              delete
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                    {this.state.segmentCompanies
                                                      .length === 0 && (
                                                      <tr>
                                                        <td
                                                          className={
                                                            "text-center"
                                                          }
                                                          colSpan={3}
                                                        >
                                                          {
                                                            constants.CLIENT
                                                              .CREATE
                                                              .NO_COMPANY_SEGMENTS_SELECTED
                                                          }
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </>
                            )}
                        </TabPane>
                        <TabPane tabId="permissions">
                          {this.state.modifiedValueType &&
                            this.state.modifiedValueType === "permissions" && (
                              <>
                                <div className="card-body-header">
                                  <h5 className="mb-0 font-weight-semibold">
                                    Manage Permissions
                                  </h5>
                                </div>
                                <div className="form-field-wrapper">
                                  <div className="row">
                                    <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                                      <div className={""}>
                                        <div
                                          className={`user-permissions m-auto`}
                                        >
                                          {this.state.totalPermissions.map(
                                            (p) => (
                                              <div className="card mb-3 user-permissions-card">
                                                <div className="card-body">
                                                  <div
                                                    className={`user-permissions-module`}
                                                  >
                                                    <h2 className="font-weight-semibold text-ligntblue2">
                                                      {p.module}
                                                    </h2>
                                                    <UserPermissions
                                                      module={p.module}
                                                      permissions={
                                                        p.permissions
                                                      }
                                                      handleCheckboxChange={
                                                        this
                                                          .handleCheckboxChangePermissions
                                                      }
                                                      selectedPermissions={
                                                        this.state
                                                          .selectedPermissions
                                                      }
                                                    />
                                                    {p.sub_modules &&
                                                      p.sub_modules.length >
                                                        0 && (
                                                        <>
                                                          {p.sub_modules.map(
                                                            (s) => (
                                                              <div
                                                                className={`p-3`}
                                                              >
                                                                <h3 className="font-weight-semibold text-ligntblue2">
                                                                  {s.module}
                                                                </h3>
                                                                <UserPermissions
                                                                  module={
                                                                    s.module
                                                                  }
                                                                  parentModule={
                                                                    p.module
                                                                  }
                                                                  permissions={
                                                                    s.permissions
                                                                  }
                                                                  handleCheckboxChange={
                                                                    this
                                                                      .handleCheckboxChangePermissions
                                                                  }
                                                                  selectedPermissions={
                                                                    this.state
                                                                      .selectedPermissions
                                                                  }
                                                                />
                                                              </div>
                                                            )
                                                          )}
                                                        </>
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                        </TabPane>
                      </TabContent>

                      <div className="form-submit-buttons">
                        <div className={"row"}>
                          <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                            <div className="text-right form-buttons">
                              <button
                                type="button"
                                className="btn btn-outline-primary modal-btn"
                                onClick={this.resetForm}
                              >
                                Reset
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary modal-btn"
                                onClick={this.handleSubmit}
                              >
                                {this.props.match.params.id ? `Update` : `Save`}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />
        </div>
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    company_details: state.company_details,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    companies: (payload) => {
      dispatch(companies(payload));
    },
    setUserRoles: (payload) => {
      dispatch(userRoles(payload));
    },
    setUserDesignation: (payload) => {
      dispatch(userDesignation(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdminUser);
