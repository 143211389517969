import React, { Component } from "react";
import constants from "../Utils/constants";
import { ClientServices } from "../Services/Client";
import { CompanyServices } from "../Services/Company";
import { MasterServices } from "../Services/Master";
import { formatRoute } from "react-router-named-routes";
import Notification from "../Components/Notification";
import CustomDropdownList from "../Components/CustomDropdownList";
import CustomMultiSelect from "../Components/CustomMultiSelect";
import { connect } from "react-redux";
import { getCurrencies, segments } from "../Store/Actions/User";
import DailogNew from "../Components/DailogNew";
import Toast from "../Utils/Toast";
import DocumentTitle from "../Utils/DocumentTitle";
import scrollIntoErrors from "../Components/errorScrolling";
import AclAction from "../Components/ACL/AclAction";
import { isEmpty } from "lodash";

const initialState = {
  companyName: "",
  displayName: "",
  stockTicker: "",
  type: null,
  fiscalQuarterOffset: null,
  currency: null,
  status: null,
  formErrors: {},
  typeDropdown: [
    {
      id: "Public",
      name: "Public",
      value: "Public",
      is_selected: true,
    },
    {
      id: "Private",
      name: "Private",
      value: "Private",
      is_selected: false,
    },
  ],
  statusDropdown: [
    {
      id: 1,
      name: "Active",
      value: 1,
      is_selected: true,
    },
    {
      id: 0,
      name: "Inactive",
      value: 0,
      is_selected: false,
    },
  ],
  selectedSegments: [],
  fiscalQuarterOffsetDropdown: [],
  dailogModal: false,
  dailogModalContent: "",
  dailogModalHeader: "",
  dailogModalConfig: {},
  dailogModalStyleType: "",
  isFormSubmitted: false,
  apiError: null,
  showDeleteButton: false,
};
class ManageCompany extends Component {
  state = { ...initialState };
  async componentDidMount() {
    this.getCurrencies();
    this.getSegments();
    this.getFiscalQuarterOffsetDropdownOptions();
  }
  getCurrencies = () => {
    MasterServices.getCurrencies()
      .then((res) => {
        let response =
          res && res.data && res.data.data && res.data.data.currencies;
        response.forEach((r) => {
          r.is_selected = false;
        });
        this.props.setCurrencies(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getSegments = () => {
    let companyId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    ClientServices.getSegmentDetails(1)
      .then((res) => {
        let response = res && res.data && res.data.data;
        response.forEach((r) => {
          r.is_selected = false;
        });
        this.props.setSegments(response);
        if (companyId !== null) this.prepopulateData(companyId);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  getFiscalQuarterOffsetDropdownOptions = () => {
    let companyId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let fiscalQuarterOffsetDropdownValues = [-4, -3, -2, -1, 0, 1, 2, 3, 4];
    let fiscalQuarterOffsetDropdown = fiscalQuarterOffsetDropdownValues?.map(
      (f) => ({ id: f, name: f, value: f, is_selected: false })
    );
    this.setState(
      {
        companyId: companyId,
        fiscalQuarterOffsetDropdown: fiscalQuarterOffsetDropdown,
      },
      () => {}
    );
  };
  prepopulateData = (companyId) => {
    CompanyServices.getCompanyDetails(companyId)
      .then((res) => {
        let response = res?.data?.company;
        let admin_role_id = this.props.home.userRoles?.filter(
          (role) => role.short_name === "admin"
        )[0].id;
        this.setState(
          {
            companyName: response?.original_name ? response?.original_name : "",
            displayName: response?.name ? response?.name : "",
            stockTicker: response?.stock_ticker ? response?.stock_ticker : "",
            fiscalQuarterOffset: response?.fiscal_quarter_offset,
            type: response?.type ? response?.type : null,
            status: response?.is_active === 1 ? response?.is_active : 0,
            currency: response?.currency_id ? response?.currency_id : null,
            selectedSegments:
              response?.segments &&
              response?.segments !== "" &&
              response?.segments.length > 0
                ? response?.segments?.map((a) => {
                    return {
                      id: a.id,
                      is_active: a.company_segment_is_active,
                      name: a.name,
                      coverage: a.company_segment_coverage_type || "",
                    };
                  })
                : [],
            isFormSubmitted: false,
            apiError: null,
            showDeleteButton:
              (res?.data?.published_count === 0 &&
                res?.data?.company?.created_by ===
                  this.props?.home?.loginUserSuccess.id) ||
              this.props.home?.loginUserSuccess?.role_id === admin_role_id,
          },
          () => {
            if (this.state.currency)
              this.handleCurrencyChange({ id: this.state.currency });
            this.handleFiscalQuarterOffsetChange({
              id: this.state.fiscalQuarterOffset,
            });
            if (this.state.type) this.handleTypeChange({ id: this.state.type });
            this.handleStatusChange({ id: this.state.status });
          }
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleTypeChange = (e) => {
    let types = [...this.state.typeDropdown];
    types.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.setState(
      {
        types: types,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleStatusChange = (e) => {
    let types = [...this.state.statusDropdown];
    types.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.setState(
      {
        statusDropdown: types,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleCurrencyChange = (e) => {
    let currencies = [...this.props.home.getCurrencies];
    console.log("🚀 ~ ManageCompany ~ currencies:", currencies);
    currencies.forEach((c) => {
      console.log("🚀 ~ ManageCompany ~ currencies.forEach ~ c:", c);
      c.is_selected = c.id === e.id;
    });
    this.props.setCurrencies(currencies);
    if (this.state.isFormSubmitted === true) this.isFormValid();
  };
  handleFiscalQuarterOffsetChange = (e) => {
    let fiscalQuarterOffset = [...this.state.fiscalQuarterOffsetDropdown];
    fiscalQuarterOffset.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.setState(
      {
        fiscalQuarterOffsetDropdown: fiscalQuarterOffset,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  handleSegmentsChange = (e) => {
    let selectedSegments = e.map((a) => a.id);
    let segments = [...this.props.home.segments];
    segments.forEach((c) => {
      c.is_selected = selectedSegments.includes(c.id);
    });
    this.props.setSegments(segments);
    if (this.state.isFormSubmitted === true) this.isFormValid();
  };
  handleInputChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.id]: e.target.value,
      },
      () => {
        if (this.state.isFormSubmitted === true) this.isFormValid();
      }
    );
  };
  isFormValid = () => {
    let errorsList = [];
    let formError = false;
    let companyNameError = false;
    let displayNameError = false;
    let stockTickerError = false;
    let typeError = false;
    let fiscalQuarterOffsetError = false;
    let currencyError = false;
    let statusError = false;
    let segmentsError = false;
    let coverageError = false;
    if (isEmpty(this.state.companyName.trim())) {
      companyNameError = true;
      formError = true;
      errorsList.push("companyName");
    }

    if (isEmpty(this.state.displayName.trim())) {
      displayNameError = true;
      formError = true;
      errorsList.push("displayName");
    }
    if (isEmpty(this.state.stockTicker.trim())) {
      stockTickerError = true;
      formError = true;
      errorsList.push("stockTicker");
    }
    if (!this.state.typeDropdown.find((t) => t.is_selected)) {
      typeError = true;
      formError = true;
    }
    if (!this.state.fiscalQuarterOffsetDropdown.find((f) => f.is_selected)) {
      fiscalQuarterOffsetError = true;
      formError = true;
      errorsList.push("fiscalQuarterOffset");
    }
    if (!this.props.home.getCurrencies.find((c) => c.is_selected)) {
      currencyError = true;
      formError = true;
      errorsList.push("currency");
    }
    if (this.state.selectedSegments.length === 0) {
      segmentsError = true;
      formError = true;
      errorsList.push("segments Error");
    }
    if (!this.state.statusDropdown.find((c) => c.is_selected)) {
      statusError = true;
      formError = true;
    }
    for (let i = 0; i < this.state.selectedSegments.length; i++) {
      if (!this.state.selectedSegments[i].coverage) {
        coverageError = true;
        formError = true;
        break;
      }
    }

    scrollIntoErrors(errorsList);
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        companyNameError: companyNameError,
        displayNameError: displayNameError,
        typeError: typeError,
        stockTickerError: stockTickerError,
        fiscalQuarterOffsetError: fiscalQuarterOffsetError,
        currencyError: currencyError,
        statusError: statusError,
        segmentsError: segmentsError,
        coverageError: coverageError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = () => {
    this.setState(
      {
        isFormSubmitted: true,
      },
      () => {
        if (!this.isFormValid()) {
          let companyId = this.props.match.params.id
            ? this.props.match.params.id
            : null;
          let prepareObject = {
            name: this.state.displayName,
            original_name: this.state.companyName,
            stock_ticker: this.state.stockTicker,
            type: this.state.typeDropdown.find((t) => t.is_selected)
              ? this.state.typeDropdown.find((t) => t.is_selected).id
              : null,
            currency_id: this.props.home.getCurrencies.find(
              (t) => t.is_selected
            )
              ? this.props.home.getCurrencies.find((t) => t.is_selected).id
              : null,
            is_active: this.state.statusDropdown.find((t) => t.is_selected)
              ? this.state.statusDropdown.find((t) => t.is_selected).id
              : null,
            fiscal_quarter_offset: this.state.fiscalQuarterOffsetDropdown.find(
              (t) => t.is_selected
            )
              ? this.state.fiscalQuarterOffsetDropdown.find(
                  (t) => t.is_selected
                ).id
              : null,
            LORS: this.state.selectedSegments,
          };
          if (companyId === null) {
            //create
            CompanyServices.addCompany(prepareObject)
              .then((res) => {
                this.saveCompanySuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          } else {
            CompanyServices.editCompany(companyId, prepareObject)
              .then((res) => {
                this.saveCompanySuccess();
              })
              .catch((error) => {
                this.setState({
                  apiError:
                    error && error.data && error.data.error
                      ? error.data.error
                      : constants.ERROR.SOMETHING_WENT_WRONG,
                });
              });
          }
        }
      }
    );
  };
  saveCompanySuccess = () => {
    let companyId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    Toast(
      companyId === null
        ? constants.COMPANY.CREATE.SUCCESS
        : constants.COMPANY.EDIT.SUCCESS,
      "success"
    );
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
    });
  };
  dailogModalAccept = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
    this.removeCompany();
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
    });
  };
  resetForm = () => {
    let companyId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        companyNameError: false,
        displayNameError: false,
        typeError: false,
        stockTickerError: false,
        fiscalQuarterOffsetError: false,
        currencyError: false,
        statusError: false,
        segmentsError: false,
      },
      apiError: null,
    });
    if (companyId !== null) {
      this.prepopulateData(companyId);
    } else {
      this.setState(
        {
          ...initialState,
          selectedSegments: [],
        },
        () => {
          this.getCurrencies();
          this.getSegments();
          this.getFiscalQuarterOffsetDropdownOptions();
          this.setState({
            selectedSegments: [],
          });
        }
      );
    }
  };
  breadcrumbs = () => {
    let companyId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY_GROUP.NAME,
        link: null,
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: companyId
          ? constants.APPLICATION_ROUTE.COMPANY.EDIT.NAME
          : constants.APPLICATION_ROUTE.COMPANY.CREATE.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  addSegments = () => {
    let isValid = false;
    let selectedSegments = [...this.state.selectedSegments];
    this.props.home.segments
      .filter((p) => p.is_selected)
      .forEach((seg) => {
        if (!selectedSegments.find((s) => s.id === seg.id)) {
          selectedSegments.push({ id: seg.id, name: seg.name, is_active: 1 });
          isValid = true;
        }
      });
    if (isValid) {
      this.props.home.segments.forEach((b) => (b.is_selected = false));
      this.setState(
        {
          selectedSegments: selectedSegments,
        },
        () => {
          if (this.state.isFormSubmitted === true) this.isFormValid();
        }
      );
    }
  };

  removeSegment = (id) => {
    let selectedSegments = this.state.selectedSegments.filter(
      (s) => s.id !== id
    );
    this.setState({
      selectedSegments: selectedSegments,
    });
  };

  toggleSwitch = (e, id) => {
    let selectedSegments = this.state.selectedSegments;
    let selectedSegment = selectedSegments.findIndex((s) => s.id === id);
    selectedSegments[selectedSegment].is_active =
      selectedSegments[selectedSegment].is_active === 1 ? 0 : 1;
    this.setState({
      selectedSegments: selectedSegments,
    });
  };
  toggleChange = (e, id) => {
    let selectedSegments = this.state.selectedSegments;
    let selectedSegment = selectedSegments.findIndex((s) => s.id === id);
    selectedSegments[selectedSegment].coverage = e.target.value;
    this.setState({
      selectedSegments: selectedSegments,
    });
  };
  changeRoute = () => {
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE),
    });
  };

  removeCompanyConfirmationHandler = () => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.COMPANY.REMOVE_COMPANY_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_company" },
      dailogModalStyleType: "danger-modal",
    });
  };

  removeCompany = () => {
    CompanyServices.removeCompany(this.props.match.params.id)
      .then((res) => {
        Toast(constants.COMPANY.REMOVE.SUCCESS, "success");
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE,
            {}
          ),
        });
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  render() {
    let companyId = this.props.match.params.id
      ? this.props.match.params.id
      : null;
    let currencies =
      this.props.home && this.props.home.getCurrencies
        ? this.props.home.getCurrencies
        : [];
    let segments =
      this.props.home && this.props.home.segments
        ? this.props.home.segments
        : [];
    let validSegmentMapping =
      this.state.selectedSegments &&
      segments.find((c) => c.is_selected === true);

    DocumentTitle(
      companyId === null
        ? constants.APPLICATION_ROUTE.COMPANY.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.COMPANY.EDIT.HEADER_TITLE
    );
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-2 br_8 main_content_height">
          <div className="manage-client">
            <div className="">
              <div className={"row col-lg-12 col-sm-12 col-md-12 col-12 mx-0"}>
                <form className={"col-lg-12 col-sm-12 form-wrapper"}>
                  {this.state.apiError !== null && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiError}
                    />
                  )}
                  <div className="card border-0">
                    <div className="card-header bg-transparent title-cancel-header d-flex align-items-center">
                      <h3 className="text-darkblue2 mb-0 font-weight-bold">
                        {companyId === null
                          ? constants.APPLICATION_ROUTE.COMPANY.CREATE
                              .HEADER_TITLE
                          : constants.APPLICATION_ROUTE.COMPANY.EDIT
                              .HEADER_TITLE}
                      </h3>
                      <div className="ml-auto manage-delete-button">
                        {this.state.showDeleteButton ? (
                          <button
                            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                            type="button"
                            className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                            onClick={this.removeCompanyConfirmationHandler}
                          >
                            <span className="mt-2px">Delete Company</span>
                            <span className="material-icons icon-fs-16 ml-1">
                              delete
                            </span>
                          </button>
                        ) : (
                          this.props.match.params.id && (
                            <AclAction
                              type={constants.ACL.PERMISSION_KEY}
                              entity={constants.ACL.COMPANY_RESOURCE.TITLE}
                              action={
                                constants.ACL.COMPANY_RESOURCE.ACTIONS.DELETE
                              }
                            >
                              <button
                                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                                type="button"
                                className="btn btn-custom btn-danger btn-no-background icon-text-btn title-btn d-inline-flex align-items-center px-2 font-weight-semibold"
                                onClick={this.removeCompanyConfirmationHandler}
                              >
                                <span className="mt-2px">Delete Company</span>
                                <span className="material-icons icon-fs-16 ml-1">
                                  delete
                                </span>
                              </button>
                            </AclAction>
                          )
                        )}
                      </div>
                      <span
                        className="material-icons-round text-darkblue2 ml-4 cursor-pointer"
                        onClick={this.changeRoute}
                      >
                        close
                      </span>
                    </div>
                    {currencies.length > 0 && segments.length > 0 && (
                      <div className="card-body">
                        <div className="card-body-header">
                          <h5 className="mb-0 font-weight-semibold">
                            Company Information
                          </h5>
                        </div>
                        <div className="form-field-wrapper">
                          <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                              <div className={"row"}>
                                <div className="col-sm-6 form-group position-relative">
                                  <label htmlFor="companyName">
                                    Name <span className={"mandatory"}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      this.state.formErrors.companyNameError ===
                                      true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors.companyNameError ===
                                      false
                                        ? ""
                                        : ""
                                    }`}
                                    id="companyName"
                                    placeholder="Name"
                                    value={this.state.companyName}
                                    onChange={this.handleInputChange}
                                  />
                                  {this.state.formErrors.companyNameError && (
                                    <div className="invalid-tooltip">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-sm-6 form-group position-relative">
                                  <label htmlFor="companyName">
                                    Display Name{" "}
                                    <span className={"mandatory"}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      this.state.formErrors.displayNameError ===
                                      true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors.displayNameError ===
                                      false
                                        ? ""
                                        : ""
                                    }`}
                                    id="displayName"
                                    placeholder="Display Name"
                                    value={this.state.displayName}
                                    onChange={this.handleInputChange}
                                  />
                                  {this.state.formErrors.displayNameError && (
                                    <div className="invalid-tooltip">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-sm-6 form-group position-relative">
                                  <label htmlFor="companyName">
                                    Symbol{" "}
                                    <span className={"mandatory"}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      this.state.formErrors.stockTickerError ===
                                      true
                                        ? "is-invalid"
                                        : ""
                                    } ${
                                      this.state.formErrors.stockTickerError ===
                                      false
                                        ? ""
                                        : ""
                                    }`}
                                    id="stockTicker"
                                    placeholder="Symbol"
                                    value={this.state.stockTicker}
                                    onChange={this.handleInputChange}
                                  />
                                  {this.state.formErrors.stockTickerError && (
                                    <div className="invalid-tooltip">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="companies">
                                    Type <span className={"mandatory"}>* </span>
                                  </label>
                                  <div
                                    className={`${
                                      this.state.formErrors.typeError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={this.state.typeDropdown}
                                      handleChange={this.handleTypeChange}
                                      placeholder={"Select Type"}
                                    />
                                  </div>
                                  {this.state.formErrors.typeError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="companies">
                                    Fiscal Quarter Offset{" "}
                                    <span className={"mandatory"}>* </span>
                                  </label>
                                  <div id="fiscalQuarterOffset">
                                    <div
                                      className={`${
                                        this.state.formErrors
                                          .fiscalQuarterOffsetError
                                          ? "is-invalid"
                                          : ""
                                      } custom-combobox-list`}
                                    >
                                      <CustomDropdownList
                                        data={
                                          this.state.fiscalQuarterOffsetDropdown
                                        }
                                        handleChange={
                                          this.handleFiscalQuarterOffsetChange
                                        }
                                        placeholder={
                                          "Select Fiscal Quarter Offset"
                                        }
                                      />
                                    </div>
                                    {this.state.formErrors
                                      .fiscalQuarterOffsetError && (
                                      <div className="invalid-tooltip d-block">
                                        {constants.FORM.MANDATORY_FIELD}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="companies">
                                    Currency{" "}
                                    <span className={"mandatory"}>* </span>
                                  </label>
                                  <div id="currency">
                                    <div
                                      className={`${
                                        this.state.formErrors.currencyError
                                          ? "is-invalid"
                                          : ""
                                      } custom-combobox-list`}
                                    >
                                      <CustomDropdownList
                                        data={currencies}
                                        handleChange={this.handleCurrencyChange}
                                        placeholder={"Select Currency"}
                                      />
                                    </div>
                                    {this.state.formErrors.currencyError && (
                                      <div className="invalid-tooltip d-block">
                                        {constants.FORM.MANDATORY_FIELD}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 form-group position-relative">
                                  <label for="status">
                                    Status{" "}
                                    <span className={"mandatory"}>* </span>
                                  </label>
                                  <div
                                    className={`${
                                      this.state.formErrors.statusError
                                        ? "is-invalid"
                                        : ""
                                    } custom-combobox-list`}
                                  >
                                    <CustomDropdownList
                                      data={this.state.statusDropdown}
                                      handleChange={this.handleStatusChange}
                                      placeholder={"Select Status"}
                                    />
                                  </div>
                                  {this.state.formErrors.statusError && (
                                    <div className="invalid-tooltip d-block">
                                      {constants.FORM.MANDATORY_FIELD}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body-header">
                          <h5 className="mb-0 font-weight-semibold">
                            Manage LORs
                          </h5>
                        </div>
                        <div className="form-field-wrapper">
                          <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                              <div className="row">
                                {segments.length > 0 && (
                                  <div className="col-sm-12 mb-2 position-relative">
                                    <label for="LORs">
                                      LORs{" "}
                                      <span className={"mandatory"}>*</span>
                                    </label>
                                    <div id="lor">
                                      <div
                                        className={`${
                                          this.state.formErrors.segmentsError
                                            ? "is-invalid"
                                            : ""
                                        } custom-multiselect`}
                                      >
                                        <CustomMultiSelect
                                          data={segments}
                                          handleChange={
                                            this.handleSegmentsChange
                                          }
                                          placeholder={"Select LORs"}
                                        />
                                      </div>
                                      {this.state.formErrors.segmentsError && (
                                        <div
                                          className="invalid-tooltip"
                                          style={{ display: "block" }}
                                        >
                                          Please add LORs
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <div className="col-sm-12 text-right">
                                  <button
                                    disabled={!validSegmentMapping}
                                    type="button"
                                    className="btn btn-md btn-primary modal-btn"
                                    onClick={this.addSegments}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              <div className="custom-table-block custom-table-block-2 nowrap-table-block th-nowrap center-th-align my-4">
                                <div className="responsive-table">
                                  <table
                                    className="table table-md"
                                    cellSpacing="0"
                                    width="100%"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Segment</th>
                                        <th>Status</th>
                                        <th>Coverage By</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.selectedSegments &&
                                        this.state.selectedSegments.map(
                                          (seg) => (
                                            <tr key={seg.id}>
                                              <td>{seg.name}</td>
                                              <td>
                                                <div className="custom-control custom-switch success-toggle">
                                                  <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`customSwitch${seg.id}`}
                                                    checked={
                                                      seg.is_active === 1
                                                    }
                                                    onChange={(e) => {
                                                      e.stopPropagation();
                                                      this.toggleSwitch(
                                                        e,
                                                        seg.id
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    for={`customSwitch${seg.id}`}
                                                  ></label>
                                                </div>
                                              </td>
                                              <td>
                                                <div>
                                                  <div
                                                    className={`{!seg.coverage?.length ? "selectError" : ""} custom-select-new custom-select-dropdown d-inline-block`}
                                                  >
                                                    <select
                                                      name="coverageby"
                                                      className={
                                                        !seg.coverage?.length
                                                          ? "selectError"
                                                          : ""
                                                      }
                                                      onChange={(e) => {
                                                        e.stopPropagation();
                                                        this.toggleChange(
                                                          e,
                                                          seg.id
                                                        );
                                                      }}
                                                    >
                                                      <option
                                                        value="none"
                                                        selected
                                                        disabled
                                                        hidden
                                                      >
                                                        Select an Option
                                                      </option>
                                                      <option
                                                        value="research_team"
                                                        selected={
                                                          seg.coverage ===
                                                          "research_team"
                                                        }
                                                      >
                                                        Company Snapshot
                                                      </option>
                                                      <option
                                                        value="analyst_team"
                                                        selected={
                                                          seg.coverage ===
                                                          "analyst_team"
                                                        }
                                                      >
                                                        Company Profile{" "}
                                                      </option>
                                                      <option
                                                        value="dedicated_analyst"
                                                        selected={
                                                          seg.coverage ===
                                                          "dedicated_analyst"
                                                        }
                                                      >
                                                        {" "}
                                                        Company Research{" "}
                                                      </option>
                                                      <option
                                                        value="benchmark_coverage"
                                                        selected={
                                                          seg.coverage ===
                                                          "benchmark_coverage"
                                                        }
                                                      >
                                                        {" "}
                                                        Benchmark Coverage{" "}
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <span
                                                  className="material-icons-outlined text-grey icon-fs-18 text-right cursor-pointer d-block"
                                                  onClick={() =>
                                                    this.removeSegment(seg.id)
                                                  }
                                                >
                                                  delete
                                                </span>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      {this.state.selectedSegments.length ===
                                        0 && (
                                        <tr>
                                          <td
                                            className={"text-center"}
                                            colSpan={3}
                                          >
                                            {
                                              constants.CLIENT.CREATE
                                                .NO_COMPANY_SEGMENTS_SELECTED
                                            }
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-submit-buttons">
                          <div className={"row"}>
                            <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                              <div className="text-right form-buttons">
                                <button
                                  type="button"
                                  className="btn btn-outline-primary modal-btn"
                                  onClick={this.resetForm}
                                >
                                  Reset
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary modal-btn"
                                  onClick={this.handleSubmit}
                                >
                                  {this.props.match.params.id
                                    ? `Update`
                                    : `Save`}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <DailogNew
          isOpen={this.state.dailogModal}
          accept={this.dailogModalAccept}
          decline={this.dailogModalDecline}
          header={this.state.dailogModalHeader}
          content={this.state.dailogModalContent}
          config={this.state.dailogModalConfig}
          modalStyleType={this.state.dailogModalStyleType}
        />
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSegments: (segmentsList) => {
      dispatch(segments(segmentsList));
    },
    setCurrencies: (currenciesList) => {
      dispatch(getCurrencies(currenciesList));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCompany);
