import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Notification from "../Components/Notification";
import logo from "../Images/INSIGHTCENTER@2x.png";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import { formatRoute } from "react-router-named-routes";
import constants from "../Utils/constants";
import { useHistory } from "react-router-dom";
import { UserServices } from "../Services/User";

import {
  loginUserSuccess,
  openSearchBar,
  userRoles,
} from "../Store/Actions/User";
import decodeJWT from "../lib/decodeJWT";

import { useDispatch } from "react-redux";

const SingleSignOn = () => {
  const history = useHistory();
  const [apiErrors, setApiErrors] = useState("");
  const [email, setEmail] = useState("");
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const paramEmail = params.get("email");
  const error_description = params.get("error_description");
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleUserSingleSignOn = async (e = false) => {
    if (e) {
      e.preventDefault();
    }
    try {
      if (email || paramEmail) {
        let input = {
          email: email || paramEmail,
          redirect_uri: window.location.origin + "/sso-login",
        };
        let res = await UserServices.userSingleSignOn(input);
        if (res) {
          if (res.data.redirect_url) {
            localStorage.setItem("samlEmail", email || paramEmail);
            window.location.href = res.data.redirect_url;
          }
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleUserSingleSignOn ~ error:", error);
      setApiErrors(error.data.error);
    }
  };

  const getUserInfo = async (body) => {
    try {
      const data = await UserServices.getUserLogin(body);
      if (body) {
        localStorage.setItem("SSO", false);
      }
      localStorage.setItem("token", data.data.access_token);
      localStorage.setItem("refresh_token", data.data.refresh_token);
      localStorage.setItem(
        "is_admin",
        decodeJWT(localStorage.getItem("token")).user.is_admin
      );
      dispatch(loginUserSuccess(decodeJWT(localStorage.getItem("token")).user));
      dispatch(userRoles(decodeJWT(localStorage.getItem("token"))?.AdminRoles));
      localStorage.removeItem("samlEmail");
      const searchParams = new URLSearchParams(document.location.search);
      if (searchParams.get("redirect_url")) {
        window.location.href = searchParams.get("redirect_url");
      } else {
        history.push(
          formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {})
        );
      }
    } catch (error) {
      console.log("Error Login: ", error);
      localStorage.removeItem("SSO");
      localStorage.removeItem("samlEmail");

      setApiErrors(
        error && error.data && error.data.error_detail
          ? error.data.error_detail
          : error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG
      );
      history.push(constants.APPLICATION_ROUTE.SSO.ROUTE);
    }
  };

  const handleCallback = async () => {
    // Extract query parameters from the URL
    // Get the 'code' parameter from the URL (used for Google OAuth flow)

    if (code) {
      // Close the search bar in the UI
      openSearchBar(false);
      // Fetch and store user information using the data received from the backend
      let input = {
        code,
        redirect_uri: window.location.origin + "/sso-login",
        samlEmail: localStorage.getItem("samlEmail"),
      };
      await getUserInfo(input);
    }
    if (error_description) {
      setApiErrors(error_description);
      history.push(constants.APPLICATION_ROUTE.SSO_LOGIN.ROUTE);
    }
  };

  useEffect(() => {
    handleCallback();
  }, [code]);
  return (
    <div className="wrapper login_page">
      <main>
        <Container fluid>
          <Row>
            <aside className="col-md-6 px-0 hide-in-mobille">
              <div className="login_left">
                <figure className="login_banner">
                  <img
                    src={login_building}
                    className="img-fluid"
                    alt="login banner"
                    title="login banner"
                  />
                </figure>
                <a href="/">
                  <img
                    style={{ width: "120px", marginLeft: "20px" }}
                    src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                    alt="TBR logo"
                  />
                </a>
              </div>
            </aside>
            <aside className="col-md-6 px-0">
              <div className="login_right">
                <figure>
                  <img src={login_map} className="img-fluid" alt="map Banner" />
                </figure>
                <Form>
                  <span className="login_formlogos">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </span>
                  <>
                    <span className="login_text_span">Single sign-on</span>
                    {apiErrors.length > 0 && (
                      <Notification
                        color={"danger"}
                        message={apiErrors}
                        className="mt-2"
                      />
                    )}
                    <FormGroup
                      style={{ marginBottom: "35px", marginTop: "30px" }}
                    >
                      <Label htmlFor="loginEmail">Email</Label>
                      <Input
                        type="email"
                        placeholder="Work Email"
                        id="loginEmail"
                        name="loginEmail"
                        onChange={handleInputChange}
                        // onChange={this.handleInputChange}
                      />
                      <span
                        className="fs-12 mt-2 d-flex justify-content-start"
                        style={{ color: "#BED4EB" }}
                      >
                        Use an organization email to easily collaborate with
                        teammates.
                      </span>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: "0px" }}>
                      <Button
                        // className={
                        //   this.state.loginEmail && this.state.loginPassword
                        //     ? ""
                        //     : "disabled"
                        // }
                        style={{ minWidth: "100%" }}
                        color="primary"
                        type="submit"
                        disabled={!email.length}
                        onClick={handleUserSingleSignOn}
                      >
                        Submit
                        {/* {verificationCode ? "Submit" : "Get Verification Code"} */}
                      </Button>
                    </FormGroup>

                    <div
                      className="text-center text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <span
                        className="material-icons-outlined d-flex align-items-center mr-2"
                        style={{ fontSize: "16px" }}
                      >
                        arrow_back
                      </span>
                      <span
                        onClick={() =>
                          history.push(
                            formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE)
                          )
                        }
                        className="sso-back-link"
                      >
                        Sign in without SSO
                      </span>
                    </div>
                  </>
                </Form>
                <div className="terms-conditions">
                  <a
                    className="links"
                    href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Terms+of+Service.pdf"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  |
                  <a
                    className="links"
                    href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Privacy+Policy.pdf"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </aside>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default SingleSignOn;
