import React, { useEffect, useRef, useState } from "react";
import constants from "../Utils/constants";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Collapsible from "react-collapsible";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/tooltip";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import CalendarHeader from "../Components/CalendarHeader";
import { ClientServices } from "../Services/Client";
import { PublishedCalendarServices } from "../Services/PublishedCalendar";
import Toast from "../Utils/Toast";
import ReactTooltip from "react-tooltip";
import { useDropzone } from "react-dropzone";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import "../Styles/calendar.scss";
import { BenchmarkServices } from "../Services/Bechmark";
import CustomCloneDeep from "../Utils/CustomCloneDeep";
import { MarketForecastServices } from "../Services/MarketForecast";
import { MarketLandscapeServices } from "../Services/MarketLandscape";
import { CustomerStudyServices } from "../Services/CustomerStudy";
import { EcosystemServices } from "../Services/Ecosystem";
import { SpecialReportServices } from "../Services/SpecialReport";
import { CompanyServices } from "../Services/Company";
import PublishCalendarModalDetail from "../Components/PublishCalendarModalDetail";
import AddEditCalendarModal from "../Components/AddEditCalendarModal";

const viewsForCalendar = {
  timeGridWeek: {
    type: "timeGrid",
    slotDuration: "24:00:00",
    allDaySlot: false,
    expandRows: true,
    scrollTime: "24:00:00",
    eventMaxStack: 10,
  },
  timeGridDay: {
    type: "dayGrid",
    slotDuration: "24:00:00",
    allDaySlot: false,
    expandRows: true,
    scrollTime: "24:00:00",
    eventMaxStack: 10,
  },
};

// Function to convert UTC date to user's local timezone
const convertToLocalTimezone = (utcDateString) => {
  return utcDateString.split("T")[0];
};

const checkboxesArray = [
  {
    id: "check1",
    icon: "domain",
    name: constants.CALENDAR.PUBLISHED_TYPE.COMPANY,
    color: "green",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.COMPANY,
  },
  {
    id: "check2",
    icon: "speeds",
    name: constants.CALENDAR.PUBLISHED_TYPE.BENCHMARK,
    color: "yellow",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.BENCHMARK,
  },
  {
    id: "check3",
    icon: "summarize",
    name: constants.CALENDAR.PUBLISHED_TYPE.SPECIAL_REPORT,
    color: "blue",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.SPECIAL_REPORT,
  },
  {
    id: "check4",
    icon: "trending_up",
    name: constants.CALENDAR.PUBLISHED_TYPE.MARKET_FORECAST,
    color: "skyblue",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.MARKET_FORECAST,
  },
  {
    id: "check5",
    icon: "analytics",
    name: constants.CALENDAR.PUBLISHED_TYPE.MARKET_LANDSCAPE,
    color: "red",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.MARKET_LANDSCAPE,
  },
  {
    id: "check6",
    icon: "person_search",
    name: constants.CALENDAR.PUBLISHED_TYPE.CUSTOMER_STUDIES,
    color: "violet",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.CUSTOMER_STUDIES,
  },
  {
    id: "check7",
    icon: "public",
    name: constants.CALENDAR.PUBLISHED_TYPE.ECOSYSTEM,
    color: "pink",
    is_selected: true,
    slug: constants.CALENDAR.PUBLISHED_SLUG.ECOSYSTEM,
  },
];

const statusTypesArray = [
  {
    id: 1,
    name: constants.CALENDAR.WEBINAR_TYPE.DRAFT,
    slug: constants.CALENDAR.SLUG.DRAFT,
    is_selected: true,
    color: "gray",
  },
  {
    id: 2,
    name: constants.CALENDAR.WEBINAR_TYPE.PUBLISHED,
    slug: constants.CALENDAR.SLUG.PUBLISHED,
    is_selected: true,
    color: "skateblue",
  },
];

const occurenceContentTypes = [
  { id: 1, name: "All", slug: "all", is_selected: true },
  { id: 1, name: "Today", slug: "today", is_selected: false },
  { id: 1, name: "Upcoming", slug: "upcoming", is_selected: false },
  { id: 1, name: "Past", slug: "past", is_selected: false },
];

const reportList = [
  { id: 1, label: "Company Research", value: "company_report" },
  { id: 2, label: "Benchmark", value: "benchmark" },
  { id: 3, label: "Special Report", value: "special_report" },
  { id: 4, label: "Market Forecast", value: "market_forecast" },
  { id: 5, label: "Market Landscape", value: "market_landscape" },
  { id: 6, label: "Customer Study", value: "customer_studies" },
  { id: 7, label: "Ecosystem", value: "ecosystem" },
];

const quaterList = [
  { id: 1, label: "1Q", value: "1Q" },
  { id: 2, label: "2Q", value: "2Q" },
  { id: 3, label: "3Q", value: "3Q" },
  { id: 4, label: "4Q", value: "4Q" },
  { id: 5, label: "1H", value: "1H" },
  { id: 6, label: "2H", value: "2H" },
];

const numWhiteSpaceRegex = /^(\s+|\d+)$/;

const PublishedCalendar = (props) => {
  const [collapseIsOpen1, setCollapseIsOpen1] = useState(true);
  const [collapseIsOpen2, setCollapseIsOpen2] = useState(true);
  const [collapseIsOpen3, setCollapseIsOpen3] = useState(true);
  const [companyCollapse, setCompanyCollapse] = useState(false);
  const [statusType, setStatusTypes] = useState(statusTypesArray);
  const [publishedTypes, setPublishedTypes] = useState(checkboxesArray);
  const [occurenceTypes, setOccurenceTypes] = useState(occurenceContentTypes);
  const [showEventModalIsOpen, setShowEventModalIsOpen] = useState(false);
  const [activeView, setActiveView] = React.useState("dayGridMonth");
  const [allEvents, setAllEvents] = useState([]);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [calendarHeaderDate, setCalendarHeaderDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [segmentList, setSegmentList] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [eventDate, setEventDate] = useState(new Date());
  const [selectedQuater, setSelectedQuater] = useState({});
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState({});
  const [buttonType, setButtonType] = useState("published");
  const [displayReportType, setDisplayReportType] = useState("");
  const [fileUploadMsg, setFileUploadMsg] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isFileNotUploaded, setIsFileNotUploaded] = useState(false);
  const [formErrors, setFormErrors] = useState({
    titleError: false,
    topicError: false,
    reportTypeError: false,
    segmentError: false,
    reportFileError: false,
    quaterError: false,
    yearError: false,
    authorError: false,
    companyError: false,
  });
  const calendarRef = useRef(null);
  const [reportFileList, setReportFileList] = useState([]);
  const [selectedReportFile, setSelectedReportFile] = useState("");
  const [eventStatus, setEventStatus] = useState("");

  const [eventDetails, setEventDetails] = useState({
    title: "",
    reportType: "",
    segment: "",
    fullDate: "",
    company: "",
    reportFile: "",
    quarter: "",
    authors: "",
    eventId: "",
    date: null,
    status: "",
    buttonType: "",
  });

  const [eventFormModel, setEventFormModel] = useState({
    id: null,
    topic: "",
    title: "",
    status: "publish",
    report_type: "company_report",
    segment_id: null,
    company_id: null,
    published_date: null,
    calendar_quarter: "",
    author_id: null,
    created_time: null,
    created_at: null,
    updated_at: null,
    report_id: null,
    deleted_at: null,
    segment: null,
    company: null,
    report_data: null,
  });

  const {
    getRootProps,
    getInputProps,
    _isFocused,
    _isDragAccept,
    _isDragReject,
  } = useDropzone({
    accept: { "text/xlsx": [".csv", ".xlsx", ".xls", ".numbers"] },
    onDrop: (files) => {
      setIsFileNotUploaded(false);
      setIsFileUploaded(false);
      setAcceptedFiles(files);
    },
  });

  const calculateNextFourYears = () => {
    const currentYear = new Date().getFullYear();
    // Calculation : Get the year and minus to 6 Months -->
    // const sixMonthsAgo = new Date().setMonth(new Date().getMonth() - 12);
    // const sixMonthsAgoYear = new Date(sixMonthsAgo).getFullYear();
    const lastYear = currentYear - 1;
    const nextFourYears = [];
    //here length is used for determining future years ex:5 i sused so 5 years of length will be displayed
    Array.from({ length: 5 }).forEach((_, index) => {
      const obj = {};
      const year = index === 0 ? lastYear : currentYear + index - 1;
      obj["id"] = index;
      obj["label"] = year;
      obj["value"] = year;
      nextFourYears.push(obj);
    });
    setYearList(nextFourYears);
  };

  const getSegmentList = () => {
    ClientServices.getSegmentDetails(1)
      .then((res) => {
        let response = res && res.data && res.data.data;
        const updatedSegment = response.map((segment) => {
          segment["label"] = segment.name;
          segment["value"] = segment.id;
          return segment;
        });
        setSegmentList(updatedSegment);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  const getBenchmarksList = () => {
    BenchmarkServices.getBenchmarks("/benchmarks")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        setReportFileList(CustomCloneDeep(response));
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };
  const getMarketForecastsList = () => {
    MarketForecastServices.getMarketForecasts("/marketforecasts")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        setReportFileList(CustomCloneDeep(response));
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };
  const getMarketLandscapesList = () => {
    MarketLandscapeServices.getMarketLandscapes("/marketlandscapes")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        setReportFileList(CustomCloneDeep(response));
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };
  const getSpecialReportsList = () => {
    SpecialReportServices.getSpecialReports("/specialreports")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        setReportFileList(CustomCloneDeep(response));
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };
  const getCustomerStudiesList = () => {
    CustomerStudyServices.getCustomerStudies("/customer-study/list")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        setReportFileList(CustomCloneDeep(response));
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };
  const getEcoSystemList = () => {
    EcosystemServices.getEcosystem("/ecosystem/list")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        setReportFileList(CustomCloneDeep(response));
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  const getAllCalendarEvents = () => {
    PublishedCalendarServices.getCalendarEvent()
      .then(async (res) => {
        let data = res.data;
        // let dateIndex = {};
        data = await Promise.all(
          data.map((event) => {
            const dateTimeString = event?.published_date;
            // const startTime = dateTimeString?.replace(" ", "T");
            event["name"] = event.title;
            event["event_id"] = event.id;
            event["title"] = `${event.calendar_quarter} ${event.title}`;

            // const timezoneOffset = new Date().getTimezoneOffset();
            // let eventStartTime = new Date(dateTimeString);
            //
            // // Adjust date considering timezone (removing time part)
            // eventStartTime.setMinutes(
            //   eventStartTime.getMinutes() + timezoneOffset + 60
            // );
            // eventStartTime.setHours(0, 0, 0, 0); // Reset time to midnight (full-day event)
            //
            // // Extract event date as a string (YYYY-MM-DD format)
            // const eventStartDate = eventStartTime.toISOString().split("T")[0];
            //
            // // Track event count per date
            // dateIndex[eventStartDate] = (dateIndex[eventStartDate] || 0) + 1;

            // Assign to even object
            event["start"] = convertToLocalTimezone(dateTimeString);
            event["allDay"] = true; // Full-day event

            switch (event.status) {
              case "publish":
              case null:
                switch (event.report_type) {
                  case constants.CALENDAR.PUBLISHED_SLUG.COMPANY:
                    event["className"] = ["event", "companyReportEvent"];
                    break;
                  case constants.CALENDAR.PUBLISHED_SLUG.BENCHMARK:
                    event["className"] = ["event", "benchmarkEvent"];
                    break;
                  case constants.CALENDAR.PUBLISHED_SLUG.MARKET_FORECAST:
                    event["className"] = ["event", "marketForecastEvent"];
                    break;
                  case constants.CALENDAR.PUBLISHED_SLUG.MARKET_LANDSCAPE:
                    event["className"] = ["event", "marketLandscapeEvent"];
                    break;
                  case constants.CALENDAR.PUBLISHED_SLUG.SPECIAL_REPORT:
                    event["className"] = ["event", "specialReportEvent"];
                    break;
                  case constants.CALENDAR.PUBLISHED_SLUG.CUSTOMER_STUDIES:
                    event["className"] = ["event", "customerStudiesEvent"];
                    break;
                  case constants.CALENDAR.PUBLISHED_SLUG.ECOSYSTEM:
                    event["className"] = ["event", "ecosystemEvent"];
                    break;
                }
                break;
              default:
                event["className"] = [
                  "event",
                  "draftEvent",
                  "draftPublishedEvent",
                ];
                break;
            }

            return event;
          })
        );

        setAllEvents(data);
        setDisplayEvents(data);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const getCurrentTime = () => {
    const date = new Date();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const addCalendarEvent = (eventType) => {
    if (!isFormValid()) {
      let date = new Date(
        Date.UTC(
          eventDate.getFullYear(),
          eventDate.getMonth(),
          eventDate.getDate()
        )
      );
      const body = {
        // This change is made to hide the 'topic'
        // topic: eventTopic,
        title: eventFormModel.title,
        report_type: selectedReport?.value,
        published_date: date,
        calendar_quarter: quaterAndYearCombiner(selectedQuater, selectedYear),
        created_time: getCurrentTime(),
        status: eventType,
      };
      // Add report-specific properties based on the selected report type
      if (selectedReport?.value === "company_report") {
        body.segment_id = selectedSegment?.value;
        body.company_id = selectedCompany.value || null;
      } else {
        body.report_id = selectedReportFile?.id;
      }
      PublishedCalendarServices.createCalendarEvent(body)
        .then((_res) => {
          Toast("New Event Created Successfully", "success");
          getAllCalendarEvents();
          setOpenAddModal(false);
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  const updateCalendarEvent = (eventType) => {
    if (!isFormValid()) {
      let dateUTC = Date.UTC(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate()
      );
      let date = new Date(dateUTC);
      let body = {
        // This change is made to hide the 'topic'
        // topic: eventTopic,
        title: eventFormModel?.title,
        report_type: eventFormModel?.report_type,
        published_date: date,
        calendar_quarter: quaterAndYearCombiner(selectedQuater, selectedYear),
        created_time: getCurrentTime(),
        status: eventType,
      };
      if (selectedReport?.value === "company_report") {
        body.segment_id = eventFormModel?.segment_id;
        body.company_id = eventFormModel?.company_id || null;
      } else {
        body.report_id = eventFormModel?.report_id;
      }
      PublishedCalendarServices.updateCalendarEvent(eventFormModel.id, body)
        .then((_res) => {
          Toast("Event Updated Successfully", "success");
          getAllCalendarEvents();
          setOpenAddModal(false);
        })
        .catch((error) => {
          Toast(
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
            "error"
          );
        });
    }
  };

  const deleteCalendarEvent = () => {
    PublishedCalendarServices.deleteCalendarEvent(eventDetails?.eventId)
      .then((_res) => {
        Toast("Event Deleted Successfully", "success");
        getAllCalendarEvents();
        setShowEventModalIsOpen(false);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const uploadBulkCalendarEvent = async () => {
    const file = acceptedFiles[0];
    const base64String = await convertFileToBase64Format(file);
    let body = {
      file: base64String,
    };
    PublishedCalendarServices.uploadBulkEventFile(body)
      .then((res) => {
        const message = res.data && res.data.msg;
        setFileUploadMsg(message);
        setIsFileUploaded(true);
        setAcceptedFiles([]);
        getAllCalendarEvents();
      })
      .catch((error) => {
        const message =
          error?.data?.error?.split(",") || "Something went wrong";
        setFileUploadMsg(message);
        setIsFileUploaded(false);
        setIsFileNotUploaded(true);
        setAcceptedFiles([]);
      });
  };

  const convertFileToBase64Format = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const isFormValid = () => {
    let formError = false;
    // Error states for different fields
    let titleError = false;
    let reportTypeError = false;
    let reportFileError = false;
    let segmentError = false;
    let quaterError = false;
    let yearError = false;
    let companyError = false;

    // Destructuring values from eventFormModel state
    const { title, company_id, segment_id, report_id } = eventFormModel;

    // Validate title
    if (!title || title.trim().length === 0) {
      titleError = true;
      formError = true;
    }

    // Validate selected report type
    if (!selectedReport || Object.keys(selectedReport).length === 0) {
      reportTypeError = true;
      formError = true;
    }

    // Validate selected quarter
    if (!selectedQuater || Object.keys(selectedQuater).length === 0) {
      quaterError = true;
      formError = true;
    }

    // Validate selected year
    if (!selectedYear || Object.keys(selectedYear).length === 0) {
      yearError = true;
      formError = true;
    }

    // Validate based on report type
    if (selectedReport.value === "company_report") {
      // Check if both company_id and segment_id are not present
      if (!company_id && !segment_id) {
        companyError = true;
        segmentError = true;
        formError = true;
      }
    } else {
      // Validate report file selection if it's not a company report
      if (!report_id) {
        reportFileError = true;
        formError = true;
      }
    }

    // Set form errors to state
    const formErrorObject = {
      titleError,
      reportTypeError,
      reportFileError,
      segmentError,
      quaterError,
      yearError,
      companyError,
    };
    setFormErrors(formErrorObject);
    return formError;
  };

  const showEventDetails = (info) => {
    const eventDetail = info.event?._def.extendedProps;
    const reportType = reportList.find(
      (report) => report.value === eventDetail.report_type
    );
    const date = new Date(info.event?.start);
    const fullDate = date.toLocaleDateString("en-us", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    const authorFullNames = eventDetail.authors || "-";
    setEventStatus(eventDetail.status);
    setDisplayReportType(reportType.label);
    setSelectedReport(reportType);
    setEventDate(date);
    quaterAndYearSeperator(eventDetail);

    setEventDetails({
      title: eventDetail.name,
      reportType: reportType.label,
      segment: eventDetail.segment_name,
      fullDate,
      company:
        reportType.label === "Company Research" ? eventDetail.report_name : "",
      reportFile:
        reportType.label !== "Company Research" ? eventDetail.report_name : "",
      quarter: eventDetail.calendar_quarter,
      authors: authorFullNames,
      eventId: eventDetail.event_id,
      date,
      status: eventDetail.status,
      buttonType: eventDetail.status,
    });

    setShowEventModalIsOpen(true);
  };

  const editEventDetail = (id) => {
    switch (selectedReport.value) {
      case "company_report":
        getCompanies();
        break;
      case "benchmark":
        getBenchmarksList();
        break;
      case "market_forecast":
        getMarketForecastsList();
        break;
      case "market_landscape":
        getMarketLandscapesList();
        break;
      case "customer_studies":
        getCustomerStudiesList();
        break;
      case "ecosystem":
        getEcoSystemList();
        break;
      case "special_report":
        getSpecialReportsList();
        break;
      default:
        break;
    }
    const formErrorObject = {
      titleError: false,
      topicError: false,
      reportTypeError: false,
      segmentError: false,
      quaterError: false,
      yearError: false,
      authorError: false,
    };
    const event = allEvents.find((event) => event.id === id);
    setEventFormModel({ ...event, title: event?.name });
    setFormErrors(formErrorObject);
    setButtonType("update");
    setShowEventModalIsOpen(false);
    setOpenAddModal(true);
  };

  const openPublishedAddModal = (data) => {
    setButtonType("published");
    setEventFormModel(data);
    // This change is made to hide the 'topic'
    // setEventTopic("");
    setSelectedReport("");
    setSelectedReportFile("");
    setSelectedSegment("");
    setSelectedCompany("");
    setSelectedQuater("");
    setSelectedYear("");
    let eventDate = new Date();
    if (data.date) {
      eventDate = new Date(data.date);
    }
    setEventDate(eventDate);
    setOpenAddModal(true);
    setFormErrors({
      titleError: false,
      topicError: false,
      reportTypeError: false,
      segmentError: false,
      quaterError: false,
      yearError: false,
      authorError: false,
    });
  };

  const openPublishedUploadModal = () => {
    setAcceptedFiles([]);
    setFileUploadMsg("");
    setIsFileNotUploaded(false);
    setIsFileUploaded(false);
    setOpenUploadModal(true);
  };

  const changeCalendarView = (viewType) => {
    setActiveView(viewType);
  };

  const quaterAndYearSeperator = (eventDetail) => {
    const str = eventDetail.calendar_quarter;
    const currentYear = new Date().getFullYear().toString();
    const quaterName = `${str[0]}${str[1]}`;
    const quater = quaterList.find((item) => item.value === quaterName);
    const yearName = `${currentYear[0]}${currentYear[1]}${str[2]}${str[3]}`;
    const year = yearList.find((year) => year.value === Number(yearName));
    setSelectedYear(year);
    setSelectedQuater(quater);
  };

  const quaterAndYearCombiner = (quater, year) => {
    const lastTwoDigitOfYear = Number(year.value) % 100;
    return `${quater.value}${lastTwoDigitOfYear}`;
  };

  const handlePublishedCheckbox = (slug) => {
    const array = [];
    publishedTypes.forEach((checkbox) => {
      if (checkbox.slug === slug) checkbox.is_selected = !checkbox.is_selected;
      array.push(checkbox);
    });
    setPublishedTypes(array);
  };

  const handleOccurenceCheckbox = (type) => {
    const occurenceArray = [];
    occurenceTypes.forEach((checkbox) => {
      checkbox.is_selected = checkbox.slug === type;
      occurenceArray.push(checkbox);
    });
    setOccurenceTypes(occurenceArray);
  };

  // handling the filtering events
  const handleCheckbox = (slug) => {
    const array = [];
    statusType.forEach((checkbox) => {
      if (checkbox.slug === slug) {
        checkbox.is_selected = !checkbox.is_selected;
      }
      array.push(checkbox);
    });
    if (array[0].is_selected && array[1].is_selected) {
      setDisplayEvents(allEvents);
    } else if (array[0].is_selected) {
      const filteredEvents = allEvents.filter(
        (event) => event.status === "draft"
      );
      setDisplayEvents(filteredEvents);
    } else if (array[1].is_selected) {
      const filteredEvents = allEvents.filter(
        (event) => event.status === "publish"
      );
      setDisplayEvents(filteredEvents);
    } else {
      setDisplayEvents([]);
    }
    setStatusTypes(array);
  };

  const handleFilterData = () => {
    const todayDate = new Date();
    const occurenceResult = []; // occurence result
    const occurenceType = occurenceTypes.find(
      (occurence) => occurence.is_selected === true
    );
    // get data of occurence type and stored them into occurenceResult
    allEvents.forEach((event) => {
      let eventDate = new Date(event.start);
      if (occurenceType.slug === "today") {
        if (
          eventDate.getFullYear() === todayDate.getFullYear() &&
          eventDate.getMonth() === todayDate.getMonth() &&
          eventDate.getDate() === todayDate.getDate()
        ) {
          occurenceResult.push(event);
        }
      } else if (occurenceType.slug === "upcoming") {
        eventDate > todayDate && occurenceResult.push(event);
      } else if (occurenceType.slug === "past") {
        eventDate < todayDate && occurenceResult.push(event);
      } else {
        occurenceResult.push(event);
      }
    });
    let finalResult = [];
    publishedTypes.forEach((published) => {
      if (published.is_selected) {
        occurenceResult.forEach((event) => {
          if (event.report_type === published.slug) {
            finalResult.push(event);
          }
        });
      } else {
        let array = finalResult;
        array.forEach((event, index) => {
          if (event.report_type === published.slug) {
            finalResult.splice(index, 1);
          }
        });
      }
    });
    setDisplayEvents(finalResult);
  };

  const searchTextResult = (e) => {
    let searchInput = e.target.value;
    setSearchText(searchInput);
    const searchResult = allEvents.filter((event) =>
      event.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setDisplayEvents(searchResult);
    if (searchInput.length > 0) {
      setActiveView("listYear");
    } else {
      setActiveView("dayGridMonth");
    }
  };

  useEffect(() => {
    getAllCalendarEvents();
    getSegmentList();
    calculateNextFourYears();
    props.activeMenuTab(
      constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED.LIST.ROUTE
    );
  }, []);

  useEffect(() => {
    handleFilterData();
  }, [publishedTypes, occurenceTypes]);

  // for calendar header
  useEffect(() => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    API && API.changeView(activeView);
    const prevButton = document.getElementById("calendar-prev-btn");
    const nextButton = document.getElementById("calendar-next-btn");
    const handlePrev = () => {
      API.prev();
      setCalendarHeaderDate(API.view.title);
    };
    const handleNext = () => {
      API.next();
      setCalendarHeaderDate(API.view.title);
    };
    prevButton.addEventListener("click", handlePrev);
    nextButton.addEventListener("click", handleNext);
    setCalendarHeaderDate(API.view.title);
    return () => {
      prevButton.removeEventListener("click", handlePrev);
      nextButton.removeEventListener("click", handleNext);
    };
  }, [activeView]);

  const customStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      minHeight: "30px",
      height: "auto",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
    }),
  };
  const customErrorStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      minHeight: "30px",
      height: "auto",
      border: "1px solid red",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
    }),
  };

  const handleDownloadSampleFile = () => {
    const link = document.createElement("a");
    link.href =
      "https://tbr-ggk.s3.us-east-2.amazonaws.com/production/Sample_File.xlsx";
    link.target = "_blank";
    link.download = "SampleFile.xlsx";
    link.click();
    link.remove();
  };

  const handleTitleChange = (e) => {
    if (!numWhiteSpaceRegex.test(e.target.value)) {
      setEventFormModel({
        ...eventFormModel,
        title: e.target.value,
      });
      setFormErrors({ ...formErrors, titleError: false });
    }
  };

  // This change is made to hide the 'topic'
  // const handleTopicChange = (e) => {
  //   if (!numWhiteSpaceRegex.test(e.target.value)) {
  //     setEventTopic(e.target.value);
  //     setFormErrors({ ...formErrors, topicError: false });
  //   }
  // };

  const handleReportTypeChange = (e) => {
    setSelectedReport(e);
    setEventFormModel({
      ...eventFormModel,
      report_type: e.value,
      company_id: null,
      segment_id: null,
      report_id: null,
      segment: null,
      company: null,
      report_data: null,
    });
    switch (e.value) {
      case "company_report":
        getCompanies();
        break;
      case "benchmark":
        getBenchmarksList();
        break;
      case "market_forecast":
        getMarketForecastsList();
        break;
      case "market_landscape":
        getMarketLandscapesList();
        break;
      case "customer_studies":
        getCustomerStudiesList();
        break;
      case "ecosystem":
        getEcoSystemList();
        break;
      case "special_report":
        getSpecialReportsList();
        break;
      default:
        break;
    }
    setSelectedCompany("");
    setSelectedReportFile("");
    setSelectedSegment("");
    setFormErrors({ ...formErrors, reportTypeError: false });
  };

  const getCompanies = () => {
    CompanyServices.getTotalCompanies("calender/companies")
      .then((res) => {
        document.body.classList.remove("second-loading-indicator");
        let response = res && res.data && res.data.data;
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        const formattedCompanies = response.map((company) => ({
          value: company.id,
          label: company.name,
          segments: company.segments,
        }));

        setCompaniesList(formattedCompanies);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      })
      .finally(() =>
        document.body.classList.remove("second-loading-indicator")
      );
  };

  const handleSegmentChange = (selectedSegmentOption) => {
    setSelectedSegment(selectedSegmentOption);
    setEventFormModel((prevFormModel) => ({
      ...prevFormModel,
      segment: selectedSegmentOption,
      segment_id: selectedSegmentOption?.value,
    }));
    setFormErrors({ ...formErrors, segmentError: false });
  };

  const handleOtherReportChange = (e) => {
    // Update selected report file
    setSelectedReportFile({ ...e, is_selected: true });

    // Update event form model
    setEventFormModel((prevFormModel) => ({
      ...prevFormModel,
      // Set the selected report ID
      report_id: e.id,
      // Store the entire selected report data
      report_data: e,
    }));

    // Clear any previous form errors related to report file selection
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      reportFileError: false,
    }));
  };

  const handleCompanyChange = (selectedCompanyOption) => {
    // Set selected company
    setSelectedCompany(selectedCompanyOption);

    // Prepare segment data from selected company's segments
    const segments = selectedCompanyOption?.segments;
    const segmentData = segments?.map((segment) => ({
      value: segment.id,
      label: segment.name,
    }));

    // Update the event form model with selected company ID
    setEventFormModel((prevFormModel) => ({
      ...prevFormModel,
      company_id: selectedCompanyOption?.value,
      company: selectedCompanyOption,
      segment_id: null,
      segment: null,
    }));

    // Update segment list and reset selected segment
    setSegmentList(segmentData);
    setSelectedSegment(null);

    // Clear any previous form errors related to company selection
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      companyError: false,
    }));
  };

  const handleQuaterChange = (e) => {
    setSelectedQuater(e);
    setFormErrors({ ...formErrors, quaterError: false });
  };

  const handleYearChange = (e) => {
    setSelectedYear(e);
    setFormErrors({ ...formErrors, yearError: false });
  };

  let classNameModalHeader = "published-event-container";
  let borderLeftStyle;
  let colorIconStyle;

  if (eventStatus === "draft") {
    classNameModalHeader += " draftCalendarModalHeaderEvent";
    borderLeftStyle = { borderLeft: "10px solid #555555" };
    colorIconStyle = { color: "#000000" };
  } else {
    const reportTypeToColor = {
      "Company Research": "#5eb37e",
      Benchmark: "#f19e37",
      "Market Forecast": "#69c1fa",
      "Market Landscape": "#d63864",
      "Special Report": "#3577ed",
      "Customer Study": "#832da4",
      Ecosystem: "#FFA1B2",
    };
    const defaultColor = "#1085c6";
    const reportType =
      displayReportType in reportTypeToColor ? displayReportType : "";
    const color = reportTypeToColor[reportType] || defaultColor;
    // if white space or blank space was there in reporttype then removing that
    classNameModalHeader += reportType
      ? ` ${reportType.toLowerCase().replace(/\s+/g, "")}ModalHeader`
      : "";
    borderLeftStyle = { borderLeft: `10px solid ${color}` };
    colorIconStyle = { color };
  }

  // Group related props into objects for better organization
  const formProps = {
    eventFormModel,
    formErrors,
    handleTitleChange,
  };

  const dropdownProps = {
    reportList,
    selectedReport,
    handleReportTypeChange,
    companiesList,
    handleCompanyChange,
    segmentList,
    handleSegmentChange,
    selectedCompany,
    reportFileList,
    handleOtherReportChange,
    quaterList,
    handleQuaterChange,
    selectedQuater,
    yearList,
    handleYearChange,
    selectedYear,
  };

  const eventProps = {
    eventDate,
    setEventDate,
    addCalendarEvent,
    updateCalendarEvent,
  };

  const styleProps = {
    customErrorStyles,
    customStyles,
  };

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white br_8 main_content_height d-flex">
        <div className="webinar-left-published">
          <h2>Published Calendar</h2>
          <Collapsible
            open={collapseIsOpen1}
            trigger={
              <div
                onClick={() => setCollapseIsOpen1(!collapseIsOpen1)}
                className="published-collapse"
              >
                <h3 className="">Published Content Type</h3>
                <span
                  style={{ color: "#555555" }}
                  className="material-icons-outlined material-icon mr-2 cursor-pointer"
                >
                  {collapseIsOpen1 ? "expand_less" : "expand_more"}
                </span>
              </div>
            }
          >
            <div className="mt-2">
              {publishedTypes.map((checkbox) => (
                <div key={checkbox.id}>
                  <div
                    style={{ paddingRight: "10px" }}
                    className="d-flex justify-content-between"
                  >
                    <div className="d-flex">
                      <div className="sb-checkbox">
                        <input
                          type="checkbox"
                          className="sb-checkbox__input"
                          checked={checkbox.is_selected}
                          id={checkbox.id}
                          onChange={() =>
                            handlePublishedCheckbox(checkbox.slug)
                          }
                        />
                        <label
                          className={`sb-checkbox__label sb-checkbox__label--${checkbox.color}`}
                          htmlFor={checkbox.id}
                        />
                      </div>
                      <span className="material-icons-outlined icon-fs-18 published-calendar-icon">
                        {checkbox.icon}
                      </span>
                      <p className="published-checkbox-label">
                        {checkbox.name}
                      </p>
                    </div>
                    {checkbox.icon === "domain" && (
                      <span
                        onClick={() => setCompanyCollapse(!companyCollapse)}
                        className="material-icons-outlined icon-fs-20 cursor-pointer"
                      >
                        {companyCollapse ? "expand_more" : "chevron_right"}
                      </span>
                    )}
                  </div>
                  {checkbox.icon === "domain" && (
                    <div
                      className={
                        companyCollapse
                          ? "company-report-container"
                          : "company-report-none"
                      }
                    >
                      <div
                        className={
                          companyCollapse
                            ? "specific-report"
                            : "specific-report-none"
                        }
                      >
                        <span className="material-icons-outlined icon-fs-10 mt-1 mr-2">
                          fiber_manual_record
                        </span>
                        <p>Company Research</p>
                      </div>
                      <div
                        className={
                          companyCollapse
                            ? "specific-report"
                            : "specific-report-none"
                        }
                      >
                        <span className="material-icons-outlined icon-fs-10 mt-1 mr-2">
                          fiber_manual_record
                        </span>
                        <p>Company Snapshot</p>
                      </div>
                      <div
                        className={
                          companyCollapse
                            ? "specific-report"
                            : "specific-report-none"
                        }
                      >
                        <span className="material-icons-outlined icon-fs-10 mt-1 mr-2">
                          fiber_manual_record
                        </span>
                        <p>Company Profile</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Collapsible>

          <Collapsible
            open={collapseIsOpen2}
            trigger={
              <div
                onClick={() => setCollapseIsOpen2(!collapseIsOpen2)}
                className="published-collapse mb-2"
              >
                <h3 className="">Occurence Type</h3>
                <span
                  style={{ color: "#555555" }}
                  className="material-icons-outlined material-icon mr-2 cursor-pointer"
                >
                  {collapseIsOpen2 ? "expand_less" : "expand_more"}
                </span>
              </div>
            }
          >
            <div>
              {occurenceTypes.map((occurence, key) => (
                <div key={key} className="d-flex">
                  <input
                    className="mt-1"
                    type="checkbox"
                    checked={occurence.is_selected}
                    onChange={() => handleOccurenceCheckbox(occurence.slug)}
                  />
                  <p className="webinar-checkbox-label">{occurence.name}</p>
                </div>
              ))}
            </div>
          </Collapsible>

          <Collapsible
            open={collapseIsOpen3}
            trigger={
              <div
                onClick={() => setCollapseIsOpen3(!collapseIsOpen3)}
                className="published-collapse mb-2"
              >
                <h3 className="">Event Status</h3>
                <span
                  style={{ color: "#555555" }}
                  className="material-icons-outlined material-icon mr-2 cursor-pointer"
                >
                  {collapseIsOpen3 ? "expand_less" : "expand_more"}
                </span>
              </div>
            }
          >
            <div>
              {statusTypesArray.map((statusType, index) => (
                <div className="d-flex" key={index}>
                  <div className="sb-checkbox">
                    <input
                      className="mt-1 sb-checkbox__input"
                      type="checkbox"
                      id={statusType.id}
                      checked={statusType.is_selected}
                      onChange={() => handleCheckbox(statusType.slug)}
                    />
                    <label
                      className={`sb-checkbox__label sb-checkbox__label--${statusType.color}`}
                      htmlFor={statusType.id}
                    />
                  </div>
                  <p className="webinar-checkbox-label">{statusType.name}</p>
                </div>
              ))}
            </div>
          </Collapsible>
        </div>
        <div className="webinar-right">
          <CalendarHeader
            calendarHeaderDate={calendarHeaderDate}
            activeView={activeView}
            searchText={searchText}
            searchTextResult={searchTextResult}
            changeCalendarView={changeCalendarView}
            openPublishedAddModal={openPublishedAddModal}
            openPublishedUploadModal={openPublishedUploadModal}
          />
          <div className="published-calendar">
            <FullCalendar
              timeZone="local"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              dateClick={(e) => {
                openPublishedAddModal(e);
              }}
              ref={calendarRef}
              editable={false}
              initialView="dayGridMonth"
              dayMaxEvents={4}
              eventClick={showEventDetails}
              displayEventEnd={true}
              headerToolbar={false}
              displayEventTime={false}
              height={"78vh"}
              events={displayEvents}
              // eventContent={renderEvent}
              eventDisplay="block"
              views={viewsForCalendar}
            />
          </div>
        </div>
      </div>
      <div>
        {showEventModalIsOpen && (
          <PublishCalendarModalDetail
            showPublishCalendarModalIsOpen={showEventModalIsOpen}
            toggle={() => setShowEventModalIsOpen(!showEventModalIsOpen)}
            classNameModalHeader={classNameModalHeader}
            borderLeftStyle={borderLeftStyle}
            deleteCalendarEvent={deleteCalendarEvent}
            eventDetails={eventDetails}
            colorIconStyle={colorIconStyle}
            onClose={() => setShowEventModalIsOpen(false)}
            editEventDetail={editEventDetail}
          />
        )}
      </div>
      <div>
        {openAddModal && (
          <AddEditCalendarModal
            isOpen={openAddModal}
            onClosed={() => setOpenAddModal(false)}
            toggle={() => setOpenAddModal(false)}
            buttonType={buttonType}
            constants={constants}
            {...formProps}
            {...dropdownProps}
            {...eventProps}
            {...styleProps}
          />
        )}
      </div>
      <div>
        {openUploadModal && (
          <Modal
            isOpen={openUploadModal}
            onClosed={() => setOpenUploadModal(false)}
            style={{ width: "480px" }}
            className={"modal-lg modal-w-header custom-modal primary-modal"}
          >
            <ModalHeader>
              <div className="create-event-modal-header">
                <h2 className="">Insert Multiple Events</h2>
                <div className="d-flex align-items-center">
                  <span
                    onClick={handleDownloadSampleFile}
                    // href="../../public/SampleFile.xlsx.numbers"
                    // download
                    // target="_blank"
                    className="material-icons icon-fs-20 mr-2 upload-file-icon"
                    data-for={`my-tooltip`}
                    data-tip={`Download Sample File`}
                    data-iscapture="true"
                  >
                    download
                  </span>
                  <ReactTooltip id="my-tooltip" />
                  <span
                    onClick={() => setOpenUploadModal(false)}
                    className="material-icons upload-file-icon"
                  >
                    close
                  </span>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div>
                <div className="bulk-upload-file-div">
                  {acceptedFiles.length > 0 ? (
                    acceptedFiles.map((file, index) => (
                      <div key={index}>
                        <div className="d-flex justify-content-center">
                          <div className="uploaded-file">{file.name}</div>
                        </div>
                        <p className="file-text">Size: {file.size} bytes</p>
                      </div>
                    ))
                  ) : (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="mb-0">
                        Drag to upload your file here (accepted formats: CSV,
                        XLSX, XLS)
                      </p>
                      <p className="mb-0 mt-2">Or</p>
                      <div className="d-flex justify-content-center mt-2">
                        <button className="upload-btn">Select File</button>
                      </div>
                    </div>
                  )}
                </div>
                {isFileUploaded && (
                  <div className="file-upload-success">{fileUploadMsg}</div>
                )}
                {isFileNotUploaded && (
                  <div className="file-upload-error">
                    {Array.isArray(fileUploadMsg) &&
                      fileUploadMsg.map(
                        (item, index) =>
                          item.length > 0 && (
                            <div key={index} className="d-flex error-content">
                              <div className="error-dot"></div>
                              <p className="file-error-text">{item}</p>
                            </div>
                          )
                      )}
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center"
              >
                <button
                  className="btn-cancel"
                  onClick={() => setOpenUploadModal(false)}
                >
                  Cancel
                </button>

                <button
                  className={`${
                    acceptedFiles.length > 0
                      ? "btn-save-draft"
                      : " btn-save-disabled"
                  } ml-3`}
                  disabled={acceptedFiles.length <= 0}
                  onClick={uploadBulkCalendarEvent}
                >
                  Insert
                </button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </aside>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishedCalendar);
