import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Notification from "../../Components/Notification";
import { MagicLinkUserServices } from "../../Services/MagicLinkUserServices";
import login_building from "../../Images/login_building.png";
import login_map from "../../Images/login_map.png";
import logo from "../../Images/INSIGHTCENTER@2x.png";
import constants from "../../Utils/constants";
import { Container, Row, Form, ButtonToggle } from "reactstrap";
import {
  loginUserSuccess,
  loginUserFailure,
  openSearchBar,
} from "../../Store/Actions/User";
import { connect } from "react-redux";
import decodeJWT from "../../lib/decodeJWT";
import { formatRoute } from "react-router-named-routes";

const MagicLinkVerification = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [notificationColor, setNotificationColor] = useState("danger");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (!token) {
      setLoading(false);
      return;
    }

    // Remove the token from the URL without re-triggering the effect
    history.replace({ search: params.toString() });

    (async () => {
      // Verify token
      await verifyMagicLink(token);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyMagicLink = async (token) => {
    try {
      const body = { token };
      const data = await MagicLinkUserServices.magicLinkLogin(body);

      if (!data || !data.data.access_token) {
        throw new Error("Authentication failed.");
      }

      localStorage.setItem("SSO", "false");
      localStorage.setItem("token", data.data.access_token);
      localStorage.setItem("refresh_token", data.data.refresh_token);
      localStorage.setItem(
        "is_admin",
        decodeJWT(data.data.access_token).user.is_admin
      );

      props.loginSuccess(decodeJWT(data.data.access_token).user);

      // Redirect Logic
      const searchParams = new URLSearchParams(document.location.search);
      const redirectUrl = searchParams.get("redirect_url");

      if (redirectUrl && !redirectUrl.includes("/login")) {
        window.location.href = redirectUrl;
      } else {
        history.push(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE);
      }
    } catch (error) {
      const errorMessage =
        error.data?.error || "Magic link verification failed.";
      setError(errorMessage);
      setLoading(false);
    }
  };
  const handleRequestNowLink = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    MagicLinkUserServices.requestMagicLink(token)
      .then((res) => {
        const response = res.data.result;
        const responseMsg = response.message;
        setError(responseMsg);
        setNotificationColor("success");
      })
      .catch((error) => {
        // Extract error message from response
        const errorMessage = error?.data?.error;
        // Update the state to show the error
        setError(errorMessage);
      });
  };

  return (
    <div className="wrapper login_page">
      <main>
        <Container fluid>
          <Row>
            <aside className="col-md-6 px-0 hide-in-mobile">
              <div className="login_left">
                <figure className="login_banner">
                  <img
                    src={login_building}
                    className="img-fluid"
                    alt="login banner"
                  />
                </figure>
                <a href="/">
                  <img
                    style={{ width: "120px", marginLeft: "20px" }}
                    src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                    alt="TBR logo"
                  />
                </a>
              </div>
            </aside>
            <aside className="col-md-6 px-0">
              <div className="login_right">
                <figure>
                  <img src={login_map} className="img-fluid" alt="map Banner" />
                </figure>
                <Form>
                  <span className="login_formlogos">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </span>
                  {error && (
                    <>
                      <Notification
                        color={notificationColor}
                        message={error}
                        className="mt-2"
                      />
                      <div>
                        <ButtonToggle
                          color="primary"
                          type="button"
                          style={{
                            minWidth: "100px",
                            marginLeft: "20px",
                            maxHeight: "30px",
                            fontSize: "14px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              formatRoute(
                                constants.APPLICATION_ROUTE.LOGIN.ROUTE,
                                {}
                              )
                            );
                          }}
                          className="d-inline-flex align-items-center justify-content-center"
                        >
                          <span className="material-icons-round icon-fs-14 mr-1">
                            west
                          </span>
                          Back to Login
                        </ButtonToggle>
                        <ButtonToggle
                          color="primary"
                          type="button"
                          style={{
                            minWidth: "100px",
                            marginLeft: "27px",
                            maxHeight: "30px",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            handleRequestNowLink();
                          }}
                          className="d-inline-flex align-items-center justify-content-center"
                        >
                          Request Now
                        </ButtonToggle>
                      </div>
                    </>
                  )}
                </Form>
                <div className="terms-conditions">
                  <a
                    className="links"
                    href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Terms+of+Service.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  |
                  <a
                    className="links"
                    href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Privacy+Policy.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </aside>
          </Row>
        </Container>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({ home: state.home });

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (user) => dispatch(loginUserSuccess(user)),
  loginFailure: (user) => dispatch(loginUserFailure(user)),
  openSearchBar: (data) => dispatch(openSearchBar(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MagicLinkVerification);
