import React, { Component } from "react";
import DynamicTableWithSelectAllComponentNew from "../Components/DynamicTableWithSelectAllNew";
import constants from "../Utils/constants";
import { CompanyServices } from "../Services/Company";
import { MasterServices } from "../Services/Master";
import queryString from "query-string";
import { formatRoute } from "react-router-named-routes";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import Search from "../Components/Search";
import { connect } from "react-redux";
import AclAction from "../Components/ACL/AclAction";
import DailogNew from "../Components/DailogNew";
import { activeMenuTab } from "../Store/Actions/User";
import { clients } from "../Store/Actions/Client";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink } from "reactstrap";
import Toast from "../Utils/Toast";
import permissionCheck from "../lib/permissionCheck";
import DocumentTitle from "../Utils/DocumentTitle";
import isRole_Acc_mgr_or_salesAdmin from "../Utils/isRole_Acc_mgr_or_salesAdmin";
import decodeJWT from "../lib/decodeJWT";
import CustomEllipsis from "../Utils/CustomEllipsis";
import { downloadExcelFile } from "../Helpers/Helper";

const tableHeader = [
  {
    id: "original_name",
    title: "Company Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "name",
    title: "Display Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "stock_ticker",
    title: "Symbol",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "currency",
    title: "Currency",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  // { id: 'updated_at', title: 'Last Modified At', sortable: true, style: {} },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_by",
    title: "Updated By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerStyle: {},
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerStyle: {},
    visibility: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
  },
];

const tableExportHeader = [
  { id: "original_name", title: "Company Name" },
  { id: "name", title: "Display Name" },
  { id: "stock_ticker", title: "Symbol" },
  { id: "currency", title: "Currency" },
  { id: "created_by", title: "Created By" },
  { id: "created_at", title: "Created On" },
];

const showActions = true;
class ListCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.COMPANIES_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.COMPANY_DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      currencies: [],
      currentPageDataCount: 0,
      disableStatus: false,
    };
  }
  componentDidMount() {
    let index = tableHeader.findIndex((element) => element.id === "actions");
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    if (isRole_Acc_mgr_or_salesAdmin(tokenDetails.role.id)) {
      index > -1 && (tableHeader[index].visibility = false);
      this.setState({ disableStatus: true });
    } else {
      index > -1 && (tableHeader[index].visibility = true);
    }
    let params = queryString.parse(this.props.location.search);
    DocumentTitle(constants.APPLICATION_ROUTE.COMPANY.LIST.HEADER_TITLE);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.COMPANIES_DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.COMPANY_DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE);
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, _key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleCompanyConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.COMPANY.INACTIVATE_COMPANY_CONFIRM_MESSAGE}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.COMPANY.ACTIVATE_COMPANY_CONFIRM_MESSAGE}
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_company" : "activate_company",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  removeCompanyConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove Company</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {constants.COMPANY.REMOVE_COMPANY_CONFIRM_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_company", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeCompany = (companyId) => {
    CompanyServices.removeCompany(companyId)
      .then((_res) => {
        Toast(constants.COMPANY.REMOVE.SUCCESS, "success");
        this.setState({
          pageNo:
            this.state.currentPageDataCount === 1 && this.state.pageNo !== 1
              ? this.state.pageNo - 1
              : this.state.pageNo,
        });
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleCompany = (companyId, isActive) => {
    CompanyServices.toggleCompany(companyId, { is_active: isActive })
      .then((_res) => {
        Toast(
          isActive
            ? constants.COMPANY.ACTIVATE.SUCCESS
            : constants.COMPANY.IN_ACTIVATE.SUCCESS,
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_company":
        this.removeCompany(config.id);
        break;
      case "de-activate_company":
        this.toggleCompany(config.id, 0);
        break;
      case "activate_company":
        this.toggleCompany(config.id, 1);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  getCurrencies = () => {
    if (this.state.currencies && this.state.currencies.length) {
      return {
        data: {
          data: {
            currencies: this.state.currencies,
          },
        },
      };
    } else {
      return MasterServices.getCurrencies();
    }
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.COMPANY_RESOURCE.TITLE}
          action={constants.ACL.COMPANY_RESOURCE.ACTIONS.EDIT}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.COMPANY.LIST.EDIT_COMPANY_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.COMPANY.LIST.EDIT_COMPANY,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.COMPANY.LIST.EDIT_COMPANY_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.COMPANY.LIST.EDIT_COMPANY_TOOLTIP
            }
            data-iscapture="true"
          >
            <span className="material-icons-outlined text-blue-clr icon-fs-20">
              edit
            </span>
          </button>
        </AclAction>
      </>
    );
  };

  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url = "/companies";
    Promise.all([
      this.getCurrencies(),
      CompanyServices.getCompanies(
        `${url}?page=` +
          this.state.pageNo +
          "&page_size=" +
          this.state.pageSize +
          queryParams
      ),
    ])
      .then((values) => {
        this.postResolvingOfGetCompanies(
          values[1].data,
          values[0].data.data.currencies
        );
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  postResolvingOfGetCompanies = (data, currencies) => {
    let checkContentPageAccess = permissionCheck(
      constants.ACL.PERMISSION_KEY,
      constants.ACL.COMPANY_RESOURCE.TITLE,
      constants.ACL.COMPANY_RESOURCE.ACTIONS.MANAGE
    );
    let clientData = data.data;
    let currentPageDataCount =
      clientData && clientData.length ? clientData.length : 10;
    let count = (data && data.meta && data.meta.total_count) || 0;
    clientData = clientData.map((data) => {
      let curencyName = currencies.find(
        (currency) => currency.id === data.currency_id
      )?.name;
      let companiesOrgName = CustomEllipsis(data.original_name);
      let companiesName = CustomEllipsis(data.name);
      return {
        id: data.id,
        original_name: checkContentPageAccess ? (
          <NavLink
            tag={RRNavLink}
            style={{ padding: 0, color: "#1085c6", cursor: "pointer" }}
            exact
            to={formatRoute(
              constants.APPLICATION_ROUTE.COMPANY.MANAGE_CONTENT.ROUTE,
              { id: data.id }
            )}
          >
            <label className={`mb-0`}>
              <span key={data.id} className={"datagrid_tooltip"}>
                {companiesOrgName.text}
                {companiesOrgName.ellipsis && (
                  <span
                    className="material-icons-outlined icon"
                    data-for={"Tooltip-" + data.id}
                    data-tip={companiesOrgName.fullText}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={"Tooltip-" + data.id}
                      multiline={true}
                    />
                  </span>
                )}
              </span>
            </label>
          </NavLink>
        ) : (
          <span>{data.original_name}</span>
        ),
        name: data.name ? (
          <label className={`mb-0`}>
            <span key={data.id} className={"datagrid_tooltip"}>
              {companiesName.text}
              {companiesName.ellipsis && (
                <span
                  className="material-icons-outlined icon"
                  data-for={"Tooltip-" + data.id}
                  data-tip={companiesName.fullText}
                  data-iscapture="true"
                >
                  info
                  <CustomReactTooltip
                    id={"Tooltip-" + data.id}
                    multiline={true}
                  />
                </span>
              )}
            </span>
          </label>
        ) : (
          "-"
        ),
        stock_ticker: `${data.stock_ticker ? data.stock_ticker : "-"}`,
        currency: `${curencyName ? curencyName : "-"}`,
        created_by:
          data.created_by_user && data.created_by_user.id
            ? `${
                data.created_by_user.first_name
                  ? data.created_by_user.first_name
                  : ""
              } ${
                data.created_by_user.last_name
                  ? data.created_by_user.last_name
                  : ""
              }`
            : "-",
        updated_by:
          data.updated_by_user && data.updated_by_user.id
            ? `${
                data.updated_by_user.first_name
                  ? data.updated_by_user.first_name
                  : ""
              } ${
                data.updated_by_user.last_name
                  ? data.updated_by_user.last_name
                  : ""
              }`
            : "-",
        created_at: data.created_at
          ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
          : ComapnyDetailsDateTimeFormatter(new Date(), false),
        updated_at: data.updated_at
          ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
          : "-",
        // updated_at: data.updated_at ? ComapnyDetailsDateTimeFormatter(data.updated_at, false) : '-',
        is_active: (
          <AclAction
            type={constants.ACL.PERMISSION_KEY}
            entity={constants.ACL.COMPANY_RESOURCE.TITLE}
            action={constants.ACL.COMPANY_RESOURCE.ACTIONS.STATUS}
          >
            <div className="custom-control custom-switch success-toggle">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`customSwitch${data.id}`}
                checked={data.is_active === 1}
                onChange={(e) => {
                  e.stopPropagation();
                  this.rowAction(
                    constants.APPLICATION_ROUTE.COMPANY.LIST.STATUS,
                    data,
                    e
                  );
                }}
                disabled={this.state.disableStatus}
              />
              <label
                className="custom-control-label"
                htmlFor={`customSwitch${data.id}`}
              ></label>
            </div>
          </AclAction>
        ),
        actions: this.getActions(data),
      };
    });
    this.setState({ rowData: clientData, count: count, currentPageDataCount });
  };

  exportList = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    let url = "/companies";
    CompanyServices.getCompanies(`${url}?1=1` + queryParams)
      .then((res) => {
        let clientData = res.data.data;
        clientData = clientData.map((data) => {
          let curencyName = this.state.currencies.find(
            (currency) => currency.id === data.currency_id
          )?.name;
          return {
            id: data.id,
            original_name: data.original_name,
            name: `${data.name ? data.name : "-"}`,
            stock_ticker: `${data.stock_ticker ? data.stock_ticker : "-"}`,
            currency: `${curencyName ? curencyName : "-"}`,
            // created_by: `-`,
            created_by:
              data.created_by_user && data.created_by_user.id
                ? `${
                    data.created_by_user.first_name
                      ? data.created_by_user.first_name
                      : ""
                  } ${
                    data.created_by_user.last_name
                      ? data.created_by_user.last_name
                      : ""
                  }`
                : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateFormatter(data.created_at, false)
              : ComapnyDetailsDateFormatter(new Date(), false),
          };
        });
        this.setState(
          {
            totalData: clientData,
            downloadAction:
              this.state.downloadAction === null
                ? true
                : !this.state.downloadAction,
          },
          () => {
            // document.querySelector(".hidden-download").click();
            // Filter the data to include only the defined columns
            const processedColumns = tableExportHeader.map((col) => ({
              ...col,
              accessor: col.id === "name" ? col.title : col.id.split("||")[0], // Extract the part before "||"
              header: col.title,
            }));

            // Filter the data to include only the processed columns
            const filteredData = this.state.totalData.map((row) =>
              processedColumns.reduce((obj, col) => {
                obj[col.header] = row[col.id] || row[col.header];
                return obj;
              }, {})
            );
            downloadExcelFile(
              "COMPANIES",
              "Companies",
              filteredData,
              processedColumns
            );
          }
        );
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };

  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };

  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.COMPANY.LIST.EDIT_COMPANY:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.COMPANY.EDIT.ROUTE,
            { id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.COMPANY.LIST.STATUS:
        this.toggleCompanyConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.COMPANY.LIST.REMOVE_COMPANY:
        this.removeCompanyConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.COMPANY.LIST.MANAGE_CONTENT:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.COMPANY.MANAGE_CONTENT.ROUTE,
            { id: data.id }
          ),
        });
        break;
      default:
        break;
    }
  };

  addCompany = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.COMPANY.CREATE.ROUTE,
        {}
      ),
    });
  };

  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };

  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
          <div className="d-flex align-items-center pt-3 flex-wrap title-section breadcrumb-mb-0">
            {/* <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} /> */}
            <h1 className="text-ligntblue2 font-weight-bold mb-0">
              {constants.APPLICATION_ROUTE.COMPANY.LIST.HEADER_TITLE}
            </h1>
            <div className="ml-auto d-flex align-items-center justify-content-end title-right-block mb-0">
              <div className="min-width-266">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={"Filter Company / Display Name"}
                />
              </div>
              <div className="icon-group d-flex">
                <button
                  className="icon-btn-primary icon-btn d-flex align-items-center justify-content-center"
                  onClick={this.exportList}
                  data-for={`tooltip-${Slugify(
                    constants.APPLICATION_ROUTE.COMPANY.LIST
                      .EXPORT_EXCEL_TOOLTIP
                  )}`}
                  data-tip={
                    constants.APPLICATION_ROUTE.COMPANY.LIST
                      .EXPORT_EXCEL_TOOLTIP
                  }
                  data-iscapture="true"
                >
                  <span className="material-icons-round icon">download</span>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.COMPANY.LIST
                        .EXPORT_EXCEL_TOOLTIP
                    )}`}
                    multiline={true}
                  />
                </button>
              </div>

              <AclAction
                type="permissions"
                entity={constants.ACL.COMPANY_RESOURCE.TITLE}
                action={constants.ACL.COMPANY_RESOURCE.ACTIONS.CREATE}
              >
                <div
                  tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}
                  className="ml-3"
                >
                  <button
                    className={
                      "btn btn-primary d-flex align-items-center icon-text-primary-btn title-btn"
                    }
                    type="button"
                    onClick={this.addCompany}
                  >
                    <span className="material-icons-round icon-fs-14 mr-1">
                      add
                    </span>
                    <span className="mt-text">
                      {" "}
                      {" " +
                        constants.APPLICATION_ROUTE.COMPANY.LIST
                          .ADD_BUTTON_TITLE}
                    </span>
                  </button>
                </div>
              </AclAction>
            </div>
          </div>

          <div className="row">
            <div className={"col-lg-12 col-sm-12 col-md-12 col-12 "}>
              <div
                className={`${
                  this.props.className ? this.props.className : ""
                } Account manager-list-table-block colored-th-table-block custom-table-block custom-table-block-2 first-col-fixed-block last-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align company-table`}
              >
                <DynamicTableWithSelectAllComponentNew
                  columnData={tableHeader}
                  rowData={this.state.rowData}
                  pagePrevious={this.pagePrevious}
                  pageNext={this.pageNext}
                  pageSize={this.state.pageSize}
                  pageNo={this.state.pageNo}
                  handlePage={this.handlePage}
                  handleSort={this.handleSort}
                  sortColumn={this.state.sortColumn}
                  sortOrder={this.state.sortOrder}
                  count={this.state.count}
                  rowAction={this.rowAction}
                  colSpan={tableHeader.filter((h) => h.visibility).length}
                  showActions={showActions}
                  isSelectAll={this.state.isSelectAll}
                  selectedValues={this.state.selectedValues}
                  handleSelectAll={this.handleSelectAll}
                  handleCheckBoxButtonClick={this.onCheckboxBtnClick}
                  showPagination={true}
                  paginationClass={"center-pagination custom-pagination-new"}
                  handlePageSize={this.handlePageSize}
                  checkBoxDisable={this.state.disableStatus}
                  statusId={this.state.statusId}
                />
              </div>

              {/* Tooltips for icons in table, don't delete */}
              {this.state.rowData.map((data, index) => {
                return (
                  <div key={index}>
                    <CustomReactTooltip
                      id={`tooltip-${Slugify(
                        constants.APPLICATION_ROUTE.COMPANY.LIST
                          .EDIT_COMPANY_TOOLTIP
                      )}-${data.id}`}
                      multiline={true}
                    />
                    <CustomReactTooltip
                      id={`tooltip-${Slugify(
                        constants.APPLICATION_ROUTE.COMPANY.LIST
                          .REMOVE_COMPANY_TOOLTIP
                      )}-${data.id}`}
                      multiline={true}
                    />
                  </div>
                );
              })}
              {this.state.dailogModal && (
                <DailogNew
                  isOpen={this.state.dailogModal}
                  accept={this.dailogModalAccept}
                  decline={this.dailogModalDecline}
                  header={this.state.dailogModalHeader}
                  content={this.state.dailogModalContent}
                  config={this.state.dailogModalConfig}
                  modalStyleType={this.state.dailogModalStyleType}
                />
              )}
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClients: (allClients) => {
      dispatch(clients(allClients));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCompanies);
