import React, { memo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";
import constants from "../Utils/constants";
import NotFound from "../Pages/NotFound";
import PrivateRoute from "../Routes/PrivateRoutes";
import PublicRoute from "../Routes/PublicRoutes";
import Login from "../Pages/Login";
import VerifyEmail from "../Pages/VerifyEmail";
import ResetPassword from "../Pages/ResetPassword";
import Layout from "../Pages/Layout";
import ForgotPassword from "../Pages/ForgotPassword";
import SingleSignOn from "../Components/SingleSignOn";
import MagicLinkVerification from "../Pages/MagicLinkUsers/MagicLinkVerification";

const SentryRoute = Sentry.withSentryRouting(Route);

const Routes = () => {
  return (
    <Switch>
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.LOGIN.ROUTE}
        exact
        component={Login}
      />
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.SSO.ROUTE}
        exact
        component={SingleSignOn}
      />
      <PublicRoute
        restricted={false}
        path={constants.APPLICATION_ROUTE.VERIFY_EMAIL.ROUTE}
        exact
        component={VerifyEmail}
      />
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE}
        exact
        component={ResetPassword}
      />
      <PublicRoute
        restricted={false}
        path={constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE}
        exact
        component={ForgotPassword}
      />
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.MAGIC_LINK_SELECTION.ROUTE}
        exact
        component={MagicLinkVerification}
      />
      <PrivateRoute
        acl={false}
        restricted={false}
        path="/"
        component={Layout}
      />
      <Redirect exact from="/" to={constants.APPLICATION_ROUTE.LOGIN.ROUTE} />
      <SentryRoute path="/not-found" exact component={NotFound} />
      <SentryRoute path="*" exact component={NotFound} />
    </Switch>
  );
};

export default memo(Routes);
