import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { DropdownList } from "react-widgets";
import "react-datepicker/dist/react-datepicker.css";

const AddEditCalendarModal = ({
  isOpen,
  toggle,
  buttonType,
  eventFormModel,
  formErrors,
  handleTitleChange,
  reportList,
  selectedReport,
  handleReportTypeChange,
  companiesList,
  handleCompanyChange,
  segmentList,
  handleSegmentChange,
  reportFileList,
  handleOtherReportChange,
  eventDate,
  setEventDate,
  quaterList,
  handleQuaterChange,
  selectedQuater,
  yearList,
  handleYearChange,
  selectedYear,
  selectedCompany,
  addCalendarEvent,
  updateCalendarEvent,
  constants,
  customErrorStyles,
  customStyles,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClosed={toggle}
      toggle={toggle}
      style={{ width: "480px" }}
      className={"modal-lg modal-w-header custom-modal primary-modal"}
    >
      <ModalHeader>
        <div className="create-event-modal-header">
          <h2>
            {buttonType === "update"
              ? "Edit Published Event"
              : "Add Published Event"}
          </h2>
          <span
            onClick={toggle}
            style={{ color: "#555555", float: "right", cursor: "pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        {/* Title Input */}
        <div className="web-create-event-title">
          <p>
            Title <span className={"mandatory"}>* </span>
          </p>
          <input
            className={
              formErrors.titleError ? "title-input-error" : "title-input"
            }
            type="text"
            value={eventFormModel?.title}
            placeholder="Enter title"
            onChange={handleTitleChange}
          />
          {formErrors.titleError && (
            <div className="red-error-msg">
              {constants.FORM.MANDATORY_FIELD}
            </div>
          )}
        </div>

        {/* Report Type Select */}
        <div className="web-create-event-title mt-3">
          <p>
            Report Type<span className={"mandatory"}>* </span>
          </p>
          <div className="react-select-div">
            <Select
              options={reportList}
              placeholder="Select a Report Type"
              styles={
                formErrors.reportTypeError ? customErrorStyles : customStyles
              }
              value={selectedReport}
              onChange={handleReportTypeChange}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
            />
            {formErrors.reportTypeError && (
              <div className="red-error-msg">
                {constants.FORM.MANDATORY_FIELD}
              </div>
            )}
          </div>
        </div>

        {/* Conditional Fields Based on Report Type */}
        {selectedReport && (
          <>
            {selectedReport?.label === "Company Research" ? (
              <>
                <div className="web-create-event-title mt-3">
                  <p>
                    Company<span className={"mandatory"}>* </span>
                  </p>
                  <div className="react-select-div">
                    <DropdownList
                      data={companiesList}
                      value={companiesList.find(
                        (company) =>
                          company.value === eventFormModel?.company_id
                      )}
                      onChange={handleCompanyChange}
                      placeholder={"Select company"}
                      valueField="value"
                      textField="label"
                      styles={
                        formErrors.companyError
                          ? customErrorStyles
                          : customStyles
                      }
                      filter="contains"
                    />
                    {formErrors.companyError && (
                      <div className="red-error-msg">
                        {constants.FORM.MANDATORY_FIELD}
                      </div>
                    )}
                  </div>
                </div>
                <div className="web-create-event-title mt-3">
                  <p>
                    Segment<span className={"mandatory"}>* </span>
                  </p>
                  <div className="react-select-div">
                    <DropdownList
                      data={segmentList}
                      value={eventFormModel?.segment_id}
                      onChange={handleSegmentChange}
                      placeholder={"Select Segment"}
                      valueField="value"
                      textField="label"
                      styles={
                        formErrors.segmentError
                          ? customErrorStyles
                          : customStyles
                      }
                      filter="contains"
                      disabled={
                        eventFormModel?.company_id !== selectedCompany.value
                      }
                    />
                    {formErrors.segmentError && (
                      <div className="red-error-msg">
                        {constants.FORM.MANDATORY_FIELD}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="web-create-event-title mt-3">
                <p>
                  Report<span className={"mandatory"}>* </span>
                </p>
                <div className="react-select-div">
                  <DropdownList
                    data={reportFileList}
                    value={reportFileList.find(
                      (item) => item.id === eventFormModel?.report_id
                    )}
                    onChange={handleOtherReportChange}
                    placeholder={"Select Report File"}
                    valueField="id"
                    textField="name"
                    styles={
                      formErrors.reportFileError
                        ? customErrorStyles
                        : customStyles
                    }
                    filter="contains"
                  />
                  {formErrors.reportFileError && (
                    <div className="red-error-msg">
                      {constants.FORM.MANDATORY_FIELD}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {/* Date Picker */}
        <div className="web-create-event-title mt-3">
          <p>
            Published Date
            <span className={"mandatory"}>* </span>
          </p>
          <div className="date-input">
            <DatePicker
              className="date-picker"
              dateFormat="MM/dd/yyyy"
              selected={eventDate}
              onChange={setEventDate}
              todayButton={"Today"}
            />
          </div>
        </div>

        {/* Quarter and Year Selects */}
        <div className="start-end-time-div mt-3">
          <div className="start-time">
            <p>
              Select Quarter
              <span className={"mandatory"}>* </span>
            </p>
            <div className="react-select-div">
              <Select
                options={quaterList}
                placeholder="Select an option"
                styles={
                  formErrors.quaterError ? customErrorStyles : customStyles
                }
                value={selectedQuater}
                onChange={handleQuaterChange}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
              />
              {formErrors.quaterError && (
                <div className="red-error-msg">
                  {constants.FORM.MANDATORY_FIELD}
                </div>
              )}
            </div>
          </div>
          <div className="end-time">
            <p>
              Select Year
              <span className={"mandatory"}>* </span>
            </p>
            <div className="react-select-div">
              <Select
                options={yearList}
                placeholder="Select an option"
                styles={formErrors.yearError ? customErrorStyles : customStyles}
                value={selectedYear}
                onChange={handleYearChange}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
              />
              {formErrors.yearError && (
                <div className="red-error-msg">
                  {constants.FORM.MANDATORY_FIELD}
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="published-event-modal-btn">
          <button className="btn-cancel" onClick={toggle}>
            Cancel
          </button>
          <button
            className="btn-save-draft ml-3"
            onClick={
              buttonType === "published"
                ? () => addCalendarEvent("draft")
                : () => updateCalendarEvent("draft")
            }
          >
            Save as Draft
          </button>
          <button
            onClick={
              buttonType === "published"
                ? () => addCalendarEvent("publish")
                : () => updateCalendarEvent("publish")
            }
            className="btn-publish ml-3"
          >
            Publish
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditCalendarModal;
