import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AccessDenied from "../Pages/AccessDenied";
import BenchmarkDetails from "../Pages/BenchmarkDetails";
import BenchmarkTransactionDetails from "../Pages/BenchmarkTransactionDetails";
import CompanyDetails from "../Pages/CompanyDetails";
import EmailBounce from "../Pages/EmailBounce";
import EmailSuppressionList from "../Pages/EmailSuppressionList";
import ListAdminUsers from "../Pages/ListAdminUsers";
import ListBenchmarks from "../Pages/ListBenchmarks";
import ListBenchmarkTrackSubmissions from "../Pages/ListBenchmarkTrackSubmissions";
import ListClientProjects from "../Pages/ListClientProjects";
import ListCompanies from "../Pages/ListCompanies";
import ListFileUploads from "../Pages/ListFileUploads";
import ListLobsAndSubCategories from "../Archive/ListLobsAndSubCategories";
import ListMarketForecasts from "../Pages/ListMarketForecasts";
import ListMarketForecastTrackSubmissions from "../Pages/ListMarketForecastTrackSubmissions";
import ListMasterGeography from "../Archive/ListMasterGeography";
import ListMetricTypesAndMetrics from "../Pages/ListMetricTypesAndMetrics";
import ListMyBenchmarkDrafts from "../Pages/ListMyBenchmarkDrafts";
import ListMyDrafts from "../Pages/ListMyDrafts";
import ListSpecialReports from "../Pages/ListSpecialReports";
import ListMyMarketForecastDrafts from "../Pages/ListMyMarketForecastDrafts";
import ListMyProjectDrafts from "../Pages/ListMyProjectDrafts";
import ListProjectTrackSubmissions from "../Pages/ListProjectTrackSubmissions";
import ListTemplates from "../Archive/ListTemplates";
import ListTrackSubmissions from "../Archive/ListTrackSubmissions";
import ListVerticalCategorySubCategories from "../Archive/ListVerticalCategorySubCategories";
import ManageAdminUser from "../Pages/ManageAdminUser";
import ManageBenchmark from "../Pages/ManageBenchmark";
import ManageClient from "../Pages/ManageClient";
import ManageClientProject from "../Pages/ManageClientProject";
import ManageClientUser from "../Pages/ManageClientUser";
import ManageCompany from "../Pages/ManageCompany";
import ManageMarketForecast from "../Pages/ManageMarketForecast";
import ManageRule from "../Pages/ManageRule";
import ManageTemplate from "../Pages/ManageTemplate";
import MarketForecastDetails from "../Pages/MarketForecastDetails";
import MarketForecastTransactionDetails from "../Pages/MarketForecastTransactionDetails";
import NotFound from "../Pages/NotFound";
import ProjectDetails from "../Pages/ProjectDetails";
import ProjectTransactionDetails from "../Pages/ProjectTransactionDetails";
import TransactionDetails from "../Pages/TransactionDetails";
import PrivateRoute from "../Routes/PrivateRoutes";
import constants from "../Utils/constants";
import Faqs from "./Faqs";
import Help from "./Help";
import ListMarketLandscapes from "./ListMarketLandscapes";
import ListMarketLandscapeTrackSubmissions from "./ListMarketLandscapeTrackSubmissions";
import ListMetricRecords from "./ListMetricRecords";
import ListMetricRulesAndThresholds from "./ListMetricRulesAndThresholds";
import ListMetricTrackSubmissions from "./ListMetricTrackSubmissions";
import ListTransactionRecords from "./ListTransactionRecords";
import ListEmailTemplates from "./ListEmailTemplates";
import ManageEmailTemplate from "./ManageEmailTemplate";
import ListMyMarketLandscapeDrafts from "./ListMyMarketLandscapeDrafts";
import ManageMarketLandscape from "./ManageMarketLandscape";
import MarketLandscapeDetails from "./MarketLandscapeDetails";
import MarketLandscapeTransactionDetails from "./MarketLandscapeTransactionDetails";
import Reports from "./Reports";
import Reports2 from "./Reports2";
import Reports3 from "./Reports3";
import Reports4 from "./Reports4";
import ListAccountManagers from "./ListAccountManagers";
import ListClientTabs from "./ListClientTabs";
import ListClientUsersTabs from "../Pages/ListClientUsersTabs";
import ManageSpecialReport from "./ManageSpecialReport";
import ListSpecialReportTrackSubmissions from "./ListSpecialReportTrackSubmissions";
import ListMySpecialReportDrafts from "./ListMySpecialReportDrafts";
import SpecialReportDetail from "./SpecialReportDetail";
import SpecialReportTransactionDetails from "./SpecialReportTransactionDetails";
import ReportsBoardsPage from "./ReportsBoardsPage";
import VisualizationReportsPage from "./VisualizationReportsPage";
import ReportsTbriPage from "./ReportsTbriPage";
import ListSearch from "./ListSearch";
import ListSearchTrends from "./ListSearchTrends";
import ListTaxanomies from "../Pages/ListTaxanomies";
import PreferenceCenter from "./PreferenceCenter";
import WebinarCalendar from "./WebinarCalendar";
import PublishedCalendar from "./PublishedCalendar";
import ListCustomerStudies from "./ListCustomerStudies";
import ManageCustomerStudies from "./ManageCustomerStudies";
import CustomerStudyDetails from "./CustomerStudyDetails";
import ListEcosystem from "./Ecosystem/ListEcosystem";
import ManageEcosystem from "./Ecosystem/ManageEcosystem";
import ListMyCustomerStudiesDrafts from "./ListMyCustomerStudiesDrafts";
import ListMyEcosystemDrafts from "./Ecosystem/ListMyEcosystemDrafts";
import ListCustomerStudyTrackSubmission from "./ListCustomerStudyTrackSubmission";
import ListEcosystemTrackSubmission from "./Ecosystem/ListEcosystemTrackSubmission";
import EcosystemDetails from "./Ecosystem/EcosystemDetails";
import EcosystemTransactionDetails from "././Ecosystem/EcosystemTransactionDetails";
import CustomerStudyTransactionDetails from "./CustomerStudyTransactionDetails";
import MetricsRulesTableView from "./MetricsRulesTableView";
import ListLobs from "./V2/ListLobs";
import ListVerticals from "./V2/ListVerticals";
import ListGeos from "./V2/ListGeos";
import ListMetricsRules from "./Metrics/ListMetricsRules";
import CreateMetricsRules from "./Metrics/CreateMetricsRules";
import EditMetricsRules from "./Metrics/EditMetricsRules";
import ListMetricRecordsV2 from "./V2/ListMetricRecordsV2";
import ListMetricRecordsV2Details from "./V2/ListMetricRecordsV2Details";
import ResourceAndFAQ from "../Pages/ResourceAndFAQ";
import ApiClientTracker from "./ApiClientTracker/ApiClientTracker";
import HistoricalFile from "./HistoricalFile";
import SegmentGroupTemplate from "./Metrics/SegmentGroupTemplate";
import CreateSegmentGroup from "./Metrics/CreateSegmentGroup";
import EditSegmentGroup from "./Metrics/EditSegmentGroup";
import ListMagicLinkUsers from "./MagicLinkUsers/ListMagicLinkUsers";
import ManageMagicUser from "./MagicLinkUsers/ManageMagicUser";
import MAGIC_LINK_CONSTANTS from "./MagicLinkUsers/utils/constants";

class Content extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.COMPANY_RESOURCE.TITLE}
          action={constants.ACL.COMPANY_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.COMPANY.MANAGE_CONTENT.ROUTE}
          exact
          component={CompanyDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MANAGE_CONTENT_MY_DRAFTS.ROUTE}
          exact
          component={ListMyDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRACK_SUBMISSIONS.ROUTE
          }
          exact
          component={ListTrackSubmissions}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={TransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT.LIST.ROUTE}
          exact
          component={ListClientTabs}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE}
          exact
          component={ListClientUsersTabs}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_EMAIL.LIST.ROUTE}
          exact
          component={EmailBounce}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_EMAIL_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_EMAIL_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_SUPPRESSION_EMAIL.LIST.ROUTE}
          exact
          component={EmailSuppressionList}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ACCESS_DENIED.ROUTE}
          exact
          component={AccessDenied}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT.CREATE.ROUTE}
          exact
          component={ManageClient}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT.EDIT.ROUTE}
          exact
          component={ManageClient}
        />

        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.ROUTE}
          exact
          component={ManageClientUser}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
          action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE}
          exact
          component={ManageClientUser}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.PROJECT_RESOURCE.TITLE}
          action={constants.ACL.PROJECT_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_PROJECT.LIST.ROUTE}
          exact
          component={ListClientProjects}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.PROJECT_RESOURCE.TITLE}
          action={constants.ACL.PROJECT_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_PROJECT.CREATE.ROUTE}
          exact
          component={ManageClientProject}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.PROJECT_RESOURCE.TITLE}
          action={constants.ACL.PROJECT_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_PROJECT.EDIT.ROUTE}
          exact
          component={ManageClientProject}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.PROJECT_RESOURCE.TITLE}
          action={constants.ACL.PROJECT_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CLIENT_PROJECT.MANAGE.ROUTE}
          exact
          component={ProjectDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_PROJECT_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={ProjectTransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.PROJECT_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MANAGE_CONTENT_PROJECT_DRAFTS.ROUTE}
          exact
          component={ListMyProjectDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.PROJECT_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_PROJECT_TRACK_SUBMISSIONS
              .ROUTE
          }
          exact
          component={ListProjectTrackSubmissions}
        />

        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.COMPANY_RESOURCE.TITLE}
          action={constants.ACL.COMPANY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE}
          exact
          component={ListCompanies}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.COMPANY_RESOURCE.TITLE}
          action={constants.ACL.COMPANY_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.COMPANY.CREATE.ROUTE}
          exact
          component={ManageCompany}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.COMPANY_RESOURCE.TITLE}
          action={constants.ACL.COMPANY_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.COMPANY.EDIT.ROUTE}
          exact
          component={ManageCompany}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
          action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TBRI_USER.LIST.ROUTE}
          exact
          component={ListAdminUsers}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
          action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TBRI_USER.CREATE.ROUTE}
          exact
          component={ManageAdminUser}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
          action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TBRI_USER.EDIT.ROUTE}
          exact
          component={ManageAdminUser}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.BENCHMARK_RESOURCE.TITLE}
          action={constants.ACL.BENCHMARK_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE}
          exact
          component={ListBenchmarks}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.BENCHMARK_RESOURCE.TITLE}
          action={constants.ACL.BENCHMARK_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.BENCHMARK.CREATE.ROUTE}
          exact
          component={ManageBenchmark}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.BENCHMARK_RESOURCE.TITLE}
          action={constants.ACL.BENCHMARK_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.BENCHMARK.EDIT.ROUTE}
          exact
          component={ManageBenchmark}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.BENCHMARK_RESOURCE.TITLE}
          action={constants.ACL.BENCHMARK_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.BENCHMARK.MANAGE.ROUTE}
          exact
          component={BenchmarkDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_BENCHMARK_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={BenchmarkTransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_BENCHMARK_MY_DRAFTS.ROUTE
          }
          exact
          component={ListMyBenchmarkDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.BENCHMARK_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_BENCHMARK_TRACK_SUBMISSIONS.ROUTE
          }
          exact
          component={ListBenchmarkTrackSubmissions}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.SPECIAL_REPORT_RESOURCE.TITLE}
          action={constants.ACL.SPECIAL_REPORT_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SPECIAL_REPORT.LIST.ROUTE}
          exact
          component={ListSpecialReports}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.SPECIAL_REPORT_RESOURCE.TITLE}
          action={constants.ACL.SPECIAL_REPORT_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SPECIAL_REPORT.CREATE.ROUTE}
          exact
          component={ManageSpecialReport}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.SPECIAL_REPORT_RESOURCE.TITLE}
          action={constants.ACL.SPECIAL_REPORT_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SPECIAL_REPORT.EDIT.ROUTE}
          exact
          component={ManageSpecialReport}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.SPECIAL_REPORT_RESOURCE.TITLE}
          action={constants.ACL.SPECIAL_REPORT_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SPECIAL_REPORT.MANAGE.ROUTE}
          exact
          component={SpecialReportDetail}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_SPECIAL_REPORT_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={SpecialReportTransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_SPECIAL_REPORT_MY_DRAFTS
              .ROUTE
          }
          exact
          component={ListMySpecialReportDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.SPECIAL_REPORT_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_SPECIAL_REPORT_TRACK_SUBMISSIONS.ROUTE
          }
          exact
          component={ListSpecialReportTrackSubmissions}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
          action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.DOWNLOAD.LIST.ROUTE}
          exact
          component={ListFileUploads}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_FORECAST_RESOURCE.TITLE}
          action={constants.ACL.MARKET_FORECAST_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE}
          exact
          component={ListMarketForecasts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_FORECAST_RESOURCE.TITLE}
          action={constants.ACL.MARKET_FORECAST_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_FORECAST.CREATE.ROUTE}
          exact
          component={ManageMarketForecast}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_FORECAST_RESOURCE.TITLE}
          action={constants.ACL.MARKET_FORECAST_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_FORECAST.EDIT.ROUTE}
          exact
          component={ManageMarketForecast}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_FORECAST_RESOURCE.TITLE}
          action={constants.ACL.MARKET_FORECAST_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_FORECAST.MANAGE.ROUTE}
          exact
          component={MarketForecastDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_FORECAST_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_MARKET_FORECAST_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={MarketForecastTransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_FORECAST_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_MARKET_FORECAST_MY_DRAFTS
              .ROUTE
          }
          exact
          component={ListMyMarketForecastDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_FORECAST_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_MARKET_FORECAST_TRACK_SUBMISSIONS.ROUTE
          }
          exact
          component={ListMarketForecastTrackSubmissions}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
          action={constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE}
          exact
          component={ListMarketLandscapes}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
          action={constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.CREATE.ROUTE}
          exact
          component={ManageMarketLandscape}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
          action={constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.EDIT.ROUTE}
          exact
          component={ManageMarketLandscape}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
          action={constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.MANAGE.ROUTE}
          exact
          component={MarketLandscapeDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_LANDSCAPE_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_MARKET_LANDSCAPE_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={MarketLandscapeTransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_LANDSCAPE_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_MARKET_LANDSCAPE_MY_DRAFTS.ROUTE
          }
          exact
          component={ListMyMarketLandscapeDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_LANDSCAPE_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_MARKET_LANDSCAPE_TRACK_SUBMISSIONS.ROUTE
          }
          exact
          component={ListMarketLandscapeTrackSubmissions}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.METRIC_TYPE_RESOURCE.TITLE}
          action={constants.ACL.METRIC_TYPE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MANAGE_METRIC_TYPE_AND_DATA.ROUTE}
          exact
          component={ListMetricTypesAndMetrics}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.LOB_RESOURCE.TITLE}
          action={constants.ACL.LOB_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MANAGE_LOB_AND_SUB_CATEGORIES.ROUTE}
          exact
          component={ListLobs}
        />

        {/* <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.VERTICAL_RESOURCE.TITLE}
          action={constants.ACL.VERTICAL_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_VERTICAL_AND_CATEGORIES
              .ARCHIVE_ROUTE
          }
          exact
          component={ListVerticalCategorySubCategories}
        /> */}
        {/* <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.GEOGRAPHY_RESOURCE.TITLE}
          action={constants.ACL.GEOGRAPHY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY
              .ARCHIVE_ROUTE
          }
          exact
          component={ListMasterGeography}
        /> */}

        {/* <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.LOB_RESOURCE.TITLE}
          action={constants.ACL.LOB_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_LOB_AND_SUB_CATEGORIES
              .ARCHIVE_ROUTE
          }
          exact
          component={ListLobsAndSubCategories}
        /> */}
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.VERTICAL_RESOURCE.TITLE}
          action={constants.ACL.VERTICAL_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_VERTICAL_AND_CATEGORIES.ROUTE
          }
          exact
          component={ListVerticals}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.GEOGRAPHY_RESOURCE.TITLE}
          action={constants.ACL.GEOGRAPHY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_GEOGRAPHY_REGION_COUNTRY.ROUTE
          }
          exact
          component={ListGeos}
        />
        {/* <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.LIST.ROUTE}
          exact
          component={ListTemplates}
        /> */}
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TEMPLATE.LIST.ROUTE}
          exact
          component={ListMetricsRules}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_METRIC_TRACK_SUBMISSIONS
              .ROUTE
          }
          exact
          component={ListMetricTrackSubmissions}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_METRIC_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={ListTransactionRecords}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.METRIC_RECORD.LIST.ROUTE}
          exact
          component={ListMetricRecords}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.METRIC_RECORD_V2.LIST.ROUTE}
          exact
          component={ListMetricRecordsV2}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.METRIC_RECORD_V2.VIEW.ROUTE}
          exact
          component={ListMetricRecordsV2Details}
        />

        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ARCHIVE_TEMPLATE.CREATE.ROUTE}
          exact
          component={ManageTemplate}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TEMPLATE.CREATE.ROUTE}
          exact
          component={CreateMetricsRules}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TEMPLATE_METRICS_VIEW.ROUTE}
          exact
          component={MetricsRulesTableView}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TEMPLATE.EDIT.ROUTE}
          exact
          component={EditMetricsRules}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_METRIC_RULES_AND_THRESHOLDS.ROUTE
          }
          exact
          component={ListMetricRulesAndThresholds}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.METRIC_RULE.CREATE.ROUTE}
          exact
          component={ManageRule}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.TEMPLATE_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.METRIC_RULE.EDIT.ROUTE}
          exact
          component={ManageRule}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.FAQS.LIST.ROUTE}
          exact
          component={Faqs}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.HELP.LIST.ROUTE}
          exact
          component={Help}
        />
        <PrivateRoute
          acl={true}
          restricted={false}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.EMAIL_TEMPLATE_RESOURCE.TITLE}
          action={constants.ACL.EMAIL_TEMPLATE_RESOURCE.ACTIONS.LIST}
          path={constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.LIST.ROUTE}
          exact
          component={ListEmailTemplates}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.EMAIL_TEMPLATE.EDIT.ROUTE}
          exact
          component={ManageEmailTemplate}
        />
        <PrivateRoute
          acl={false}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
          action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.REPORTS.LIST.ROUTE}
          exact
          component={Reports}
        />
        <PrivateRoute
          acl={false}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
          action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.REPORTS.LIST2.ROUTE}
          exact
          component={Reports2}
        />
        <PrivateRoute
          acl={false}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
          action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.REPORTS.LIST3.ROUTE}
          exact
          component={Reports3}
        />
        <PrivateRoute
          acl={false}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TBRI_USER_RESOURCE.TITLE}
          action={constants.ACL.TBRI_USER_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.REPORTS.LIST4.ROUTE}
          exact
          component={Reports4}
        />
        <PrivateRoute
          acl={true}
          restricted={false}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.ACCOUNT_MANAGERS_RESOURCE.TITLE}
          action={constants.ACL.ACCOUNT_MANAGERS_RESOURCE.ACTIONS.LIST}
          path={constants.APPLICATION_ROUTE.ACCOUNT_MANAGERS.LIST.ROUTE}
          exact
          component={ListAccountManagers}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.REPORTS.BOARDS.ROUTE}
          exact
          component={ReportsBoardsPage}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.REPORTS.VISUALIZATION_TEMPLATES.ROUTE
          }
          exact
          component={VisualizationReportsPage}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.REPORTS.REPORTS_TBRI.ROUTE}
          exact
          component={ReportsTbriPage}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SEARCH_TREND.LIST.ROUTE}
          exact
          component={ListSearchTrends}
        />
        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.API_CLIENT_TRACER.LIST.ROUTE}
          exact
          component={ApiClientTracker}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TAXONOMY_RESOURCE.TITLE}
          action={constants.ACL.TAXONOMY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE}
          exact
          component={ListTaxanomies}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CUSTOMER_STUDY_RESOURCE.TITLE}
          action={constants.ACL.CUSTOMER_STUDY_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.CREATE.ROUTE}
          exact
          component={ManageCustomerStudies}
        />

        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CUSTOMER_STUDY_RESOURCE.TITLE}
          action={constants.ACL.CUSTOMER_STUDY_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.EDIT.ROUTE}
          exact
          component={ManageCustomerStudies}
        />

        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.CUSTOMER_STUDY_RESOURCE.TITLE}
          action={constants.ACL.CUSTOMER_STUDY_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.MANAGE.ROUTE}
          exact
          component={CustomerStudyDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TAXONOMY_RESOURCE.TITLE}
          action={constants.ACL.TAXONOMY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE}
          exact
          component={ListCustomerStudies}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.CUSTOMER_STUDY_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_CUSTOMER_STUDY_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={CustomerStudyTransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TAXONOMY_RESOURCE.TITLE}
          action={constants.ACL.TAXONOMY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_CUSTOMER_STUDIES_MY_DRAFTS.ROUTE
          }
          exact
          component={ListMyCustomerStudiesDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_FORECAST_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_CUSTOMER_STUDIES_TRACK_SUBMISSIONS.ROUTE
          }
          exact
          component={ListCustomerStudyTrackSubmission}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TAXONOMY_RESOURCE.TITLE}
          action={constants.ACL.TAXONOMY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE}
          exact
          component={ListEcosystem}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.ECOSYSTEM_RESOURCE.TITLE}
          action={constants.ACL.ECOSYSTEM_RESOURCE.ACTIONS.CREATE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ECOSYSTEM.CREATE.ROUTE}
          exact
          component={ManageEcosystem}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.ECOSYSTEM_RESOURCE.TITLE}
          action={constants.ACL.ECOSYSTEM_RESOURCE.ACTIONS.MANAGE}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ECOSYSTEM.MANAGE.ROUTE}
          exact
          component={EcosystemDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.ECOSYSTEM_RESOURCE.TITLE}
          action={constants.ACL.ECOSYSTEM_RESOURCE.ACTIONS.EDIT}
          restricted={false}
          path={constants.APPLICATION_ROUTE.ECOSYSTEM.EDIT.ROUTE}
          exact
          component={ManageEcosystem}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.ECOSYSTEM_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_ECOSYSTEM_TRANSACTION_DETAILS.ROUTE
          }
          exact
          component={EcosystemTransactionDetails}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.ECOSYSTEM_RESOURCE.TITLE}
          action={constants.ACL.ECOSYSTEM_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE.MANAGE_CONTENT_ECOSYSTEM_MY_DRAFTS.ROUTE
          }
          exact
          component={ListMyEcosystemDrafts}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.MANAGE_CONTENT_PERMISSIONS.MARKET_FORECAST_ENTITY}
          action={constants.MANAGE_CONTENT_PERMISSIONS.MANAGE.SLUG}
          restricted={false}
          path={
            constants.APPLICATION_ROUTE
              .MANAGE_CONTENT_ECOSYSTEM_TRACK_SUBMISSIONS.ROUTE
          }
          exact
          component={ListEcosystemTrackSubmission}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TAXONOMY_RESOURCE.TITLE}
          action={constants.ACL.TAXONOMY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CALENDAR_WEBINAR.LIST.ROUTE}
          exact
          component={WebinarCalendar}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.TAXONOMY_RESOURCE.TITLE}
          action={constants.ACL.TAXONOMY_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.CALENDAR_PUBLISHED.LIST.ROUTE}
          exact
          component={PublishedCalendar}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.MANAGE_PREFERENCES.ROUTE}
          exact
          component={PreferenceCenter}
        />

        <PrivateRoute
          acl={false}
          restricted={false}
          path={constants.APPLICATION_ROUTE.RESOURCE_AND_FAQ.ROUTE}
          exact
          component={ResourceAndFAQ}
        />
        <PrivateRoute acl={false} path="/search" exact component={ListSearch} />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
          action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.HISTORICAL_FILE.LIST.ROUTE}
          exact
          component={HistoricalFile}
        />
        {/* comment for prod release */}
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
          action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.LIST.ROUTE}
          exact
          component={SegmentGroupTemplate}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
          action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.CREATE.ROUTE}
          exact
          component={CreateSegmentGroup}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
          action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.LIST}
          restricted={false}
          path={constants.APPLICATION_ROUTE.SEGMENT_GROUP_TEMPLATE.EDIT.ROUTE}
          exact
          component={EditSegmentGroup}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE}
          action={
            MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS.LIST
          }
          restricted={false}
          path={MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.ROUTE}
          exact
          component={ListMagicLinkUsers}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE}
          action={
            MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS.CREATE
          }
          restricted={false}
          path={MAGIC_LINK_CONSTANTS.MAGIC_LINK.CREATE.ROUTE}
          exact
          component={ManageMagicUser}
        />
        <PrivateRoute
          acl={true}
          type={constants.ACL.PERMISSION_KEY}
          entity={MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE}
          action={
            MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS.EDIT
          }
          restricted={false}
          path={MAGIC_LINK_CONSTANTS.MAGIC_LINK.EDIT.ROUTE}
          exact
          component={ManageMagicUser}
        />
        <Redirect
          exact
          from="/"
          to={constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE}
        />
        <Route path="*" exact component={NotFound} />
      </Switch>
    );
  }
}

export default Content;
