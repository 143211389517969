import React from "react";
import Copy from "../Utils/Copy";
import Toast from "../Utils/Toast";

const CopyInput = ({
  placeholder,
  value,
  fontSize = "12px",
  inputClass = "",
  buttonClass = "",
  inputId,
  readonly,
  divCustomClass,
  onChange,
}) => {
  return (
    <div className={`input-group ${divCustomClass}`}>
      <input
        type="text"
        style={{ fontSize }}
        className={`form-control border-right-0 ${inputClass}`}
        aria-describedby="button-addon2"
        id={inputId}
        value={value}
        placeholder={placeholder}
        readOnly={readonly ?? false}
        onChange={(e) => onChange && onChange(e.target.value)} // Calling onChange with the new value
      />
      <div className="input-group-append">
        <button
          className={`material-icons-outlined copy-icon border-left-0 ${buttonClass}`}
          type="button"
          id="button-addon2"
          onClick={() => {
            Copy(value);
            Toast("Copied Successfully", "success");
          }}
        >
          content_copy
        </button>
      </div>
    </div>
  );
};

export default CopyInput;
