import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import {
  ButtonToggle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Notification from "../Components/Notification";
import logo from "../Images/INSIGHTCENTER@2x.png";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import RegisterSSO from "../Archive/RegisterSSO";
import PasswordChecklist from "react-password-checklist";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [apiErrors, setApiErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isChecklistValid, setIsChecklistValid] = useState(false);

  const history = useHistory();
  const { code } = useParams(); // Accessing dynamic route parameters

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password && confirmPassword) {
      try {
        await UserServices.resetPassword({
          code,
          password,
          confirm_password: confirmPassword,
        });
        setSuccessMessage(constants.RESET_PASSWORD.SUCCESS);
        redirectToLogin();
      } catch (error) {
        setApiErrors(
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG
        );
      }
    }
  };

  const redirectToLogin = () => {
    setTimeout(
      () =>
        history.push(formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})),
      3000
    );
  };

  const getCodeExpiryVerification = async () => {
    try {
      await UserServices.codeVerification(code);
    } catch (error) {
      setApiErrors(error?.data?.error || constants.ERROR.CODE_EXPIRED);
      setTimeout(
        () =>
          history.push(
            formatRoute(constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE, {})
          ),
        4000
      );
    }
  };

  useEffect(() => {
    DocumentTitle(constants.RESET_PASSWORD.HEADER_TITLE);
    if (code) {
      getCodeExpiryVerification();
    }
  }, [code]);

  return (
    <div className="wrapper login_page">
      <main>
        <Container fluid>
          <Row>
            <aside className="col-md-6 px-0 hide-in-mobille">
              <div className="login_left">
                <figure className="login_banner">
                  <img
                    src={login_building}
                    className="img-fluid"
                    alt="login banner"
                    title="login banner"
                  />
                </figure>
                <a href={process.env.PUBLIC_URL + "/"}>
                  <img
                    style={{ width: "120px", marginLeft: "20px" }}
                    src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                    alt="TBR logo"
                  />
                </a>
              </div>
            </aside>
            <aside className="col-md-6 px-0">
              <div className="login_right">
                <figure>
                  <img src={login_map} className="img-fluid" alt="map Banner" />
                </figure>
                <Form
                  onSubmit={handleSubmit}
                  method="post"
                  className={`form-wrapper reset-password`}
                >
                  <span className="login_formlogos">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </span>
                  {apiErrors && (
                    <Notification color={"danger"} message={apiErrors} />
                  )}
                  {successMessage && (
                    <Notification color={"success"} message={successMessage} />
                  )}
                  <FormGroup>
                    <h1 className="reset_password_header">
                      {constants.RESET_PASSWORD.HEADER_TITLE}
                    </h1>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="password">Password</Label>
                    <Input
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="confirmPassword">Confirm Password</Label>
                    <Input
                      type="password"
                      placeholder="Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "match",
                        "notEmpty",
                        "lowercase",
                      ]}
                      minLength={8}
                      value={password}
                      iconSize={12}
                      valueAgain={confirmPassword}
                      onChange={(isValid) => setIsChecklistValid(isValid)}
                      style={{ color: "white", marginTop: "20px" }}
                      className={"set-pwd-form"}
                    />
                  </FormGroup>
                  <FormGroup className="m-0">
                    <ButtonToggle
                      color="primary"
                      type="submit"
                      disabled={!isChecklistValid}
                      className="mb-1"
                    >
                      Save
                    </ButtonToggle>
                  </FormGroup>
                </Form>
              </div>
            </aside>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default ResetPassword;
