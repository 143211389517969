import React, { Component } from "react";
import constants from "../Utils/constants";
import CustomPagination from "./Pagination";

class DynamicTableComponentNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  totalPages = () => {
    if (this.props.count === 0) {
      return 1;
    }
    return Math.ceil(this.props.count / this.props.pageSize);
  };
  entireRowAction = (row, e) => {
    if (this.props.entireRowAction && this.props.entireRowAction.title) {
      this.props.rowAction(this.props.entireRowAction.title, row, e);
    }
  };
  handlePageSize = (e) => {
    this.props.handlePageSize(e);
  };

  render() {
    return (
      <>
        <div
          className={`responsive-table scroll-table-wrapper ${
            this.props.customClass ? this.props.customClass : ""
          }`}
        >
          <table
            className={`nowrap responsive ${
              this.props.className ? this.props.className : ""
            }`}
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr>
                {this.props.statusId !== 3 && this.props.checkboxpermission && (
                  <th>
                    <input
                      className="custom-checkbox-new"
                      type="checkbox"
                      name="isSelectAll"
                      disabled={this.props.checkBoxDisable}
                      value={this.props.isSelectAll}
                      checked={this.props.isSelectAll}
                      onClick={(e) => this.props.handleSelectAll(e)}
                      style={{ verticalAlign: "middle" }}
                    />
                  </th>
                )}
                {this.props.columnData.map(
                  (column, key) =>
                    column.visibility && (
                      <th
                        key={key}
                        className={`${
                          this.props.sortColumn === column.id
                            ? this.props.sortOrder
                            : column.sortable
                            ? "default"
                            : null
                        } ${column.headerClass ? column.headerClass : null} ${
                          column.sortable ? "sort-column" : ""
                        }`}
                        onClick={() => {
                          column.sortable &&
                            this.props.handleSort(column.id, key);
                        }}
                        style={column.headerStyle}
                      >
                        <div className="th-content">{column.title}</div>
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {this.props.rowData.length > 0 &&
                this.props.rowData.map((row, index) => (
                  <>
                    <tr
                      key={row.id}
                      onClick={(e) => {
                        this.entireRowAction(row, e);
                      }}
                    >
                      {this.props.columnData.map(
                        (val, i) =>
                          val.visibility && (
                            <td
                              key={`${index + "-" + i}`}
                              style={val.rowStyle}
                              className={val.rowClass ? val.rowClass : null}
                            >
                              {row[val.id]}
                            </td>
                          )
                      )}
                    </tr>
                  </>
                ))}
              {this.props.rowData.length === 0 && (
                <tr>
                  <td
                    className={`no-records text-center`}
                    colSpan={this.props.colSpan}
                  >
                    {constants.PAGINATION.NO_RECORDS_FOUND}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {this.props.showPagination && (
          <>
            <CustomPagination
              handlePage={this.props.handlePage}
              totalPages={this.totalPages}
              handlePageSize={this.handlePageSize}
              pageSize={this.props.pageSize}
              pageNo={this.props.pageNo}
              className={`${this.props.paginationClass}`}
            />
          </>
        )}
      </>
    );
  }
}

export default DynamicTableComponentNew;
