import React, { useEffect, useState } from "react";
import constants from "../../Utils/constants";
import { useDispatch } from "react-redux";
import { activeMenuTab } from "../../Store/Actions/User";
import CustomDropdownList from "../../Components/CustomDropdownList";
import Search from "../../Components/Search";
import { ClientServices } from "../../Services/Client";
import Toast from "../../Utils/Toast";
import DynamicTableWithSelectAllComponentNew from "../../Components/DynamicTableWithSelectAllNew";
import IsTBRIClient from "../../Utils/IsTBRIClient";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import { NavLink as RRNavLink } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import { useHistory } from "react-router";
import permissionCheck from "../../lib/permissionCheck";
import { MagicLinkUserServices } from "../../Services/MagicLinkUserServices";
import AclAction from "../../Components/ACL/AclAction";
import decodeJWT from "../../lib/decodeJWT";
import Slugify from "../../Utils/Slugify";
import Dailog from "../../Components/DailogNew";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import { TableHeader } from "./utils/TableHeader";
import Copy from "../../Utils/Copy";
import MAGIC_LINK_CONSTANTS from "./utils/constants";
import { formatHoursToReadableTime } from "../../Utils/helper";
import { NavLink } from "reactstrap";

const ListMagicLinkUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // states intialization
  const [rowData, setrowData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [pageNo, setPageNo] = useState(constants.PAGINATION.DEFAULT_PAGE_NO);
  const [pageSize, setPageSize] = useState(
    constants.PAGINATION.DEFAULT_PAGE_SIZE
  );
  const [sortOrder, setSortOrder] = useState(
    constants.PAGINATION.COMPANY_DEFAULT_SORT_ORDER
  );
  const [sortField, setsortField] = useState("created_at");
  const [searchNameEmail, setSearchNameEmail] = useState(null);
  const [clients, setClients] = useState([
    { id: "all", name: "All Clients", is_selected: true },
  ]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [status, setStatus] = useState([
    { id: 3, name: "All Status", is_selected: true },
    { id: 1, name: "Active", is_selected: false },
    { id: 4, name: "Expired", is_selected: false },
    { id: 2, name: "Pending", is_selected: false },
  ]);

  const [modalState, setModalState] = useState({
    dailogModal: false,
    dailogModalHeader: null,
    dailogModalConfig: { type: null },
    dailogModalStyleType: "",
    dailogModalContent: <></>,
    submitButtonLabel: "Confirm",
  });

  useEffect(() => {
    getAllClients(); // Runs only once when the component mounts
  }, []);

  useEffect(() => {
    if (pageLoading) {
      getTableData();
      dispatch(activeMenuTab(MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.ROUTE));
    }
  }, [pageLoading]); // Empty dependency array ensures it runs once

  // Fetching All the clients details
  const getAllClients = () => {
    ClientServices.getAllClients()
      .then((res) => {
        let response = res?.data?.data || [];
        response = response
          .filter((client) => !IsTBRIClient(client))
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
        // Add the "All Clients" option at the beginning
        const allClientsOption = {
          id: "all",
          name: "All Clients",
          is_selected: true,
        };
        const updatedClients = [
          allClientsOption,
          ...response.map((client) => ({ ...client, is_selected: false })),
        ];
        let totalClients = clients.concat(response);
        totalClients.forEach((r) => {
          r.is_selected = String(r.id) === selectedClientId ? true : false;
        });
        let clientid = totalClients.find((c) => c.is_selected === true)
          ? totalClients.find((c) => c.is_selected === true).id
          : "all";

        setSelectedClientId(clientid);
        setClients(updatedClients);
      })
      .catch((error) => {
        Toast(error?.data?.error || "Something went wrong", "error");
      });
  };

  const getTableData = () => {
    let queryParams = "";
    if (sortOrder) {
      // queryParams = "?sort_column=" + sortField + "&sort_order=" + sortOrder;
      queryParams = "&sort_order=" + sortOrder + "&sort_column=" + sortField;
    }
    // Check if searchNameEmail has a value and add it to the queryParams
    if (searchNameEmail && searchNameEmail.trim() !== "") {
      queryParams += `&user_name=${encodeURIComponent(searchNameEmail)}`;
    }
    // Add client_id to query params if available
    if (selectedClientId && selectedClientId !== "all") {
      queryParams += `&client_id=${encodeURIComponent(selectedClientId)}`;
    }
    const selectedStatus = status.find((c) => c.is_selected);
    const statusId = selectedStatus?.id || null;
    if (selectedStatus?.name !== "All Status") {
      queryParams += `&status=${statusId === 4 ? "expired" : statusId}`;
    }

    MagicLinkUserServices.getAllMagicLinkUsers(
      `?hide_tbri_users=1&page=` +
        pageNo +
        "&page_size=" +
        pageSize +
        queryParams
    )
      .then((res) => {
        let tokenDetails = decodeJWT(localStorage.getItem("token"));
        let isAccess =
          tokenDetails.role &&
          ["admin", "account_manager", "sales_admin"].includes(
            tokenDetails.role.short_name
          );
        let clientApiData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        clientApiData = clientApiData.map((data) => {
          return {
            id: data?.id,
            name:
              (isAccess || tokenDetails.role.short_name !== "editor") &&
              data?.first_name ? (
                <NavLink
                  tag={RRNavLink}
                  style={{
                    padding: 0,
                    color:
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK_USER_COLOR[
                        data.is_active
                      ],
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                  exact
                  to={formatRoute(MAGIC_LINK_CONSTANTS.MAGIC_LINK.EDIT.ROUTE, {
                    id: data.id,
                  })}
                >
                  <label className={`mb-0`}>{`${
                    data.first_name ? data.first_name : ""
                  } ${data.last_name ? data.last_name : ""}`}</label>
                </NavLink>
              ) : (
                <span>{`${data.first_name ? data.first_name : ""} ${
                  data.last_name ? data.last_name : ""
                }`}</span>
              ),
            email: (
              <span
                style={{
                  color:
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_USER_COLOR[data.is_active],
                }}
              >
                {data.email}
              </span>
            ),
            role: data?.role?.name,
            client: data?.client?.name,
            created_by: data.created_by_user
              ? `${
                  data.created_by_user.first_name
                    ? data.created_by_user.first_name
                    : ""
                } ${
                  data.created_by_user.last_name
                    ? data.created_by_user.last_name
                    : ""
                }`
              : "-",
            last_login_at: data?.last_login_datetime
              ? ComapnyDetailsDateTimeFormatter(data.last_login_datetime, false)
              : "-",
            created_at: data.created_at
              ? ComapnyDetailsDateTimeFormatter(data.created_at, false)
              : ComapnyDetailsDateTimeFormatter(new Date(), false),
            expires_on: data.expiration_time
              ? formatHoursToReadableTime(data.expiration_time)
              : "Unlimited",
            is_active:
              data.is_active === 0 || data.is_active === 1 ? (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
                  }
                  action={
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.STATUS
                  }
                >
                  <div className="custom-control custom-switch success-toggle">
                    <input
                      type="checkbox"
                      disabled={!isAccess}
                      className="custom-control-input"
                      id={`customSwitch${data.id}`}
                      checked={data.is_active === 1}
                      onChange={(e) => {
                        e.stopPropagation();
                        updateMagicLinkUserStatus(data);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`customSwitch${data.id}`}
                    />
                  </div>
                </AclAction>
              ) : (
                <span>Pending</span>
              ),
            actions: getActions(data),
          };
        });
        setrowData(clientApiData);
        setCount(count);
        setPageLoading(false);
      })
      .catch((error) => {
        setrowData([]);
        setCount(0);
        console.error(
          "Getting error while fetching get all client api data",
          error
        );
      });
  };

  const getActions = (data) => {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let isAccess =
      tokenDetails.role &&
      ["admin", "editor", "sales_admin"].includes(tokenDetails.role.short_name);
    let isAdmin = tokenDetails.role && tokenDetails.role.short_name === "admin";
    return (
      <>
        {data.is_active === 1 ? (
          <>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
              }
              action={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS
                  .LIST
              }
            >
              <button
                disabled={!isAccess}
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .VALIDATE_USER_TOOL_TIP
                )}-${data.id}`}
                onClick={(e) => {
                  rowAction(
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST.VALIDATE_USERS,
                    data,
                    e
                  );
                }}
                className="btn btn-md d-inline-flex align-items-center"
                data-tip={
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .VALIDATE_USER_TOOL_TIP
                }
                data-iscapture="true"
              >
                <span className="material-icons-outlined text-blue-clr icon-fs-20">
                  person_outline
                </span>
              </button>
            </AclAction>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
              }
              action={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS
                  .STATUS
              }
            >
              <button
                disabled={!isAccess}
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK_TOOLTIP
                )}-${data.id}`}
                onClick={(e) => {
                  rowAction(
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK,
                    data,
                    e
                  );
                }}
                className={"btn btn-md d-inline-flex align-items-center"}
                data-tip={
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK_TOOLTIP
                }
                data-iscapture="true"
              >
                <span className="material-icons-round text-blue-clr icon-fs-20">
                  send
                </span>
              </button>
            </AclAction>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
              }
              action={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS
                  .DELETE
              }
            >
              <button
                disabled={!isAdmin}
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .DELETE_CLIENT_USER
                )}-${data.id}`}
                onClick={(e) => {
                  rowAction(
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.DELETE_MAGIC_LINK_USER,
                    data,
                    e
                  );
                }}
                className={"btn btn-md d-inline-flex align-items-center"}
                data-tip={
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .DELETE_CLIENT_USER
                }
                data-iscapture="true"
              >
                <span className="material-icons-round text-red icon-fs-20">
                  delete
                </span>
              </button>
            </AclAction>
          </>
        ) : (
          <>
            {data.is_active !== 2 && data.password ? (
              <>
                <div style={{ width: "100%" }}>-</div>
              </>
            ) : null}
          </>
        )}
        {data.is_active === 2 && (
          <>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
              }
              action={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS
                  .STATUS
              }
            >
              <button
                disabled={!isAccess}
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.COPY_MAGIC_LINK_TOOLTIP
                )}-${data.id}`}
                onClick={(e) => {
                  rowAction(
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                      .COPY_EMAIL_VERIFICATION_TOOLTIP,
                    data,
                    e
                  );
                }}
                className={"btn btn-md d-inline-flex align-items-center"}
                data-tip={
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.COPY_MAGIC_LINK_TOOLTIP
                }
                data-iscapture="true"
              >
                <span className="material-icons-round text-blue-clr icon-fs-20">
                  add_link
                </span>
              </button>
            </AclAction>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
              }
              action={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS
                  .STATUS
              }
            >
              <button
                disabled={!isAccess}
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK_TOOLTIP
                )}-${data.id}`}
                onClick={(e) => {
                  rowAction(
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK,
                    data,
                    e
                  );
                }}
                className={"btn btn-md d-inline-flex align-items-center"}
                data-tip={
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK_TOOLTIP
                }
                data-iscapture="true"
              >
                <span className="material-icons-round text-blue-clr icon-fs-20">
                  send
                </span>
              </button>
            </AclAction>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE
              }
              action={
                MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.ACTIONS
                  .DELETE
              }
            >
              <button
                disabled={!isAdmin}
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                data-for={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .DELETE_CLIENT_USER
                )}-${data.id}`}
                onClick={(e) => {
                  rowAction(
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.DELETE_MAGIC_LINK_USER,
                    data,
                    e
                  );
                }}
                className={"btn btn-md d-inline-flex align-items-center"}
                data-tip={
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .DELETE_CLIENT_USER
                }
                data-iscapture="true"
              >
                <span className="material-icons-round text-red icon-fs-20">
                  delete
                </span>
              </button>
            </AclAction>
          </>
        )}
      </>
    );
  };

  const rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.VALIDATE_USERS:
        clientUserBounceValidationConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .COPY_EMAIL_VERIFICATION_TOOLTIP:
        copyEmailVerificationLink(data);
        break;
      case MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.DELETE_MAGIC_LINK_USER:
        deleteMagicLinkClientUser(data);
        break;
      case MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK:
        resendMagicLink(data);
        break;
    }
  };

  const updateMagicLinkUserStatus = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.INACTIVATE_MAGIC_LINK_USER}
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.ACTIVATE_MAGIC_LINK_USER}
            </h2>
          </div>
        )}
      </>
    );
    setModalState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalConfig: {
        type: data?.is_active
          ? "de-activate_magic_link_user"
          : "activate_magic_link_user",
        id: data.id,
      },
      dailogModalStyleType:
        data.is_active === true ? "danger-modal" : "success-modal",
      dailogModalContent: dailogModalContent,
      submitButtonLabel: "Confirm",
    });
  };

  const deleteMagicLinkClientUser = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          Remove Magic Link User
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {
              MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST
                .DELETE_CONFIRATION_MAGIC_LINK_USER
            }
          </h2>
        </div>
      </>
    );
    setModalState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_magic_link_user", id: data.id },
      dailogModalStyleType: "danger-modal",
      submitButtonLabel: "Confirm",
    });
  };

  const resendMagicLink = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Resend Magic Link</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to Resend Magic Link?
          </h2>
        </div>
      </>
    );
    setModalState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "resend_magic_link", id: data.id },
      dailogModalStyleType: "success-modal",
      submitButtonLabel: "Confirm",
    });
  };
  const copyEmailVerificationLink = (user) => {
    const userId = user.id;
    MagicLinkUserServices.copyMagicLink(userId)
      .then((res) => {
        const link = res.data.magicLink;
        Copy(link);
        Toast("Copied!", "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  const handlePage = (input) => {
    setPageNo(input);
    setPageLoading(true);
  };

  const pagePrevious = () => {
    setPageNo((prevPageNo) => prevPageNo - 1);
    setPageLoading(true);
  };

  const pageNext = () => {
    setPageNo((prevPageNo) => prevPageNo + 1);
    setPageLoading(true);
  };

  const handleSort = (clickedColumn, key) => {
    setsortField(clickedColumn);
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    setPageLoading(true);
  };
  const handleSearch = () => {
    getTableData();
    setPageNo(1);
    setPageLoading(true);
  };
  const handlePageSize = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
    setPageLoading(true);
  };
  const handleInput = (e) => {
    setSearchNameEmail(e.target.value);
    setPageLoading(true);
  };

  const handleStatusChange = (e) => {
    const updatedStatus = status.map((c) => ({
      ...c,
      is_selected: c.id === e.id,
    }));

    setStatus(updatedStatus);
    setPageNo(1);
    setPageLoading(true);
  };

  // handler for client name filtering dropdown
  const handleClientChange = (selectedClient) => {
    const updatedClients = clients.map((client) => ({
      ...client,
      is_selected: client.id === selectedClient.id,
    }));
    setClients(updatedClients);
    const clientId =
      updatedClients.find((c) => c.is_selected === true)?.id || "all";
    setSelectedClientId(clientId);
    setPageNo(1);
    setPageLoading(true);
  };

  const addMagicUser = () => {
    history.push({
      pathname: formatRoute(MAGIC_LINK_CONSTANTS.MAGIC_LINK.CREATE.ROUTE, {
        clientId: selectedClientId,
      }),
    });
  };

  const toggleUserApiSwitch = (user_id, isActive) => {
    MagicLinkUserServices.updateUserApiStatus(user_id, isActive)
      .then((res) => {
        getTableData();
        Toast(
          isActive
            ? constants.CLIENT_USER.ACTIVATE.SUCCESS
            : constants.CLIENT_USER.IN_ACTIVATE.SUCCESS,
          "success"
        );
        setModalState({
          ...modalState,
          dailogModal: false,
          dailogModalConfig: { type: null },
          dailogModalStyleType: "",
        });
      })
      .catch((error) => {
        getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const deleteMagicLinkUser = (userId) => {
    MagicLinkUserServices.deleteMagicLinkUser(userId)
      .then((res) => {
        getTableData();
        Toast(
          MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.DELETE_SUCCESS_MAGIC_LINK_USER,
          "success"
        );
        setModalState({
          ...modalState,
          dailogModal: false,
          dailogModalConfig: { type: null },
          dailogModalStyleType: "",
        });
      })
      .catch((error) => {
        getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  const clientUserBounceValidationConfirmationHandler = (data) => {
    ClientServices.getSingleUserBounceValidation(data.email, data.id)
      .then((res) => {
        if (res.data.status.toLowerCase().trim() === "invalid") {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">User Invalid</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="remove-body">
                <h2 className="font-weight-semibold mb-0">
                  {constants.CLIENT_USER.INACTIVATE_USER_CONFIRM_MESSAGE}
                </h2>
              </div>
            </>
          );
          setModalState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: "de-activate_user", id: data.id },
            dailogModalStyleType: "danger-modal",
          });
        } else if (
          res.data.status.toLowerCase() === "catch_all" ||
          res.data.status.toLowerCase().trim() === "catchall"
        ) {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Verify Email</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="inactive-body">
                <h2 className="font-weight-semibold mb-0">
                  Please Verify User Email Manually
                </h2>
              </div>
            </>
          );
          setModalState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "inactive-modal",
          });
        } else {
          let dailogModalHeader = (
            <>
              <h3 className="text-darkblue2 font-weight-bold">Success</h3>
            </>
          );
          let dailogModalContent = (
            <>
              <div className="activate-body">
                <h2 className="font-weight-semibold mb-0">
                  User Email is Valid
                </h2>
              </div>
            </>
          );
          setModalState({
            dailogModal: true,
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: dailogModalContent,
            dailogModalConfig: { type: false, id: null },
            dailogModalStyleType: "success-modal",
          });
        }
      })
      .catch((error) => {
        getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  const resendEmailVerificationLink = (clientUserId) => {
    MagicLinkUserServices.resendMagicLink(clientUserId)
      .then((res) => {
        setModalState({
          dailogModal: false,
          dailogModalHeader: null,
          dailogModalConfig: { type: null },
          dailogModalStyleType: "",
          dailogModalContent: <></>,
          submitButtonLabel: "Confirm",
        });
        Toast(res.data.result.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const dailogModalAccept = (config) => {
    switch (config.type) {
      case "de-activate_magic_link_user":
        toggleUserApiSwitch(config.id, 0);
        break;
      case "activate_magic_link_user":
        toggleUserApiSwitch(config.id, 1);
        break;
      case "remove_magic_link_user":
        deleteMagicLinkUser(config.id);
        break;
      case "de-activate_user":
        toggleUserApiSwitch(config.id, 0);
        break;
      case "resend_magic_link":
        resendEmailVerificationLink(config.id);
        break;
      default:
        break;
    }
  };

  // Close Modal
  const dailogModalDecline = () => {
    setModalState((prev) => ({ ...prev, dailogModal: false }));
  };

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.NAME,
        link: null,
        is_active: false,
      },
    ];
    return breadcrumbs;
  };
  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        {/* This Portion is for breadcumb */}
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
        </div>
        {/* this portion is for search and filter */}
        <div className="row align-items-center flex-wrap-reverse">
          <div className="col-12 col-xl-7">
            <div className="row align-items-center flex-wrap-reverse">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="custom-combobox-list">
                  <CustomDropdownList
                    data={clients}
                    handleChange={handleClientChange}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="w-100-search border-search search-box-shadow-2">
                  <Search
                    handleSearch={handleSearch}
                    handleInput={handleInput}
                    value={""}
                    placeHolder={"Filter Name / Email"}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown mb-18px">
                <div className="custom-combobox-list">
                  <CustomDropdownList
                    data={status}
                    handleChange={handleStatusChange}
                    placeholder={"Status"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-auto ml-auto">
            <div className="d-flex align-items-center flex-wrap">
              <div className="ml-auto">
                <aside className="d-flex justify-content-end">
                  {permissionCheck(
                    constants.ACL.PERMISSION_KEY,
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK.TITLE,
                    MAGIC_LINK_CONSTANTS.MAGIC_LINK_PERMISSION.MAGIC_LINK
                      .ACTIONS.CREATE
                  ) && (
                    <>
                      <button
                        className={
                          "btn btn-custom btn-primary icon-text-btn title-btn d-inline-flex align-items-center mb-3 text-nowrap"
                        }
                        type="button"
                        onClick={addMagicUser}
                      >
                        <span className="material-icons-round icon-fs-16 mr-1">
                          add
                        </span>
                        {" " +
                          constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                            .ADD_BUTTON_TITLE}
                      </button>
                    </>
                  )}
                </aside>
              </div>
            </div>
          </div>
        </div>
        {/* this portion is for table data */}
        <div className="magic-users-table">
          <div
            className={`client-users-table-block colored-th-table-block custom-table-block custom-table-block-2 first-col-fixed-block last-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align`}
          >
            <DynamicTableWithSelectAllComponentNew
              columnData={TableHeader}
              rowData={rowData}
              showPagination={true}
              pageNext={pageNext}
              pagePrevious={pagePrevious}
              pageSize={pageSize}
              pageNo={pageNo}
              count={count}
              paginationClass={"center-pagination custom-pagination-new"}
              handlePageSize={handlePageSize}
              handleSort={handleSort}
              sortColumn={sortField}
              sortOrder={sortOrder}
              colSpan={TableHeader.length}
              rowAction={rowAction}
              handlePage={handlePage}
            />
          </div>
        </div>
        {rowData.map((data) => {
          return (
            <>
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .VALIDATE_USER_TOOL_TIP
                )}-${data.id}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={"tool-tip-resetpassword"}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.RESEND_MAGIC_LINK_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .DELETE_CLIENT_USER
                )}-${data.id}`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${Slugify(
                  MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.COPY_MAGIC_LINK_TOOLTIP
                )}-${data.id}`}
                multiline={true}
              />
            </>
          );
        })}
        <Dailog
          isOpen={modalState.dailogModal}
          accept={dailogModalAccept}
          decline={dailogModalDecline}
          header={modalState.dailogModalHeader}
          content={modalState.dailogModalContent}
          config={modalState.dailogModalConfig}
          modalStyleType={modalState.dailogModalStyleType}
          submitButtonLabel={modalState.submitButtonLabel}
        />
      </div>
    </aside>
  );
};

export default ListMagicLinkUsers;
