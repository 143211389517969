import { home as HOME } from "../Reducers/actionConstants";
import { UserServices } from "../../Services/User";

const getUserLogin = (user) => {
  return UserServices.getUserLogin({
    email: user.email,
    password: user.password,
  }).then((data) => {
    if (data.message) {
    } else {
      localStorage.setItem("token", data.data.data.token);
      return data.data.data;
    }
  });
};

const loginUserSuccess = (payload) => {
  return {
    type: HOME.LOGIN_USER_SUCCESS,
    payload,
  };
};

const loginUserFailure = (payload) => {
  return {
    type: HOME.LOGIN_USER_FAILURE,
    payload,
  };
};

const segments = (payload) => {
  return {
    type: HOME.SEGMENTS,
    payload,
  };
};

const activeMenuTab = (payload) => {
  return {
    type: HOME.ACTIVE_MENU_TAB,
    payload,
  };
};

const getTbriUsers = (payload) => {
  return {
    type: HOME.TBRI_USERS,
    payload,
  };
};

const getCurrencies = (payload) => {
  return {
    type: HOME.CURRENCIES,
    payload,
  };
};

const userRoles = (payload) => {
  return {
    type: HOME.USER_ROLES,
    payload,
  };
};

const openSearchBar = (payload) => {
  return {
    type: HOME.OPEN_SEARCHBAR,
    payload,
  };
};

const userDesignation = (payload) => {
  return {
    type: HOME.USER_DESIGNATION,
    payload,
  };
};

const openPreference = (payload) => {
  return {
    type: HOME.OPEN_PREFERENCE,
    payload,
  };
};

export {
  getUserLogin,
  loginUserSuccess,
  loginUserFailure,
  segments,
  openSearchBar,
  activeMenuTab,
  getTbriUsers,
  getCurrencies,
  userRoles,
  userDesignation,
  openPreference,
};
