import React, { useEffect, useState } from "react";
import constants from "../../Utils/constants";
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import Dailog from "../../Components/DailogNew";
import CustomDropdownList from "../../Components/CustomDropdownList";
import { connect } from "react-redux";
import { clientRoles, clients } from "../../Store/Actions/Client";
import DocumentTitle from "../../Utils/DocumentTitle";
import { ClientServices } from "../../Services/Client";
import isRoleAccountManager from "../../Utils/isRoleAccountManager";
import Toast from "../../Utils/Toast";
import IsTBRIClient from "../../Utils/IsTBRIClient";
import capitalize from "../../Utils/Capitalize";
import { isEmpty } from "lodash";
import { MagicLinkUserServices } from "../../Services/MagicLinkUserServices";
import Notification from "../../Components/Notification";
import MAGIC_LINK_CONSTANTS from "./utils/constants";
import { normalizeToHours } from "../../Utils/helper";

const ManageMagicUser = (props) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [modalContent, setModalContent] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalConfig, setModalConfig] = useState({});
  const [modalStyle, setModalStyle] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState(null);
  const [isFormSubmitted, setisFormSubmitted] = useState(false);
  const [isExpirationTimeUnlimited, setIsExpirationTimeUnlimited] =
    useState(false);

  const [time, setTime] = useState("In Hours");

  const [userDetails, setUserDetails] = useState({
    clientId: "",
    userRoleId: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    expirationTime: "",
  });

  const [clients, setClients] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = async () => {
    let clientId = props.match.params.clientId || null;
    let userId = props.match.params.id || null;

    updateDocumentTitle(userId);

    await fetchClients(clientId);
    await fetchClientUserRoles();

    if (userId !== null) await prepopulateData(userId);
  };

  const updateDocumentTitle = (userId) => {
    DocumentTitle(
      userId === null
        ? constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.HEADER_TITLE
        : constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.HEADER_TITLE
    );
  };

  const fetchClients = async (clientId) => {
    try {
      let response;
      if (!isRoleAccountManager(props.home.loginUserSuccess.role_id)) {
        response = await ClientServices.getAllClients();
      } else {
        response = await ClientServices.getAssignedClients(
          "/getAssignedClients"
        );
      }

      processClientData(response?.data?.data || [], clientId);
    } catch (error) {
      handleApiError(error);
    }
  };

  const processClientData = (clients, clientId) => {
    const filteredClients = clients
      .filter((client) => !IsTBRIClient(client))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((client) => ({
        ...client,
        is_selected: String(client.id) === clientId,
      }));
    setClients(filteredClients);
  };

  const fetchClientUserRoles = async () => {
    try {
      const res = await ClientServices.getClientUserRoles();
      let response =
        res?.data?.map((role) => ({ ...role, is_selected: false })) || [];

      setRoles(response);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    Toast(error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG, "error");
  };

  const changeRoute = () => {
    history.push({
      pathname: formatRoute(MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.ROUTE, {
        clientId: "all",
      }),
    });
  };
  const prepopulateData = async (userId) => {
    try {
      const res = await MagicLinkUserServices.getSpecificMagikLinkUser(userId);
      const response = res?.data?.data || {};
      let expiration_time = response?.expiration_time >= 24;
      let tempExpTime = response?.expiration_time;
      if (expiration_time) {
        setTime("In Days");
        tempExpTime = tempExpTime / 24;
      }

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        clientId: response?.client?.id || "",
        userRoleId: response?.role?.id || "",
        firstName: response?.first_name || "",
        lastName: response?.last_name || "",
        email: response?.email || "",
        contactNo: response?.mobile || "",
        expirationTime: tempExpTime,
      }));

      setIsExpirationTimeUnlimited(response?.expiration_time === null);

      if (userDetails.expirationTime === "null") {
        setIsExpirationTimeUnlimited(true);
      }

      setApiError(null);
      setisFormSubmitted(false);

      setClient(response.client.id);
      setRole(response.role.id);
    } catch (error) {
      handleApiError(error);
    }
  };

  const dailogModalAccept = (config) => {
    setIsModalOpen(false);
    setModalHeader("");
    setModalContent("");
    setModalConfig({});
  };
  const dailogModalDecline = () => {
    setIsModalOpen(false);
    setModalHeader("");
    setModalContent("");
    setModalConfig({});
  };

  const handleSubmit = async () => {
    setisFormSubmitted(true);

    if (isFormValid()) return; // Exit if form is invalid

    const userId = props.match.params.id || null;
    const prepareObject = {
      client_id: userDetails.clientId,
      first_name: capitalize(userDetails.firstName),
      last_name: capitalize(userDetails.lastName),
      role_id: userDetails.userRoleId,
      email: userDetails.email,
      mobile: userDetails.contactNo,
      expiration_time: isExpirationTimeUnlimited
        ? null
        : normalizeToHours(userDetails.expirationTime, time),
    };

    try {
      if (userId) {
        await MagicLinkUserServices.editMagicLinkUser(userId, prepareObject);
      } else {
        await MagicLinkUserServices.addMagicLinkUser(prepareObject);
      }
      saveClientUserSuccess();
    } catch (error) {
      handleApiError(error);
    }
  };

  const saveClientUserSuccess = () => {
    let userId = props.match.params.id ? props.match.params.id : null;
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Success</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {userId === null
              ? constants.CLIENT_USER.CREATE.SUCCESS
              : constants.CLIENT_USER.EDIT.SUCCESS}
          </h2>
        </div>
      </>
    );
    setIsModalOpen(true);
    setModalHeader(dailogModalHeader);
    setModalContent(dailogModalContent);
    setModalConfig({ type: false, id: null });
    setModalStyle("success-modal");

    setTimeout(
      () =>
        props.history.push({
          pathname: !isRoleAccountManager(props.home.loginUserSuccess.role_id)
            ? formatRoute(MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.ROUTE, {
                clientId: props.match.params.clientId,
              })
            : formatRoute(MAGIC_LINK_CONSTANTS.MAGIC_LINK.LIST.ROUTE, {
                clientId: props.match.params.clientId,
              }),
        }),
      1500
    );
  };

  const isFormValid = () => {
    const errors = {
      userClientIdError:
        userDetails.clientId === "" ||
        userDetails.clientId === null ||
        userDetails.clientId === "all",
      userRoleIdError:
        userDetails.userRoleId === "" || userDetails.userRoleId === null,
      userFirstNameError: isEmpty(userDetails?.firstName?.trim()),
      userLastNameError: isEmpty(userDetails?.lastName?.trim()),
      userEmailError:
        isEmpty(userDetails?.email?.trim()) ||
        !constants.REG_EX.EMAIL_REG_EX.test(
          String(userDetails?.email).toLowerCase()
        ),
      userContactNumberError:
        userDetails?.contactNo &&
        !constants.REG_EX.PHONE_REG_EX.test(userDetails.contactNo),
      userExpirationTime:
        !isExpirationTimeUnlimited && !userDetails?.expirationTime,
    };

    const formError = Object.values(errors).some(Boolean);
    setFormErrors(errors);
    setApiError(null);

    return formError;
  };

  const resetForm = () => {
    let userId = props.match.params.id ? props.match.params.id : null;
    let clientId = props.match.params.clientId
      ? props.match.params.clientId
      : null;

    setFormErrors({
      userClientIdError: false,
      userRoleIdError: false,
      userFirstNameError: false,
      userLastNameError: false,
      userEmailError: false,
      userContactNumberError: false,
      userExpirationTime: false,
    });
    setApiError(null);
    if (userId !== null) {
      prepopulateData(userId);
    } else {
      setUserDetails({
        clientId: clientId,
        userRoleId: "",
        firstName: "",
        lastName: "",
        email: " ",
        contactNo: "",
        expirationTime: "",
      });
      setisFormSubmitted(false);
      setApiError(null);
    }
  };
  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsExpirationTimeUnlimited(isChecked);

    if (isChecked) {
      setUserDetails((prev) => ({
        ...prev,
        expirationTime: "", // Clear input when checkbox is checked
      }));
    }
  };

  // Handle input change
  const handleExpirationTimeChange = (e) => {
    const { value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      expirationTime: parseInt(value),
    }));

    if (value) {
      setIsExpirationTimeUnlimited(false); // Uncheck checkbox when user types
    }
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setUserDetails((prevDetails) => ({
      ...prevDetails, // Spread previous state
      [id]: value, // Update only the changed field
    }));
  };
  //handle client change
  const handleClientChange = (selectedClient) => {
    setUserDetails((prev) => ({
      ...prev,
      clientId: selectedClient.id,
    }));

    setClient(selectedClient.id);
  };

  const handleRoleChange = (selectedRole) => {
    setUserDetails((prev) => ({
      ...prev,
      userRoleId: selectedRole.id,
    }));

    setRole(selectedRole.id);
  };

  const setClient = (selectedClientId) => {
    setClients((prevClients) => {
      const updatedClients = prevClients.map((client) => ({
        ...client,
        is_selected: client.id === selectedClientId,
      }));
      return updatedClients;
    });
  };

  const setRole = (selectedRoleId) => {
    setRoles((prevRoles) => {
      const updatedRoles = prevRoles.map((role) => ({
        ...role,
        is_selected: role.id === selectedRoleId,
      }));
      return updatedRoles;
    });
  };

  const userId = props.match.params.id ? props.match.params.id : null;

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 br_8 main_content_height">
        <div className="manage-client">
          <div className="">
            <div className={"add-edit-form-card"}>
              <form className={"form-wrapper"}>
                {apiError !== null && (
                  <Notification color={"danger"} message={apiError} />
                )}
                <div className="card mt-3">
                  <div className="card-header title-cancel-header d-flex align-items-center">
                    <h3 className="text-darkblue2 mb-0 font-weight-bold">
                      {userId === null
                        ? MAGIC_LINK_CONSTANTS.MAGIC_LINK.CREATE.HEADER_TITLE
                        : MAGIC_LINK_CONSTANTS.MAGIC_LINK.EDIT.HEADER_TITLE}
                    </h3>
                    <span
                      className="material-icons-round text-darkblue2 ml-auto cursor-pointer"
                      onClick={changeRoute}
                    >
                      close
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="card-body-header">
                      <h5 className="mb-0 font-weight-semibold">
                        User Information
                      </h5>
                    </div>
                    <div className="form-field-wrapper">
                      <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                          <div className={"row"}>
                            <div className="col-lg-6 col-sm-6 form-group position-relative">
                              <label for="companies">
                                Client <span className={"mandatory"}>* </span>
                              </label>
                              <div
                                className={`${
                                  formErrors.userClientIdError
                                    ? "is-invalid"
                                    : ""
                                } custom-combobox-list`}
                              >
                                <CustomDropdownList
                                  isDisabled={userId === null ? false : true}
                                  data={clients}
                                  handleChange={handleClientChange}
                                  placeholder={"Select Client"}
                                />
                              </div>
                              {formErrors.userClientIdError && (
                                <div className="invalid-tooltip d-block">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6 col-sm-6 form-group position-relative">
                              <label for="segments">
                                Role <span className={"mandatory"}>*</span>
                              </label>
                              <div
                                className={`${
                                  formErrors.userRoleIdError ? "is-invalid" : ""
                                } custom-combobox-list`}
                              >
                                <CustomDropdownList
                                  data={roles}
                                  handleChange={handleRoleChange}
                                  placeholder={"Select Role"}
                                />
                              </div>
                              {formErrors.userRoleIdError && (
                                <div className="invalid-tooltip display-block d-block">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6 form-group position-relative">
                              <label htmlFor="firstName">
                                First Name{" "}
                                <span className={"mandatory"}>*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control capitalize ${
                                  formErrors.userFirstNameError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userFirstNameError === false
                                    ? ""
                                    : ""
                                }`}
                                id="firstName"
                                placeholder="First Name"
                                value={userDetails.firstName}
                                onChange={handleInputChange}
                              />
                              {formErrors.userFirstNameError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6 form-group position-relative">
                              <label htmlFor="lastName">
                                Last Name <span className={"mandatory"}>*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control capitalize ${
                                  formErrors.userLastNameError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userLastNameError === false
                                    ? ""
                                    : ""
                                }`}
                                id="lastName"
                                placeholder="Last Name"
                                value={userDetails.lastName}
                                onChange={handleInputChange}
                              />
                              {formErrors.userLastNameError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6 form-group position-relative">
                              <label for="email">
                                Email <span className={"mandatory"}>*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.userEmailError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userEmailError === false ? "" : ""
                                }`}
                                id="email"
                                placeholder="Email (example@domain.com)"
                                value={userDetails.email}
                                onChange={handleInputChange}
                              />
                              {formErrors.userEmailError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.INVALID_EMAIL}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6 form-group position-relative">
                              <label for="contactNo">Contact No.</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.userContactNumberError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userContactNumberError === false
                                    ? ""
                                    : ""
                                }`}
                                id="contactNo"
                                placeholder="Contact No. (99999999)"
                                value={userDetails.contactNo}
                                onChange={handleInputChange}
                              />
                              {formErrors.userContactNumberError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.INVALID_CONTACT_NUMBER}
                                </div>
                              )}
                            </div>

                            <div className="col-sm-8 form-group position-relative">
                              <label htmlFor="expirationTime">
                                Expiration Time
                                <span className={"mandatory"}>*</span>
                              </label>
                              <div
                                className={
                                  "d-flex flex-column flex-sm-row align-items-start"
                                }
                              >
                                <div className="mr-sm-2 d-flex align-items-center w-100 w-sm-auto mt-2">
                                  <input
                                    className="form-check-input custom-checkbox-new ml-0 mt-0"
                                    type="checkbox"
                                    id="expirationTime"
                                    checked={isExpirationTimeUnlimited}
                                    onChange={handleCheckboxChange}
                                    disabled={userDetails.expirationTime}
                                  />
                                  <label
                                    className="form-check-label ml-1"
                                    htmlFor="isExpirationTimeUnlimited"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Unlimited
                                  </label>
                                </div>
                                <div
                                  style={{
                                    padding: "4px",
                                    minWidth: "140px",
                                  }}
                                >
                                  <div
                                    className={"input-group"}
                                    id="expirationTime"
                                    style={{
                                      position: "relative",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      className={`form-control ${
                                        formErrors.userExpirationTime === true
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      id="expirationTime"
                                      style={{
                                        maxHeight: "29px",
                                        maxWidth: "60px",
                                      }}
                                      min="1"
                                      value={userDetails.expirationTime}
                                      onChange={handleExpirationTimeChange}
                                      disabled={isExpirationTimeUnlimited}
                                    />

                                    <span
                                      className="ml-3"
                                      style={{
                                        color:
                                          !isExpirationTimeUnlimited &&
                                          time === "In Days"
                                            ? "#1E81E8"
                                            : "#606B77",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      In Days
                                    </span>
                                    <label className="exp-switch ml-2">
                                      <input
                                        type="checkbox"
                                        checked={time === "In Hours"}
                                        disabled={isExpirationTimeUnlimited}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setTime("In Hours");
                                          } else {
                                            setTime("In Days");
                                          }
                                        }}
                                      />
                                      <span className="exp-slider" />
                                    </label>
                                    <span
                                      style={{
                                        color:
                                          !isExpirationTimeUnlimited &&
                                          time === "In Hours"
                                            ? "#1E81E8"
                                            : "#606B77",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      In Hours
                                    </span>
                                  </div>
                                </div>

                                {formErrors.userExpirationTime && (
                                  <div className="d-flex align-items-center justify-content-center mt-2 ml-2">
                                    <span
                                      className="material-icons-round icon-fs-16 cursor-pointer"
                                      style={{ color: "red" }}
                                    >
                                      info
                                    </span>
                                    <span
                                      className="ml-1"
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {constants.FORM.MANDATORY_FIELD}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-submit-buttons">
                      <div className={"row"}>
                        <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                          <div className="text-right form-buttons">
                            <button
                              type="button"
                              className="btn btn-outline-primary modal-btn"
                              onClick={resetForm}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary modal-btn"
                              onClick={handleSubmit}
                            >
                              {props.match.params.id ? `Update` : `Save`}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Dailog
        isOpen={isModalOpen}
        accept={dailogModalAccept}
        decline={dailogModalDecline}
        header={modalHeader}
        content={modalContent}
        config={modalConfig}
        modalStyleType={modalStyle}
      />
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMagicUser);
