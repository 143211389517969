import { Component, default as React } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Notification from "./Notification";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";

class SetPassword extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    popUpModel: false,
    password: "",
    confirmPassword: "",
    apiErrors: "",
    formErrors: {},
    passwordError: false,
    confirmPasswordError: false,
    isSubmitted: false,
    successMessage: "",
  };

  handlePopUp = () => {
    this.setState({
      successMessage: "",
      apiErrors: "",
      popUpModel: !this.state.popUpModel,
      passwordError: false,
      confirmPasswordError: false,
    });
  };

  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.isSubmitted) this.isFormValid();
      }
    );
  };

  isFormValid = () => {
    var re = new RegExp(constants.REG_EX.PASSWORD_REG_EX);
    let passwordError = !re.test(this.state.password);
    let confirmPasswordError =
      !re.test(this.state.confirmPassword) ||
      this.state.password !== this.state.confirmPassword;

    this.setState({
      passwordError,
      confirmPasswordError,
      isSubmitted: true,
    });
    if (passwordError === false && confirmPasswordError === false) {
      return true;
    }
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      let body = {
        password: this.state.password,
        confirm_password: this.state.confirmPassword,
      };
      UserServices.setPassword(body)
        .then((data) => {
          this.setState({
            apiErrors: "",
            successMessage: data.data.message,
          });
        })
        .catch((error) => {
          this.setState({
            apiErrors:
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  render() {
    return (
      <>
        <li>
          <span
            onClick={this.handlePopUp}
            className="d-inline-flex align-items-center"
          >
            <i className="material-icons-outlined rotate-45">vpn_key</i>
            Set Password
          </span>
        </li>
        {this.state.popUpModel && (
          <Modal
            isOpen={this.state.popUpModel}
            toggle={this.handlePopUp}
            className={"modal-md modal-w-header custom-modal primary-modal"}
          >
            <ModalHeader toggle={this.handlePopUp}>
              {constants.SET_PASSWORD.HEADER_TITLE}
            </ModalHeader>
            <form
              onSubmit={this.handleSubmit}
              className={`col-lg-12 col-sm-12 form-wrapper p-0`}
            >
              <ModalBody>
                {this.state.apiErrors.length > 0 && (
                  <Notification
                    color={"danger"}
                    message={this.state.apiErrors}
                  />
                )}
                {this.state.successMessage.length > 0 && (
                  <Notification
                    color={"success"}
                    message={this.state.successMessage}
                  />
                )}
                <FormGroup>
                  <div className="clearfix">
                    <Label htmlFor="password">
                      Password
                      <span className={"mandatory"}>* </span>
                    </Label>
                    <Input
                      className={`${
                        this.state.passwordError ? "is-invalid" : ""
                      }`}
                      type="password"
                      placeholder=""
                      id="password"
                      name="password"
                      onChange={this.handleInputChange}
                    />

                    {this.state.passwordError ? (
                      <div className="invalid-tooltip">
                        Passwords must contain at least 8 characters including
                        at least 1 of each of the following: uppercase letter,
                        lowercase letter, special character and number.
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="clearfix">
                    <Label htmlFor="confirmPassword">
                      Confirm Password
                      <span className={"mandatory"}>* </span>
                    </Label>
                    <Input
                      className={`${
                        this.state.confirmPasswordError ? "is-invalid" : ""
                      }`}
                      type="password"
                      placeholder=""
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={this.handleInputChange}
                    />

                    {this.state.confirmPasswordError && (
                      <div className="invalid-tooltip">
                        Passwords do not match.
                      </div>
                    )}
                  </div>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="modal-btn btn-outline-primary"
                  onClick={this.handlePopUp}
                >
                  Cancel
                </Button>
                <Button className="modal-btn" color="primary" type="submit">
                  Set Password
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        )}
      </>
    );
  }
}
export default SetPassword;
