import React from "react";

const SSO_LOGOUT = (props) => {
  const logOutHandler = async () => {
    props.userLogout();
  };

  return (
    <li>
      <span
        onClick={logOutHandler}
        className="d-inline-flex align-items-center"
      >
        <i className="material-icons-outlined font-weight-bold">logout</i>
        Logout
      </span>
    </li>
  );
};

export default SSO_LOGOUT;
