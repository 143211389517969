import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomReactTooltip from "./CustomReactTooltip";

const VideoModal = ({ isOpen, toggle, videoUrl, title, data }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-xl custom-modal">
      <ModalHeader toggle={toggle}>
        {title?.length > 45 ? (
          <>
            <span
              className="category-title"
              data-for={`tooltip-${title}-title`}
              data-tip={title}
            >
              {title.slice(0, 45) + "..."}
            </span>
            <CustomReactTooltip id={`tooltip-${title}-title`} multiline />
          </>
        ) : (
          <span className="category-title">{title}</span>
        )}
      </ModalHeader>
      <ModalBody
        className="d-flex justify-content-center flex-d"
        style={{ padding: "0px" }}
      >
        {videoUrl && (
          <iframe
            width="1020"
            height="500"
            src={videoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="saml-configuration-player"
          ></iframe>
        )}
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;
